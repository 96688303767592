import { Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Colors from '../../../../theme/colors/Color';
import { Heading16Reg } from '../../../../common-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSuppliers } from '../../../../redux/features/user management/supplierListSlice';
import { fetchKam } from '../../../../redux/features/user management/kamListSlice';
import MultiValCombobox from '../../../../utils/component/combo-box/MultiValCombobox';
import PropTypes from 'prop-types';
import { FILTER_CONSTANT } from '../../../../utils/constants/filters';
import { fetchPM_Masters } from '../../../../redux/features/masters/PM_MastersSlice';
import DateRangePicker from '../../../../utils/component/date-picker/DateRangePicker';

const CustomBox = styled(Box)(() => ({
  padding: '15px',
  borderBottom: '1px solid',
  borderColor: Colors.secondary1,
  cursor: 'pointer',
}));

const BoxContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
}));
const BoxItem = styled(Box)(() => ({
  background: Colors.bg_filterItem,
  padding: '3px 15px',
  borderRadius: '20px',
  cursor: 'pointer',
}));

const filterItems = ['By Date', 'By Status', 'By Type', 'By Kam', 'By Firm Name', 'Product Details'];

const EnquiryFilter = ({ selectedFilters, filterData }) => {
  const [filters, setFilters] = useState(filterData);
  const dispatch = useDispatch();
  const PM_Masters = useSelector((state) => state.PM_Masters);
  const allSuppliers = useSelector((state) => state.suppliers.data);
  const allKams = useSelector((state) => state.kam.data);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleDateRangeChange = (range) => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const formattedStartDate = range.startDate ? formatDate(range.startDate) : null;
    const formattedEndDate = range.endDate ? formatDate(range.endDate) : null;
    setDateRange({
      startDate: range.startDate,
      endDate: range.endDate,
    });

    if (formattedStartDate)
      setFilters((prev) => {
        const updatedFilters = { ...prev, byDate: [formattedStartDate, formattedEndDate] };
        selectedFilters(updatedFilters);
        return updatedFilters;
      });
    else setFilters((prev) => ({ ...prev, byDate: [] }));
  };

  const [selectedItem, setSelectedItem] = useState('By Date');
  const [selectedChildItem, setSelectedChildItem] = useState('');

  useEffect(() => {
    // localStorage.removeItem('firmData');
    if (PM_Masters.grades || PM_Masters.grades?.length === 0) {
      dispatch(fetchPM_Masters());
    }
  }, []);

  useEffect(() => {
    if (!allSuppliers || allSuppliers.length === 0) {
      dispatch(fetchSuppliers());
    }
  }, [allSuppliers]);

  useEffect(() => {
    if (!allKams || allKams.length === 0) {
      dispatch(fetchKam());
    }
  }, [allKams]);

  const selectFilter = (item) => {
    setSelectedItem(item);
    if (item === 'Product Details') {
      setSelectedChildItem('Grade');
    } else {
      setSelectedChildItem('');
    }
  };

  const isValueSelected = (item, value) => {
    return filters[item]?.includes(value);
  };

  const toggleSelect = (key, value) => {
    setFilters((prev) => {
      const isSelected = prev[key].includes(value);
      const updatedFilters = {
        ...prev,
        [key]: isSelected ? prev[key].filter((item) => item !== value) : [...prev[key], value],
      };
      selectedFilters(updatedFilters); // Notify parent component with updated filters
      return updatedFilters;
    });
  };

  return (
    <Box display={'flex'} width={'35vw'} fontFamily={'poppins-regular'}>
      <Box width={'50%'} borderRight={'1px solid'} borderColor={Colors.secondary1}>
        {filterItems.map((item, i) => (
          <CustomBox key={i} sx={{ background: selectedItem === item ? Colors.bg_strip : '' }} onClick={() => selectFilter(item)}>
            <Heading16Reg text={item} />
          </CustomBox>
        ))}
        {selectedItem === 'Product Details' && (
          <>
            <CustomBox
              sx={{ borderLeft: '7px solid', borderLeftColor: selectedChildItem === 'Grade' ? Colors.secondary1 : Colors.transparent }}
              onClick={() => setSelectedChildItem('Grade')}
            >
              <Heading16Reg text={'Grade'} />
            </CustomBox>
            <CustomBox
              sx={{ borderLeft: '7px solid', borderLeftColor: selectedChildItem === 'Size' ? Colors.secondary1 : Colors.transparent }}
              onClick={() => setSelectedChildItem('Size')}
            >
              <Heading16Reg text={'Size'} />
            </CustomBox>
            <CustomBox
              sx={{
                borderLeft: '7px solid',
                borderLeftColor: selectedChildItem === 'Process Route' ? Colors.secondary1 : Colors.transparent,
              }}
              onClick={() => setSelectedChildItem('Process Route')}
            >
              <Heading16Reg text={'Process Route'} />
            </CustomBox>
          </>
        )}
      </Box>
      <Box width={'50%'} padding={2}>
        {selectedItem === 'By Date' && (
          <DateRangePicker startDate={dateRange.startDate} endDate={dateRange.endDate} onDateRangeChange={handleDateRangeChange} />
        )}
        {selectedItem === 'By Status' && (
          <BoxContainer>
            {Object.values(FILTER_CONSTANT.enquiry_status).map((type, index) => (
              <BoxItem
                key={index}
                sx={{
                  background: isValueSelected('status', type) ? Colors.bg_strip : Colors.bg_filterItem,
                }}
                onClick={() => toggleSelect('status', type)}
              >
                {type}
              </BoxItem>
            ))}
          </BoxContainer>
        )}
        {selectedItem === 'By Type' && (
          <BoxContainer>
            {Object.values(FILTER_CONSTANT.enquiry_type).map((type, index) => (
              <BoxItem
                key={index}
                sx={{
                  background: isValueSelected('enquiry_type', type) ? Colors.bg_strip : Colors.bg_filterItem,
                }}
                onClick={() => toggleSelect('enquiry_type', type)}
              >
                {type}
              </BoxItem>
            ))}
          </BoxContainer>
        )}
        {/* {selectedItem === 'By Kam' && <AutocompleteFilter placeholder={'Type/Select Name Of KAM'} optionsList={customerList} />} */}
        {selectedItem === 'By Kam' && (
          <MultiValCombobox
            optionsLabel={'name'}
            value={allKams.filter((kam) => filters.kam_id.includes(kam.user_id))}
            options={allKams}
            onChangeValue={(newValue) =>
              setFilters((prev) => {
                const updatedFilters = { ...prev, kam_id: newValue.map((item) => item.user_id) };
                selectedFilters(updatedFilters);
                return updatedFilters;
              })
            }
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
          />
        )}
        {selectedItem === 'By Firm Name' && (
          <MultiValCombobox
            optionsLabel={'firm_name'}
            value={allSuppliers.filter((supplier) => filters.firm_id.includes(supplier.id))}
            options={allSuppliers}
            onChangeValue={(newValue) =>
              setFilters((prev) => {
                const updatedFilters = { ...prev, firm_id: newValue.map((item) => item.id) };
                selectedFilters(updatedFilters);
                return updatedFilters;
              })
            }
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
          />
        )}
        {selectedChildItem === 'Grade' && (
          <MultiValCombobox
            optionsLabel={'grade_name'}
            value={PM_Masters.grades.filter((g) => filters.grade_id.includes(g.grade_id))}
            options={PM_Masters.grades}
            onChangeValue={(newValue) =>
              setFilters((prev) => {
                const updatedFilters = { ...prev, grade_id: newValue.map((item) => item.grade_id) };
                selectedFilters(updatedFilters);
                return updatedFilters;
              })
            }
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
          />
        )}
        {selectedChildItem === 'Size' && (
          <MultiValCombobox
            optionsLabel={'size_name'}
            value={PM_Masters.sizes.filter((item) => filters.size_id.includes(item.size_id))}
            options={PM_Masters.sizes}
            onChangeValue={(newValue) =>
              setFilters((prev) => {
                const updatedFilters = { ...prev, size_id: newValue.map((item) => item.size_id) };
                selectedFilters(updatedFilters);
                return updatedFilters;
              })
            }
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
          />
        )}
        {selectedChildItem === 'Process Route' && (
          <MultiValCombobox
            optionsLabel={'pr_name'}
            value={PM_Masters.processRoutes.filter((item) => filters.pr_id.includes(item.pr_id))}
            options={PM_Masters.processRoutes}
            onChangeValue={(newValue) =>
              setFilters((prev) => {
                const updatedFilters = { ...prev, pr_id: newValue.map((item) => item.pr_id) };
                selectedFilters(updatedFilters);
                return updatedFilters;
              })
            }
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
          />
        )}
      </Box>
    </Box>
  );
};

EnquiryFilter.propTypes = {
  selectedFilters: PropTypes.func.isRequired,
  filterData: PropTypes.array,
};

export default EnquiryFilter;
