import Colors from '../../theme/colors/Color';

export const getStatusBgColor = (status) => {
  switch (status) {
    case 'Active':
      return Colors.success;
    case 'Inactive':
      return Colors.error;
    case 'Open':
      return Colors.primary;
    case 'Close':
      return Colors.error;
    case 'In Progress':
      return Colors.inprogress;
    default:
      return Colors.transparent;
  }
};
