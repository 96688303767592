import React, { useState, useEffect } from 'react';
import { Box, styled } from '@mui/material';
import Colors from '../../../../theme/colors/Color';
import { Heading16Reg } from '../../../../common-components';
// import { InputTextField } from '../../../../utils/component';
import MultiValCombobox from '../../../../utils/component/combo-box/MultiValCombobox';
// import MultiValCombobox from '../../../../utils/component/combo-box/MultiValCombobox';
import { FILTER_CONSTANT } from '../../../../utils/constants/filters';
import DateRangePicker from '../../../../utils/component/date-picker/DateRangePicker';
import PropTypes from 'prop-types';
import { getData } from '../../../../services/APIService';
const token = JSON.parse(localStorage.getItem('userDetails'))?.token;

const CustomBox = styled(Box)(() => ({
  padding: '15px',
  borderBottom: '1px solid',
  borderColor: Colors.secondary1,
  cursor: 'pointer',
}));

const BoxContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
}));

const BoxItem = styled(Box)(() => ({
  background: Colors.bg_filterItem,
  padding: '3px 15px',
  borderRadius: '20px',
  cursor: 'pointer',
}));
const filterItems = ['Delivery location', 'Payment terms', 'Fulfilment type', 'By Status', 'By date of'];

const OrderFilter = ({ selectedFilters, filterData }) => {
  const [selectedItem, setSelectedItem] = useState('Delivery location');
  const [selectedChildItem, setSelectedChildItem] = useState('');
  const [filters, setFilters] = useState({});
  const [address, setAddress] = useState([]);
  useEffect(() => {
    setFilters(filterData);
  }, [filterData]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const handleDateRangeChange = (range, dateType) => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const formattedStartDate = range.startDate ? formatDate(range.startDate) : null;
    const formattedEndDate = range.endDate ? formatDate(range.endDate) : null;

    setDateRange({
      startDate: range.startDate,
      endDate: range.endDate,
    });

    if (formattedStartDate) {
      setFilters((prev) => {
        const updatedFilters = { ...prev, [dateType]: [formattedStartDate, formattedEndDate] };
        selectedFilters(updatedFilters);
        return updatedFilters;
      });
    } else {
      setFilters((prev) => ({ ...prev, [dateType]: [] }));
    }
  };

  const selectFilter = (item) => {
    setSelectedItem(item);
    if (item === 'By date of') {
      setSelectedChildItem('order');
    } else {
      setSelectedChildItem('');
    }
  };
  const isValueSelected = (category, value) => {
    return filters[category]?.includes(value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getData('product/filter_list', {}, token);
        if (response.data.status) {
          const cities = response.data.data.addresses;
          const cityOptions = cities.map((city, index) => ({
            id: index + 1,
            label: city,
          }));
          setAddress(cityOptions);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  const toggleSelect = (category, value) => {
    setFilters((prev) => {
      const isSelected = prev[category].includes(value);
      const updatedFilters = {
        ...prev,
        [category]: isSelected ? prev[category].filter((item) => item !== value) : [...prev[category], value],
      };
      selectedFilters(updatedFilters);
      return updatedFilters;
    });
  };
  return (
    <Box display={'flex'} width={'35vw'} fontFamily={'poppins-regular'}>
      <Box width={'50%'} borderRight={'1px solid'} borderColor={Colors.secondary1}>
        {filterItems.map((item, i) => (
          <CustomBox key={i} sx={{ background: selectedItem === item ? Colors.bg_strip : '' }} onClick={() => selectFilter(item)}>
            <Heading16Reg text={item} />
          </CustomBox>
        ))}
        {selectedItem === 'By date of' && (
          <>
            <CustomBox
              sx={{ borderLeft: '7px solid', borderLeftColor: selectedChildItem === 'order' ? Colors.secondary1 : Colors.transparent }}
              onClick={() => setSelectedChildItem('order')}
            >
              <Heading16Reg text={'order'} />
            </CustomBox>
            <CustomBox
              sx={{
                borderLeft: '7px solid',
                borderLeftColor: selectedChildItem === 'Payment details' ? Colors.secondary1 : Colors.transparent,
              }}
              onClick={() => setSelectedChildItem('Payment details')}
            >
              <Heading16Reg text={'Payment details'} />
            </CustomBox>
            <CustomBox
              sx={{
                borderLeft: '7px solid',
                borderLeftColor: selectedChildItem === 'Order acceptance' ? Colors.secondary1 : Colors.transparent,
              }}
              onClick={() => setSelectedChildItem('Order acceptance')}
            >
              <Heading16Reg text={'Order acceptance'} />
            </CustomBox>
          </>
        )}
      </Box>
      <Box width={'50%'} padding={2}>
        {selectedItem === 'Delivery location' && (
          <MultiValCombobox
            optionsLabel={'label'}
            value={address.filter((addr) => (filters.location || []).includes(addr.label))}
            options={address}
            onChangeValue={(newValue) => {
              const selectedCityNames = newValue.map((item) => item.label);
              setFilters((prev) => {
                const updatedFilters = { ...prev, location: selectedCityNames };
                selectedFilters(updatedFilters);
                return updatedFilters;
              });
            }}
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
          />
        )}
        {selectedItem === 'Payment terms' && (
          <>
            <p>Type</p>
            <BoxContainer>
              {Object.values(FILTER_CONSTANT.order_type).map((ordertype, index) => {
                return (
                  <BoxItem
                    key={index}
                    sx={{
                      background: isValueSelected('payment_terms', ordertype) ? Colors.bg_strip : Colors.bg_filterItem,
                    }}
                    onClick={() => toggleSelect('payment_terms', ordertype)}
                  >
                    {ordertype}
                  </BoxItem>
                );
              })}
            </BoxContainer>
            <p>Terms</p>
            <BoxContainer>
              {Object.values(FILTER_CONSTANT.order_terms).map((orderterms, index) => {
                return (
                  <BoxItem
                    key={index}
                    // sx={{
                    //   background: isValueSelected('status', status) ? Colors.bg_strip : Colors.bg_filterItem,
                    // }}
                    // onClick={() => toggleSelect('status', status)}
                  >
                    {orderterms}
                  </BoxItem>
                );
              })}
            </BoxContainer>
          </>
        )}
        {selectedItem === 'Fulfilment type' && (
          <BoxContainer>
            {Object.values(FILTER_CONSTANT.order_fulfilment).map((orderfullfilmettype, index) => {
              return (
                <BoxItem
                  key={index}
                  sx={{
                    background: isValueSelected('fulfilment_type', orderfullfilmettype) ? Colors.bg_strip : Colors.bg_filterItem,
                  }}
                  onClick={() => toggleSelect('fulfilment_type', orderfullfilmettype)}
                >
                  {orderfullfilmettype}
                </BoxItem>
              );
            })}
          </BoxContainer>
        )}

        {selectedItem === 'By Status' && (
          <BoxContainer>
            {Object.values(FILTER_CONSTANT.order_status).map((status, index) => {
              return (
                <BoxItem
                  key={index}
                  sx={{
                    background: isValueSelected('status', status) ? Colors.bg_strip : Colors.bg_filterItem,
                  }}
                  onClick={() => toggleSelect('status', status)}
                >
                  {status}
                </BoxItem>
              );
            })}
          </BoxContainer>
        )}
        {selectedChildItem === 'order' && (
          <BoxContainer>
            <DateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onDateRangeChange={(range) => handleDateRangeChange(range, 'order_date')}
            />
          </BoxContainer>
        )}
        {selectedChildItem === 'Payment details' && (
          <BoxContainer>
            <DateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onDateRangeChange={(range) => handleDateRangeChange(range, 'payment_entry_date')}
            />
          </BoxContainer>
        )}
        {selectedChildItem === 'Order acceptance' && (
          <BoxContainer>
            <DateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onDateRangeChange={(range) => handleDateRangeChange(range, 'order_acceptance_date')}
            />
          </BoxContainer>
        )}
      </Box>
    </Box>
  );
};
OrderFilter.propTypes = {
  selectedFilters: PropTypes.func,
  filterData: PropTypes.object,
};

export default OrderFilter;
