import React from 'react';
import { Grid } from '@mui/material';
import AddProducts from '../AddProducts/AddProducts';
import ProductsComment from '../AddProducts/ProductsComment';
import PropTypes from 'prop-types';
// import { Heading26Bold } from '../../../../common-components'

const EditProducts = () => {
  return (
    <Grid>
      <AddProducts isDisable={true} showDetails={true} isEditMode={true} />
      <ProductsComment />
    </Grid>
  );
};
EditProducts.propTypes = {
  isDisable: PropTypes.bool,
  showDetails: PropTypes.bool,
};

export default EditProducts;
