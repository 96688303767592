import { Box, Button, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Colors from '../../../../theme/colors/Color';
import { Heading16Reg } from '../../../../common-components';
// import AutocompleteFilter from '../../../../common-components/autocomplete/AutocompleteFilter';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { getData } from '../../../../services/APIService';
// import { Combobox } from '../../../../utils/component';

const CustomBox = styled(Box)(() => ({
  padding: '15px',
  borderBottom: '1px solid',
  borderColor: Colors.secondary1,
  cursor: 'pointer',
}));

// const StyledAutocomplete = styled(Autocomplete)(() => ({
//   backgroundColor: Colors.bg_filterItem,
//   fontFamily: 'poppins-regular',
//   borderRadius: 10,
//   width: 240,
//   '& .MuiOutlinedInput-root': {
//     height: '45px',
//     '& fieldset': {
//       borderRadius: 10,
//     },
//   },
// }))

// const CustomPaper = styled(Paper)({
//   overflow: 'auto',
//   maxHeight: 140,
//   '::-webkit-scrollbar': {
//     display: 'none',
//   },
// })

// const styles = {
//   inputRoot: {
//     height: '45px',
//     fontFamily: 'poppins-regular',
//   },
// }

// const filterItems = ['Role', 'By Status', 'Department', 'Name of The Customer'];
const filterItems = ['Role', 'By Status', 'Department'];
// const customerList = ['Mahindra', 'Tata steel', 'JSW Steel', 'Jindal Steel', 'Essar Steel', 'Steel Authority of India Limited'];
const department = ['Purchase', 'Sales', 'Marketing'];

const BosEmpFilter = (props) => {
  const [selectedItem, setSelectedItem] = useState('Role');
  const [roleList, setRoleList] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const dispatch = useDispatch();
  let filters = {};
  useEffect(() => {
    fetchIntialData();
    fetchCustomers();
  }, []);

  const fetchIntialData = async () => {
    try {
      const roles = await getData('role');
      setRoleList(roles.data.data);
    } catch (error) {
      dispatch(showSnackbar({ message: error.message, severity: 'error' }));
    }
  };
  const fetchCustomers = async () => {
    // try {
    //   const roles = await getData('role');
    //   setRoleList(roles.data.data);
    // } catch (error) {
    //   dispatch(showSnackbar({ message: error.message, severity: 'error' }));
    // }
  };

  const selectFilter = (item) => {
    setSelectedItem(item);
  };

  const chooseRole = (role) => {
    let roleList;
    if (selectedRoles.includes(role.role_id)) {
      roleList = selectedRoles.filter((item) => item != role.role_id);
    } else {
      roleList = [...selectedRoles, role.role_id];
    }
    setSelectedRoles(roleList);
    filters.role_id = roleList;
    props.onChooseFilter(filters);
  };

  const chooseStatus = (status) => {
    let list;
    if (statusList.includes(status)) {
      list = statusList.filter((item) => item != status);
    } else {
      list = [...statusList, status];
    }
    setStatusList(list);
    filters.status = list;
    props.onChooseFilter(filters);
  };

  const chooseDepartment = (dept) => {
    let list;
    if (departmentList.includes(dept)) {
      list = departmentList.filter((item) => item != dept);
    } else {
      list = [...departmentList, dept];
    }
    setDepartmentList(dept);
    filters.department = list;
    props.onChooseFilter(filters);
  };
  return (
    <Box display={'flex'} width={'35vw'} fontFamily={'poppins-regular'}>
      <Box width={'50%'} borderRight={'1px solid'} borderColor={Colors.secondary1}>
        {filterItems.map((item, i) => (
          <CustomBox key={i} sx={{ background: selectedItem === item ? Colors.bg_strip : '' }} onClick={() => selectFilter(item)}>
            <Heading16Reg text={item} />
          </CustomBox>
        ))}
      </Box>
      <Box width={'50%'} padding={2}>
        {selectedItem === 'Role' && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {roleList.map((item, i) => (
              <Button
                key={i}
                sx={{
                  background: selectedRoles.includes(item.role_id) ? Colors.bg_strip : Colors.bg_filterItem,
                  padding: '3px 10px',
                  borderRadius: '20px',
                  color: Colors.text_black,
                  cursor: 'pointer',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: selectedRoles.includes(item.role_id) ? Colors.bg_strip : Colors.bg_filterItem,
                  },
                }}
                onClick={() => chooseRole(item)}
              >
                {item.role_name}
              </Button>
            ))}
          </Box>
        )}
        {selectedItem === 'By Status' && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <Button
              sx={{
                background: statusList.includes('Active') ? Colors.bg_strip : Colors.bg_filterItem,
                padding: '3px 10px',
                borderRadius: '20px',
                color: Colors.text_black,
                cursor: 'pointer',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: statusList.includes('Active') ? Colors.bg_strip : Colors.bg_filterItem,
                },
              }}
              onClick={() => chooseStatus('Active')}
            >
              Active
            </Button>
            <Button
              sx={{
                background: statusList.includes('Inactive') ? Colors.bg_strip : Colors.bg_filterItem,
                padding: '3px 10px',
                borderRadius: '20px',
                color: Colors.text_black,
                cursor: 'pointer',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: statusList.includes('Inactive') ? Colors.bg_strip : Colors.bg_filterItem,
                },
              }}
              onClick={() => chooseStatus('Inactive')}
            >
              Inactive
            </Button>
          </Box>
        )}
        {selectedItem === 'Department' && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {department.map((item, i) => (
              <Button
                key={i}
                sx={{
                  background: departmentList.includes(item) ? Colors.bg_strip : Colors.bg_filterItem,
                  padding: '3px 10px',
                  borderRadius: '20px',
                  color: Colors.text_black,
                  cursor: 'pointer',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: departmentList.includes(item) ? Colors.bg_strip : Colors.bg_filterItem,
                  },
                }}
                onClick={() => chooseDepartment(item)}
              >
                {item}
              </Button>
            ))}
          </Box>
        )}
        {selectedItem === 'Name of The Customer' && (
          //   <StyledAutocomplete
          //     disablePortal
          //     id="combo-box-demo"
          //     options={customerList}
          //     renderInput={(params) => (
          //       <TextField
          //         {...params}
          //         placeholder="Type/Select Name of Customer"
          //         InputProps={{
          //           ...params.InputProps,
          //           sx: styles.inputRoot,
          //         }}
          //       />
          //     )}
          //     PaperComponent={(props) => <CustomPaper {...props} />}
          //   />
          // <AutocompleteFilter placeholder={'Type/Select Name of Customer'} optionsList={customerList} />
          // <Combobox
          //   optionsLabel={'name'}
          //   value={allKams.filter((kam) => filters.kam_id.includes(kam.user_id))}
          //   options={allKams}
          //   onChangeValue={(newValue) =>
          //     setFilters((prev) => {
          //       const updatedFilters = { ...prev, kam_id: newValue.map((item) => item.user_id) };
          //       selectedFilters(updatedFilters);
          //       return updatedFilters;
          //     })
          //   }
          //   bgColor={Colors.bg_filterItem}
          //   isMultiSelect={true}
          // />
          <span>hI</span>
        )}
      </Box>
    </Box>
  );
};

BosEmpFilter.propTypes = {
  onChooseFilter: PropTypes.func,
};

export default BosEmpFilter;
