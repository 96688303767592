import React, { useState } from 'react';
import { Box, Popover, TextField, Typography, styled } from '@mui/material';
import Colors from '../../../../theme/colors/Color';
import { Heading26Bold } from '../../../../common-components';
import PropTypes from 'prop-types';
import { Add, Delete, Filter } from '../../../../theme/Images';
import { useNavigate } from 'react-router-dom';
import TableHeader from '../../../../utils/table headings/TableHeaders.json';
import ListDataTable from '../../../../common-components/table/ListDataTable';

const orderListData = [
  {
    id: 1,
    ordNo: ' OA-BOS/23-24/0001',
    cstmrName: 'BOS',
    ordDate: '15/02/2024',
    dlvryLocation: 'Mumbai',
    pmntTerm: 'Advanced',
    fulflType: 'Transportation by BOS',
    GrndTotal: '2,00,000/-',
    pdEntry: '15/02/2024',
    ordAcceptance: '15/02/2024',
    status: 'Pending',
  },
  {
    id: 2,
    ordNo: ' OA-BOS/23-24/0001',
    cstmrName: 'Suresh',
    ordDate: '15/02/2024',
    dlvryLocation: 'Pune',
    pmntTerm: 'LC',
    fulflType: 'Transportation by BOS',
    GrndTotal: '4,00,000/-',
    pdEntry: '15/02/2024',
    ordAcceptance: '15/02/2024',
    status: 'Awaiting',
  },
  {
    id: 3,
    ordNo: ' OA-BOS/23-24/0001',
    cstmrName: 'BOS',
    ordDate: '15/02/2024',
    dlvryLocation: 'Rajkot',
    pmntTerm: 'BG',
    fulflType: 'Self Pick up',
    GrndTotal: '6,00,000/-',
    pdEntry: '15/02/2024',
    ordAcceptance: '15/02/2024',
    status: 'Generated',
  },
  {
    id: 4,
    ordNo: ' OA-BOS/23-24/0001',
    cstmrName: 'Dinesh',
    ordDate: '15/02/2024',
    dlvryLocation: 'Mumbai',
    pmntTerm: 'PDC',
    fulflType: 'Transportation by BOS',
    GrndTotal: '8,00,000/-',
    pdEntry: '15/02/2024',
    ordAcceptance: '15/02/2024',
    status: 'Processing',
  },
  {
    id: 5,
    ordNo: ' OA-BOS/23-24/0001',
    cstmrName: 'BOS',
    ordDate: '15/02/2024',
    dlvryLocation: 'Mumbai',
    pmntTerm: 'PDC',
    fulflType: 'Transportation by BOS',
    GrndTotal: '8,00,000/-',
    pdEntry: '15/02/2024',
    ordAcceptance: '15/02/2024',
    status: 'Complete',
  },
];

const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));

const CustomBtnBox = styled(Box)(() => ({
  // border: '1px solid',
  height: '35px',
  width: '35px',
  borderRadius: '6px',
  padding: '8px 6px 5px',
  background: Colors.white,
  textAlign: 'center',
  cursor: 'pointer',
}));

const AddOrders = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <Box>
      <Heading26Bold text={''} />
      <Box
        sx={{
          marginTop: '20px',
          // height: '80px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch placeholder="Search" />
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Box>
            <CustomBtnBox onClick={handleClickFilter}>
              <img src={Filter} alt="filter" style={{ height: '20px', width: '25px' }} />
              <Typography sx={{ fontSize: '10px' }}>FILTER</Typography>
            </CustomBtnBox>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box>{/* <UMFilter /> */}</Box>
            </Popover>
          </Box>
          <CustomBtnBox onClick={() => navigate(`/order/details`)}>
            <img src={Add} alt="add" style={{ height: '22px', width: '22px' }} />
            <Typography sx={{ fontSize: '10px' }}>ADD</Typography>
          </CustomBtnBox>
          <CustomBtnBox>
            <img src={Delete} alt="delete" style={{ height: '21px', width: '20px' }} />
            <Typography sx={{ fontSize: '10px' }}>DELETE</Typography>
          </CustomBtnBox>
        </Box>
      </Box>
      <Box>
        <ListDataTable list={orderListData} path={`/${props.path}/edit`} tableHead={TableHeader.Order_list} />
      </Box>
    </Box>
  );
};
AddOrders.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
};
export default AddOrders;
