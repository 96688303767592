import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import Colors from '../../../../theme/colors/Color';
import { Heading16Reg } from '../../../../common-components';
import { FILTER_CONSTANT } from '../../../../utils/constants/filters';

const CustomBox = styled(Box)(() => ({
  padding: '15px',
  borderBottom: '1px solid',
  borderColor: Colors.secondary1,
  cursor: 'pointer',
}));

const BoxContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
}));

const BoxItem = styled(Box)(() => ({
  background: Colors.bg_filterItem,
  padding: '3px 15px',
  borderRadius: '20px',
  cursor: 'pointer',
}));
const filterItems = ['Realm', 'Status'];

const CommunityFilter = () => {
  const [selectedItem, setSelectedItem] = useState('Realm');
  const [filters, setFilters] = useState();
  console.log(setFilters, filters);

  const selectFilter = (item) => {
    setSelectedItem(item);
  };
  //   const toggleSelect = () => {
  //     // setFilters((prev) => {
  //     //   const isSelected = prev[category].includes(value);
  //     //   const updatedFilters = {
  //     //     ...prev,
  //     //     [category]: isSelected ? prev[category].filter((item) => item !== value) : [...prev[category], value],
  //     //   };
  //     //   selectedFilters(updatedFilters);
  //     //   return updatedFilters;
  //     // });
  //   };
  return (
    <Box display={'flex'} width={'35vw'} fontFamily={'poppins-regular'}>
      <Box width={'50%'} borderRight={'1px solid'} borderColor={Colors.secondary1}>
        {filterItems.map((item, i) => (
          <CustomBox key={i} sx={{ background: selectedItem === item ? Colors.bg_strip : '' }} onClick={() => selectFilter(item)}>
            <Heading16Reg text={item} />
          </CustomBox>
        ))}
      </Box>
      <Box width={'50%'} padding={2}>
        {selectedItem === 'Realm' && (
          <BoxContainer>
            {Object.values(FILTER_CONSTANT.community_Realm).map((communityRealm, index) => {
              return (
                <BoxItem
                  key={index}
                  // sx={{
                  //   background: isValueSelected('status', status) ? Colors.bg_strip : Colors.bg_filterItem,
                  // }}
                  // onClick={() => toggleSelect('status', status)}
                >
                  {communityRealm}
                </BoxItem>
              );
            })}
          </BoxContainer>
        )}
        {selectedItem === 'Status' && (
          <>
            <BoxContainer>
              {Object.values(FILTER_CONSTANT.status).map((orderterms, index) => {
                return (
                  <BoxItem
                    key={index}
                    // sx={{
                    //   background: isValueSelected('status', status) ? Colors.bg_strip : Colors.bg_filterItem,
                    // }}
                    // onClick={() => toggleSelect('status', status)}
                  >
                    {orderterms}
                  </BoxItem>
                );
              })}
            </BoxContainer>
          </>
        )}
      </Box>
    </Box>
  );
};
export default CommunityFilter;
