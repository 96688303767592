import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getData } from '../../../../services/APIService';

const headerCellStyle = {
  border: '1px solid #ddd',
  color: '#fff',
  backgroundColor: '#0093D3',
  fontFamily: 'poppins-regular',
};
const headerCellStyle1 = {
  border: '1px solid #ddd',
  color: 'red',
  fontFamily: 'poppins-regular',
};
const headerCellStyle2 = {
  border: '1px solid #ddd',
  fontFamily: 'poppins-regular',
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return `${formattedDate}, ${formattedTime}`;
};

const d = (v) => {
  return v ? 'Y' : 'N';
};

export default function Inventory() {
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [list, setList] = useState([]);
  const fetchData = async () => {
    await getData(`/tally/inventory`, {}, userData.token)
      .then((res) => {
        if (res.data.data) {
          setList(res.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {list?.map((item) => (
        <TableContainer key={item.product_code} component={Paper} sx={{ mb: 2 }}>
          <Table sx={{ minWidth: 650, border: '1px solid #ddd' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={!item.product_id ? headerCellStyle1 : null} colSpan={4}>
                  Product code : {item.product_code}
                </TableCell>
                <TableCell sx={headerCellStyle1} colSpan={8}></TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }}>
                <TableCell sx={headerCellStyle}>Date + time</TableCell>
                <TableCell sx={headerCellStyle}>opening stock</TableCell>
                <TableCell sx={headerCellStyle}>customer id</TableCell>
                <TableCell sx={headerCellStyle}>order no</TableCell>
                <TableCell sx={headerCellStyle}>order date</TableCell>
                <TableCell sx={headerCellStyle}>payment terms</TableCell>
                <TableCell sx={headerCellStyle}>block duration</TableCell>
                <TableCell sx={headerCellStyle}>status</TableCell>
                <TableCell sx={headerCellStyle}>block date</TableCell>
                <TableCell sx={headerCellStyle}>payment entered</TableCell>
                <TableCell sx={headerCellStyle}>payment entry date</TableCell>
                <TableCell sx={headerCellStyle}>order accepted</TableCell>
                <TableCell sx={headerCellStyle}>order accept date</TableCell>
                <TableCell sx={headerCellStyle}>invoice generated</TableCell>
                <TableCell sx={headerCellStyle}>invoice date</TableCell>
                <TableCell sx={headerCellStyle}>display stock</TableCell>
                <TableCell sx={headerCellStyle}>validation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item.data?.map((row) => (
                <TableRow key={row.id} sx={headerCellStyle2}>
                  <TableCell sx={headerCellStyle2}>{formatDate(row.createdAt)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.opening_stock}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.customer_id}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.order_no}</TableCell>
                  <TableCell sx={headerCellStyle2}>{formatDate(row.order_date)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.payment_terms}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.block_duration}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.status}</TableCell>
                  <TableCell sx={headerCellStyle2}>{formatDate(row.block_date)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{d(row.is_payment_entered)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.payment_entry_date}</TableCell>
                  <TableCell sx={headerCellStyle2}>{d(row.is_order_accepted)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.order_accept_date}</TableCell>
                  <TableCell sx={headerCellStyle2}>{d(row.is_invoice_generated)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.invoice_date}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.display_stock}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.validation}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  );
}
