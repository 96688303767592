import { Box, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Colors from '../../../../theme/colors/Color';
import { Heading16Reg } from '../../../../common-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSuppliers } from '../../../../redux/features/user management/supplierListSlice';
import { InputTextField } from '../../../../utils/component';
import { FILTER_CONSTANT } from '../../../../utils/constants/filters';
import { fetchKam } from '../../../../redux/features/user management/kamListSlice';
import MultiValCombobox from '../../../../utils/component/combo-box/MultiValCombobox';
import { getData } from '../../../../services/APIService';
const token = JSON.parse(localStorage.getItem('userDetails'))?.token;

const CustomBox = styled(Box)(() => ({
  padding: '15px',
  borderBottom: '1px solid',
  borderColor: Colors.secondary1,
  cursor: 'pointer',
}));

const BoxContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
}));

const BoxItem = styled(Box)(() => ({
  background: Colors.bg_filterItem,
  padding: '3px 15px',
  borderRadius: '20px',
  cursor: 'pointer',
}));

const filterItems = ['By Product Code', 'By Supplier', 'By Location', 'By Status', 'Show In Catalogue'];

function debounce(func, delay) {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
}

const ProductFilter = ({ selectedFilters, filterData }) => {
  const [productCode, setProductCode] = useState('');
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const allSuppliers = useSelector((state) => state.suppliers.data);
  const allKams = useSelector((state) => state.kam.data);
  const [address, setAddress] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getData('product/filter_list', {}, token);
        if (response.data.status) {
          const cities = response.data.data.addresses;
          const cityOptions = cities.map((city, index) => ({
            id: index + 1,
            label: city,
          }));
          setAddress(cityOptions);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!allSuppliers || allSuppliers.length === 0) {
      dispatch(fetchSuppliers());
    }
  }, [dispatch, allSuppliers]);

  useEffect(() => {
    if (!allKams || allKams.length === 0) {
      dispatch(fetchKam());
    }
  }, [dispatch, allKams]);

  useEffect(() => {
    setFilters(filterData);
  }, [filterData]);

  const [selectedItem, setSelectedItem] = useState('By Product Code');

  const selectFilter = (item) => {
    setSelectedItem(item);
  };

  const isValueSelected = (category, value) => {
    return filters[category]?.includes(value);
  };

  const toggleSelect = (category, value) => {
    setFilters((prev) => {
      const isSelected = prev[category].includes(value);
      const updatedFilters = {
        ...prev,
        [category]: isSelected ? prev[category].filter((item) => item !== value) : [...prev[category], value],
      };
      selectedFilters(updatedFilters);
      return updatedFilters;
    });
  };

  const debouncedChangeHandler = useRef(
    debounce((newProductCode) => {
      setFilters((prev) => {
        const updatedFilters = { ...prev, product_code: newProductCode ? [newProductCode] : [] };

        if (JSON.stringify(prev.product_code) !== JSON.stringify(updatedFilters.product_code)) {
          selectedFilters(updatedFilters);
          return updatedFilters;
        }

        return prev;
      });
    }, 300)
  ).current;

  const handleProductCodeChange = (e) => {
    const newProductCode = e.target.value.trim();
    setProductCode(newProductCode);

    debouncedChangeHandler(newProductCode);
  };

  return (
    <Box display={'flex'} width={'35vw'} fontFamily={'poppins-regular'}>
      <Box width={'50%'} borderRight={'1px solid'} borderColor={Colors.secondary1}>
        {filterItems.map((item, i) => (
          <CustomBox key={i} sx={{ background: selectedItem === item ? Colors.bg_strip : '' }} onClick={() => selectFilter(item)}>
            <Heading16Reg text={item} />
          </CustomBox>
        ))}
      </Box>
      <Box width={'50%'} padding={2}>
        {selectedItem === 'By Product Code' && (
          <InputTextField
            placeholder={'Type Product Code'}
            bgColor={Colors.bg_filterItem}
            value={productCode}
            onChange={handleProductCodeChange}
          />
        )}
        {selectedItem === 'By Supplier' && (
          <MultiValCombobox
            optionsLabel={'firm_name'}
            value={allSuppliers.filter((supplier) => filters.firm_id.includes(supplier.id))}
            options={allSuppliers}
            onChangeValue={(newValue) =>
              setFilters((prev) => {
                const updatedFilters = { ...prev, firm_id: newValue.map((item) => item.id) };
                selectedFilters(updatedFilters);
                return updatedFilters;
              })
            }
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
          />
        )}
        {selectedItem === 'By Location' && (
          <MultiValCombobox
            optionsLabel={'label'}
            value={address.filter((addr) => filters.location.includes(addr.label))}
            options={address}
            onChangeValue={(newValue) => {
              const selectedCityNames = newValue.map((item) => item.label);
              setFilters((prev) => {
                const updatedFilters = { ...prev, location: selectedCityNames };
                selectedFilters(updatedFilters);
                return updatedFilters;
              });
            }}
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
          />
        )}

        {selectedItem === 'By Status' && (
          <BoxContainer>
            {Object.values(FILTER_CONSTANT.product_status).map((status, index) => {
              return (
                <BoxItem
                  key={index}
                  sx={{
                    background: isValueSelected('status', status) ? Colors.bg_strip : Colors.bg_filterItem,
                  }}
                  onClick={() => toggleSelect('status', status)}
                >
                  {status}
                </BoxItem>
              );
            })}
          </BoxContainer>
        )}
        {selectedItem === 'Show In Catalogue' && (
          <BoxContainer>
            {['Yes', 'No'].map((option, index) => {
              const value = option === 'Yes';
              return (
                <BoxItem
                  key={index}
                  sx={{
                    background: isValueSelected('show_in_catalog', value) ? Colors.bg_strip : Colors.bg_filterItem,
                  }}
                  onClick={() => toggleSelect('show_in_catalog', value)}
                >
                  {option}
                </BoxItem>
              );
            })}
          </BoxContainer>
        )}
      </Box>
    </Box>
  );
};

ProductFilter.propTypes = {
  selectedFilters: PropTypes.function,
  filterData: PropTypes.obj,
};

export default ProductFilter;
