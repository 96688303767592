import { Box, Button, Modal, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomDataGrid, Heading16Reg, Heading26Bold } from '../../../../utils/component';
import PageHeader from '../../../Page header/PageHeader';
// import EmployeeListTable from './EmployeeListTable';
// import TableHeader from '../../../../utils/table headings/TableHeaders.json';
import { postData } from '../../../../services/APIService';
import { useNavigate } from 'react-router-dom';
import BosEmpFilter from '../filters/BosEmpFilter';
// import Colors from '../../../../theme/colors/Color';
import { Edit } from '../../../../theme/Images';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { getStatusBgColor } from '../../../../utils/constants/statusColor';

let totalPages = 1;
const limit = 10;

const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 40,
  p: 4,
};

const EmployeeList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [keyword, setKeyword] = useState('');
  // const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columnsData = [
    { field: 'name', headerName: 'Name', flex: 0.7 },
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.7,
      valueGetter: (params) => (params ? params.role_name : ''),
    },
    { field: 'email', headerName: 'Email Id(User Name)', flex: 1.2 },
    {
      field: 'password',
      headerName: 'Password',
      flex: 0.8,
      valueGetter: (params) => (params ? '********' : ''),
    },
    {
      field: 'assignedFirm',
      headerName: 'Customer Name',
      flex: 1.5,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.map((item) => item.firm_name).join(', ');
        }
        return '';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.8,
      renderCell: (params) => {
        const status = params.value;
        return (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                background: getStatusBgColor(status),
                height: '25px',
                width: '80px',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: '1px 20px',
              }}
            >
              {status}
            </Box>
          </Box>
        );
      },
    },
    {
      // field: 'actions',
      headerName: 'Edit',
      width: 70,
      renderCell: (params) => {
        const { user_id } = params.row;
        const handleEdit = () => {
          navigate(`/employee/edit/${user_id}`);
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
    },
  ];

  useEffect(() => {
    fetchEmployee({});
  }, [currentPage, keyword]);
  const fetchEmployee = async (filters) => {
    try {
      const res = await postData('/users/getAllUser', { type: 'EMPLOYEE', page: currentPage, limit, filters, keyword }, {}, userData.token);
      totalPages = res.data.data.totalPages;
      setEmployeeList(res.data.data.users);
    } catch (error) {
      dispatch(showSnackbar({ message: error.message, severity: 'error' }));
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCheckRow = (selectedRow) => {
    setSelectedUsers(selectedRow);
  };

  const handleDeleteUser = async () => {
    try {
      setDeleteWarning(false);
      const res = await postData('/users/bulkDelete', { userIds: selectedUsers }, {}, userData.token);
      fetchEmployee(currentPage);
      dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
    } catch (error) {
      dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    }
    setSelectedUsers([]);
  };

  // const handleFilter = async (filters) => {
  //   // try {
  //   //   setDeleteWarning(false);
  //   //   const res = await postData('/users/bulkDelete', { userIds: selectedUsers }, {}, userData.token);
  //   //   fetchEmployee(currentPage);
  //   //   dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
  //   // } catch (error) {
  //   //   dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
  //   // }
  //   // setSelectedUsers([]);
  // };

  const onFilterClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const onAddClick = () => {
    navigate('/employee/add');
  };

  const handleSearch = (e) => {
    const keyword = e?.target.value.trim();
    setKeyword(keyword);
  };

  const open = Boolean(anchorEl);
  return (
    <Box>
      <Heading26Bold text="BOS Employee" />
      <PageHeader
        showFilter={true}
        showAdd={true}
        showDeleter={true}
        filterClick={onFilterClick}
        addClick={onAddClick}
        deleteClick={() => setDeleteWarning(true)}
        handleSearch={handleSearch}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { marginTop: 5 },
        }}
      >
        <Box>
          <BosEmpFilter
            onChooseFilter={(filters) => {
              setCurrentPage(1);
              fetchEmployee(filters);
            }}
          />
        </Box>
      </Popover>
      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          {selectedUsers.length ? (
            <>
              <Heading16Reg text="Do you want to Delete Users Permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDeleteUser}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select User first." />
          )}
        </Box>
      </Modal>
      <CustomDataGrid rowIdKey={'user_id'} columnsData={columnsData} rowsData={employeeList} onSelectCheckbox={handleCheckRow} />
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </Box>
  );
};

export default EmployeeList;
