import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileImport } from '../../../../theme/Images';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { FilledBtn } from '../../../../common-components';
import OutlineBtn from '../../../../common-components/buttons/OutlineBtn';
import { useNavigate, useParams } from 'react-router-dom';
import TopNav from '../../../Navigation/TopNavBar/TopNav';
import { Box, Typography, Grid, Link, Button, CircularProgress } from '@mui/material';
import Colors from '../../../../theme/colors/Color';
import { getFile, postFileData } from '../../../../services/APIService';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
const ImportProducts = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSupplier, setIsSupplier] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [linkEnabled, setLinkEnabled] = useState(true);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleUploadClick = async () => {
    if (!selectedFile) {
      console.error('No file selected'); // Debugging line
      return;
    }

    setLinkEnabled(false);
    setLoading(true);

    const data = new FormData();
    data.append('importFile', selectedFile);

    try {
      const response = await postFileData('/product/import', data, {}, userData.token);
      console.log('response-- ', response);
      if (response.status === 200) {
        setLoading(false);
        navigate('/products-record');
      } else {
        console.error('Error on product import', response);
      }
    } catch (error) {
      console.error('Error on product import', error);
    } finally {
      setLoading(false);
      setLinkEnabled(true);
    }
  };

  useEffect(() => {
    if (id) setIsSupplier(true);
  }, [id, isSupplier]);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setErrorMsg('');
      setFileName(acceptedFiles[0].name);

      // Set the selected file here
      setSelectedFile(acceptedFiles[0]);

      // Read and parse file if needed
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        if (file.name.endsWith('.xlsx')) {
          const workbook = XLSX.read(reader.result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);
          console.log('XLSX Data:', jsonData);
        } else if (file.name.endsWith('.csv')) {
          Papa.parse(file, {
            complete: (result) => {
              console.log('CSV Data:', result.data);
            },
            header: true,
          });
        }
      };

      if (file.name.endsWith('.xlsx')) {
        reader.readAsBinaryString(file);
      } else if (file.name.endsWith('.csv')) {
        reader.readAsText(file);
      }
    }

    if (rejectedFiles.length > 0) {
      setErrorMsg('Only CSV and XLSX files are allowed.');
      setFileName('');
      setSelectedFile(null); // Clear selected file if rejected
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    onDrop,
  });

  const handleBackClick = () => {
    navigate(-1);
  };
  const downloadSampleFile = async () => {
    try {
      // Make the API request to download the sample template
      const response = await getFile(
        '/product/sampleImport', // URL for downloading the sample template
        // Empty body, as we don't need to send any data in this case
        {
          headers: {
            Authorization: `Bearer ${userData.token}`, // Authorization header
          },
          responseType: 'blob', // Make sure the response is a blob (binary file)
        }
        // userData.token
      );

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob); // Create an object URL for the Blob
      link.download = 'sample_template.xlsx'; // Filename for the downloaded file

      // Trigger a click event on the link to start the download
      link.click();

      // Show success message
      dispatch(showSnackbar({ message: 'Template downloaded successfully', severity: 'success' }));
    } catch (error) {
      console.error('Error downloading template:', error);

      // Show error message
      dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    }
  };
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log(file); // Log file to check if it's being correctly set
      setSelectedFile(file);
    }
  };
  return (
    <Box>
      {isSupplier && <TopNav isSupplier={isSupplier} />}
      <Box sx={{ padding: 2, maxWidth: 600, margin: 'auto' }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          Import Product
        </Typography>
        <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="body1" sx={{ textAlign: 'right' }}>
              click to&nbsp;
              {linkEnabled ? (
                <Link href="#" underline="hover" sx={{ fontWeight: 'bold' }} onClick={downloadSampleFile}>
                  Download template
                </Link>
              ) : (
                <span style={{ fontWeight: 'bold', color: Colors.placeholder }}>Download template</span>
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: 2,
              border: '2px dashed #ccc',
              borderRadius: 2,
              textAlign: 'center',
              cursor: 'pointer',
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} onChange={handleFileChange} />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <img src={FileImport} alt="FileImport" style={{ marginRight: 8 }} />
              <Typography variant="body1" sx={{ ml: 2 }}>
                {fileName ? (
                  <span>{fileName}</span>
                ) : (
                  <>
                    upload or drop a file here{' '}
                    <Typography component="span" variant="body2">
                      CSV, xlsx
                    </Typography>
                  </>
                )}
              </Typography>
            </Box>
          </Box>
          {errorMsg && (
            <Typography color="error" sx={{ marginTop: 2 }}>
              {errorMsg}
            </Typography>
          )}
        </Box>
        <Grid container spacing={2} sx={{ marginTop: 2, justifyContent: 'center', alignItems: 'center' }}>
          <Grid item>
            {loading ? (
              <Button
                variant="contained"
                sx={{
                  minWidth: 120,
                  position: 'relative',
                  color: Colors.white,
                  background: Colors.primary,
                  textTransform: 'none',
                  padding: '0px 45px',
                  height: '45px',
                  borderRadius: '28px',
                  fontSize: '18px',
                  fontFamily: 'poppins-regular',
                  lineHeight: '24px',
                  '&:hover': {
                    color: Colors.white,
                    background: Colors.primary,
                  },
                }}
              >
                <CircularProgress size={24} sx={{ color: '#70FACB', position: 'absolute', right: '12px' }} />
                <span>Upload in Progress</span>
              </Button>
            ) : (
              <FilledBtn text={'Upload'} isdisable={false} onClick={handleUploadClick} />
            )}
          </Grid>
          <Grid item>
            <OutlineBtn text={'Back'} isdisable={false} onClick={handleBackClick} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ImportProducts;
