import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Button,
} from '@mui/material';
import Colors from '../../theme/colors/Color';
import { Edit } from '../../theme/Images';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const CustomIconBtn = styled(IconButton)(() => ({
  border: '1px solid',
  borderColor: Colors.text_black,
  borderRadius: '50%',
}));

const TBodyCell = styled(TableCell)(() => ({
  borderBottom: '1px solid',
  borderColor: Colors.secondary1,
  '&.MuiTableCell-root': { padding: '5px' },
}));

const TableBodyText = styled(Typography)(() => ({
  fontSize: '12px',
  fontFamily: 'poppins-regular',
}));

const ListDataTable = (props) => {
  const navigate = useNavigate();
  const [selectAllRow, setSelectAllRow] = useState(false);
  const [selectOneRow, setSelectOneRow] = useState([]);
  const greenColumns = ['Grade', 'Size'];
  const blueColumns = ['Enquiry No', 'Size', 'Order No', 'Product Code'];

  const handleSelectRow = (e, item) => {
    setSelectAllRow(false);
    if (e.target.checked) {
      setSelectOneRow((prev) => [...prev, item.id]);
    } else {
      const updateChecks = selectOneRow.filter((rowId) => rowId !== item.id);
      setSelectOneRow(updateChecks);
    }
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectAllRow(true);
      setSelectOneRow([]);
    } else {
      setSelectAllRow(false);
    }
  };

  // Status bg colors
  const getStatusBg = (status) => {
    switch (status) {
      case 'Yes':
        return Colors.success;
      case 'No':
        return Colors.error;
      case 'Active':
        return Colors.success;
      case 'Inactive':
        return Colors.error;
      case 'Open':
        return Colors.primary;
      case 'In Progress':
        return Colors.inprogress;
      case 'Pending':
        return Colors.error;
      case 'Awaiting':
        return Colors.awaiting;
      case 'Accepted':
        return Colors.primary;
      case 'Shipped':
        return Colors.secondary2;
      case 'Fulfilled':
        return Colors.success;
      case 'Generated':
        return Colors.status_gnrtd;
      case 'Processing':
        return Colors.error;
      case 'Complete':
        return Colors.success;
      default:
        return Colors.transparent;
    }
  };

  return (
    <Box marginTop={'20px'}>
      <TableContainer
        sx={{
          // maxHeight: '58vh',
          height: '60vh',
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: Colors.placeholder,
            borderRadius: '2px',
          },
        }}
      >
        <Table stickyHeader aria-label="skicy table">
          <TableHead>
            <TableRow sx={{ height: '70px' }}>
              <TableCell sx={{ textTransform: 'none', background: Colors.secondary1, color: Colors.white, padding: '2px' }}>
                <Checkbox
                  onChange={handleSelectAll}
                  checked={selectAllRow}
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'blue',
                    },
                  }}
                />
              </TableCell>
              {props.tableHead.map((item) => (
                <TableCell sx={{ background: Colors.secondary1, color: Colors.white, padding: '0px 10px 0px 0px' }} key={item.header}>
                  <Typography sx={{ fontFamily: 'poppins-regular', fontWeight: 500, fontSize: 14 }}>{item.header}</Typography>
                </TableCell>
              ))}
              <TableCell sx={{ background: Colors.secondary1, color: Colors.white }}>
                {' '}
                <Typography sx={{ fontFamily: 'poppins-regular', fontWeight: 400, fontSize: 14 }}>Edit</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.list.map((item, i) => {
              return (
                <TableRow key={i * 13} sx={{ background: '' }}>
                  <TBodyCell>
                    <Checkbox
                      {...label}
                      checked={selectOneRow.includes(item.id) || selectAllRow}
                      sx={{ color: Colors.text_black }}
                      onChange={(e) => handleSelectRow(e, item)}
                    />
                  </TBodyCell>
                  {props.tableHead.map((column) => (
                    <TBodyCell key={column.keyName} sx={{ minWidth: '100px' }}>
                      {column.keyName == 'status' || column.keyName == 'isVisible' ? (
                        <Box
                          sx={{
                            color: 'white',
                            // width: '90px',
                            textAlign: 'center',
                            borderRadius: '14px',
                            padding: '4px 1px',
                            background: getStatusBg(item[column.keyName]),
                          }}
                        >
                          <TableBodyText>{item[column.keyName]}</TableBodyText>
                        </Box>
                      ) : greenColumns.includes(column.header) ? (
                        <TableBodyText
                          sx={{
                            color: item.isExternal ? Colors.error : Colors.success,
                          }}
                        >
                          {item[column.keyName]}
                        </TableBodyText>
                      ) : (
                        <TableBodyText
                          sx={
                            blueColumns.includes(column.header)
                              ? {
                                  color: Colors.primary,
                                  textDecoration: 'underline',
                                  // width: column.header === 'Order No' ? '170px' : '100px',
                                  cursor: 'pointer',
                                }
                              : { color: Colors.text_black }
                          }
                          onClick={() => {
                            if (props.viewpath)
                              navigate(`${props.viewpath}/${item.id}`, { state: { eqType: item.type, isCallFrom: 'view' } });
                          }}
                        >
                          {item[column.keyName]}
                        </TableBodyText>
                      )}
                    </TBodyCell>
                  ))}
                  <TBodyCell align="center">
                    <img
                      src={Edit}
                      alt="edit"
                      onClick={() => {
                        if (props.path) navigate(`${props.path}/${item.id}`, { state: { eqType: item.type, isCallFrom: 'edit' } });
                      }}
                    />
                  </TBodyCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'200px'} margin={'0px auto'} marginTop={'20px'}>
        <CustomIconBtn>
          <ArrowBackIosNewRoundedIcon />
        </CustomIconBtn>
        <Typography>1-10/18</Typography>
        <CustomIconBtn>
          <ArrowForwardIosRoundedIcon />
        </CustomIconBtn>
      </Box>
    </Box>
  );
};
ListDataTable.propTypes = {
  tableHead: PropTypes.array,
  list: PropTypes.array,
  singleSelection: PropTypes.func,
  selectAll: PropTypes.func,
  path: PropTypes.string,
  viewpath: PropTypes.string,
};
export default ListDataTable;
