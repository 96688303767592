import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    firmId: null,
    gstNo: '',
    type: '',
  },
};

export const firmSlice = createSlice({
  name: 'firm',
  initialState,
  reducers: {
    addFirmId(state, action) {
      state.data = action.payload;
    },
    clearFirmId(state) {
      state.data = {
        firmId: null,
        gstNo: '',
        type: '',
      };
    },
  },
});

export const { addFirmId, clearFirmId } = firmSlice.actions;
export default firmSlice.reducer;
