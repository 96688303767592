import { Box, IconButton, styled, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../../theme/colors/Color';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import PropTypes from 'prop-types';

const CustomIconBtn = styled(IconButton)(() => ({
  border: '1px solid',
  borderColor: Colors.text_black,
  borderRadius: '50%',
  '&:disabled': {
    borderColor: Colors.placeholder,
  },
}));

const Pagination = ({ currentPage = 1, totalPages = 1, onPageChange }) => {
  //   const [page, setPage] = useState(currentPage);
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'200px'} margin={'0px auto'} marginTop={'20px'}>
      <CustomIconBtn disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
        <ArrowBackIosNewRoundedIcon />
      </CustomIconBtn>
      <Typography>
        {currentPage} / {totalPages}
      </Typography>
      <CustomIconBtn disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
        <ArrowForwardIosRoundedIcon />
      </CustomIconBtn>
    </Box>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default Pagination;
