import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Paper,
  // styled,
  Grid,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Typography,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { getData, postFileData } from '../../../services/APIService';
import { Heading20Semi } from '../../../common-components';
import Colors from '../../../theme/colors/Color';

const Comment = ({ module_id, comment_type }) => {
  const [allcomments, setAllComments] = useState([]);
  const [comment, setComment] = useState('');
  const [selectedDocument, setSelectedDocument] = useState(null);
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  const fetchComments = async () => {
    try {
      const res = await getData('/comments/getCommentDataById', { id: module_id }, userData.token);
      if (Array.isArray(res.data.data)) {
        setAllComments(res.data.data);
      } else {
        setAllComments([]);
      }
    } catch (error) {
      console.error(error);
      setAllComments([]);
    }
  };

  useEffect(() => {
    if (module_id) fetchComments();
  }, [module_id]);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedDocument(event.target.files[0]);
    }
  };

  const removeFile = () => {
    setSelectedDocument('');
    document.getElementById('upload-pdf-2').value = '';
  };

  const addComments = async () => {
    const data = new FormData();
    data.append('module_id', module_id);
    data.append('module_comment_type', comment_type);
    data.append('comment', comment);
    if (selectedDocument) {
      data.append('files', selectedDocument);
    }

    try {
      const response = await postFileData('/comments/addcomments', data, {}, userData.token);
      if (response.status === 200) {
        setComment('');
        removeFile();
        fetchComments();
      } else {
        console.error('Error adding comment', response);
      }
    } catch (error) {
      console.error('Error adding comment', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format date to '14 Feb 2024'
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    // Format time to '12:30 PM'
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return `${formattedDate}, ${formattedTime}`;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={5} sx={{ borderRadius: '10px', padding: '20px', height: '550px', overflowY: 'auto' }}>
          <FormControl>
            <FormLabel>
              <Heading20Semi text={'Comments '} />
            </FormLabel>
          </FormControl>
          <Box sx={{ border: '1px solid black', borderRadius: 2 }}>
            <TextField
              fullWidth
              multiline
              minRows={2}
              placeholder="Add Comments"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              sx={{
                marginBottom: 2,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none', // Remove the border
                  },
                },
              }}
            />

            <Divider
              sx={{
                width: '96%',
                borderBottom: '2px solid',
                borderColor: Colors.primary,
                m: 'auto',
                mb: -2,
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
              <input type="file" style={{ display: 'none' }} id="upload-pdf-2" onChange={handleFileChange} />
              <label htmlFor="upload-pdf-2">
                <IconButton component="span">
                  <AttachFileIcon />
                </IconButton>
              </label>
              {selectedDocument && (
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
                  <span>{selectedDocument.name}</span>
                  <IconButton onClick={removeFile}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
              <Box sx={{ marginLeft: 'auto' }}>
                <IconButton color="primary" onClick={addComments}>
                  <SendIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Typography variant="caption" display="block" sx={{ marginBottom: 2, marginTop: 1, color: Colors.placeholder, fontSize: '13px' }}>
            Maximum attachment size for this enquiry 2.5 mb
          </Typography>
          <List
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              maxHeight: '320px',
              '&::-webkit-scrollbar': {
                width: '12px',
                height: '11px',
                cursor: 'pointer',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: Colors.secondary1,
                borderRadius: '6px',
                border: '3px solid transparent',
                backgroundClip: 'padding-box',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: Colors.primary,
              },
            }}
          >
            {allcomments.map((comment) => (
              <ListItem key={comment.comment_id} alignItems="flex-start">
                <Avatar sx={{ fontSize: 12, background: 'black', mr: 0.5 }}>
                  {comment.commentUser?.name?.substring(0, 3)?.toUpperCase()}
                </Avatar>
                <ListItemText primary={comment.comment} secondary={formatDate(comment.createdAt)} sx={{ marginLeft: 2 }} />

                {/* Attachment List */}
                {Array.isArray(comment.attachmentList) && comment.attachmentList.length > 0 && (
                  <Box sx={{ marginTop: 1, marginLeft: 7 }}>
                    {comment.attachmentList.map((attachment, index) => (
                      <Typography
                        key={index}
                        variant="body2"
                        component="span"
                        sx={{ display: 'block', color: 'blue', textDecoration: 'underline' }}
                      >
                        <a href={attachment.Location} target="_blank" rel="noopener noreferrer">
                          {attachment.key.split('_')[2]}
                        </a>
                      </Typography>
                    ))}
                  </Box>
                )}
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

Comment.propTypes = {
  module_id: PropTypes.string,
  comment_type: PropTypes.string,
};

export default Comment;
