import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getData } from '../../../../services/APIService';
import { Heading26Bold } from '../../../../utils/component';

const TallyInvetory = () => {
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [list, setList] = useState([]);
  const fetchTallyFinancials = async () => {
    await getData(`/tally/tally_inventory`, {}, userData.token)
      .then((res) => {
        if (res.data.data) {
          setList(res.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchTallyFinancials();
  }, []);

  const dateFormat = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getUTCDate();
    const month = dateObject.getUTCMonth() + 1;
    const year = dateObject.getUTCFullYear();
    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  return (
    <>
      <Heading26Bold text="Tally Inventory Master" />
      <TableContainer component={Paper} sx={{ marginTop: 5 }}>
        <Table sx={{ minWidth: 650, border: '1px solid #ddd' }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#0093D3' }}>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Product code</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Heat No</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Supplier Code</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Supplier GST no.</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Name of Supplier</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Wearhouse location </TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Opening stock</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Inward stock</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Outward</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Tally/total stock=opening + inward-outward</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Export Date</TableCell>
              {/* <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Status</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Blocked stock</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Sold</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Display Stock</TableCell>
              <TableCell sx={{ border: '1px solid #ddd', color: '#fff' }}>Validation</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid #ddd' }} component="th" scope="row">
                  {row.product_code}
                </TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.heat_number}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.supplier_code}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.supplier_gst}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.supplier_name}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.warehouse_location}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.opening_stock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.inward_stock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.outward_stock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.total_stock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{dateFormat(row.export_date)}</TableCell>
                {/* <TableCell sx={{ border: '1px solid #ddd' }}>{row.status}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.blockStock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.sold}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.displayStock}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.validation}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TallyInvetory;
