import { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';

export const useAddProducts = (productImgs) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const replaceImageInputRef = useRef(null);
  const [replaceIndex, setReplaceIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState({ pdf: null });
  const [deletedIds, setDeletedIds] = useState([]);
  useEffect(() => {
    setUploadedImages(productImgs);
  }, [productImgs]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 5 * 1024 * 1024,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0 || !acceptedFiles.every((file) => file.type.startsWith('image/'))) {
        setError('Files/Folders are not supported\nOR Image exceeds 5MB.');
        setTimeout(() => setError(''), 5000);
        return;
      }
      const file = acceptedFiles.filter((file) => file.size <= 5 * 1024 * 1024);
      if (file.size > 5 * 1024 * 1024) {
        setError('Files/Folders are not supported\nOR Image exceeds 5MB.');
        setTimeout(() => setError(''), 5000);
        return;
      }
      setLoading(true);
      const newImages = file.map((file) => ({
        src: URL.createObjectURL(file),
        file,
      }));
      setTimeout(() => {
        setUploadedImages((prevImages) => [...prevImages, ...newImages]);
        setLoading(false);
        setError('');
      }, 1000);
    },
  });

  const handleDeleteImage = (index) => {
    const newImages = [...uploadedImages];
    const newIds = [...deletedIds];
    const id = newImages[index].id;
    if (id && !newIds.includes(id)) newIds.push(id);
    newImages.splice(index, 1);
    setDeletedIds(newIds);
    setUploadedImages(newImages);
  };

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleReplaceClick = (index) => {
    setReplaceIndex(index);
    replaceImageInputRef.current.click();
  };

  const handleReplaceChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      handleReplaceImage(selectedFile, replaceIndex);
    }
  };

  const handleReplaceImage = (file, index) => {
    const newImage = {
      src: URL.createObjectURL(file),
      file,
    };
    setUploadedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = newImage;
      return updatedImages;
    });
  };

  return {
    isLoading,
    error,
    uploadedImages,
    file,
    getRootProps,
    getInputProps,
    handleDeleteImage,
    open,
    selectedImage,
    handleOpen,
    handleClose,
    handleReplaceClick,
    handleReplaceChange,
    replaceImageInputRef,
    deletedIds,
  };
};
