import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData } from '../../../services/APIService';

export const fetchSuppliers = createAsyncThunk('supplierList/fetchSupplier', async () => {
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const res = await getData('/firm/all?type=SUPPLIER', {}, userData.token);
  return res.data.data;
});

const initialState = {
  data: [],
};

export const supplierListSlice = createSlice({
  name: 'supplierList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSuppliers.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default supplierListSlice.reducer;
