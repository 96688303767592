import React, { useState } from 'react';
import { Grid, Box, Typography, TextField, Modal, Button } from '@mui/material';
import { NoPreview } from '../../../../theme/Images';
import Heading16Bold from '../../../../common-components/headings/Heading16Bold';
import PropTypes from 'prop-types';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { deleteData } from '../../../../services/APIService';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { Heading16Reg } from '../../../../utils/component';

const userData = JSON.parse(localStorage.getItem('userDetails'));

const DeleteModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 4,
  textAlign: 'center',
};

export const PaymentDetails = ({ orderinfo, order_id, refresh }) => {
  const [deleteWarning, setDeleteWarning] = useState(false);
  const dispatch = useDispatch();

  let lastTerm = '';

  if (orderinfo && orderinfo.payment_option && orderinfo.payment_option.payment_option) {
    lastTerm = orderinfo.payment_option.payment_option;
  }

  const fileUrl = orderinfo?.order_payment?.length > 0 ? orderinfo.order_payment[0]?.attachment : '';
  const fullFileName = fileUrl ? fileUrl.split('/').pop() : '';
  const fileName = fullFileName ? fullFileName.split('_').slice(-2).join('_') : '';
  const deletePaymentDetails = async () => {
    try {
      const res = await deleteData(`/payment/${order_id}`, { is_oa_generated: true }, userData.token);

      if (res && res.data && res.data.status) {
        setDeleteWarning(false);
        refresh();
        dispatch(
          showSnackbar({
            message: 'Payment details deleted successfully',
            severity: 'success',
          })
        );
      } else {
        dispatch(
          showSnackbar({
            message: 'Something went wrong',
            severity: 'error',
          })
        );
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        dispatch(
          showSnackbar({
            message: error.response.data.message,
            severity: 'error',
          })
        );
      } else {
        dispatch(
          showSnackbar({
            message: 'Something went wrong...!',
            severity: 'error',
          })
        );
      }
    }
  };

  return (
    <>
      {orderinfo.order_payment && orderinfo.order_payment.length > 0 ? (
        <>
          <Typography variant="subtitle1" gutterBottom>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Heading16Bold text={'Payment Details:'} />
              {orderinfo.status === 'Awaiting' && (
                <Typography variant="body2" sx={{ color: 'red' }} onClick={() => setDeleteWarning(true)}>
                  Delete Payment details
                </Typography>
              )}
            </Box>
          </Typography>

          {lastTerm === 'Advance' ? (
            <>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                RTGS transfer details
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">UTR No.</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter UTR No."
                      disabled
                      value={orderinfo?.order_payment[0]?.utr_no || ''}
                      InputProps={{
                        sx: {
                          height: '35px',
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Name of Bank</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter Name of Bank"
                      value={orderinfo?.order_payment[0]?.bank_name || ''}
                      disabled
                      InputProps={{
                        sx: {
                          height: '35px',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Date of Transaction</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter Date of Transaction"
                      value={orderinfo?.order_payment[0]?.transaction_date || ''}
                      disabled
                      InputProps={{
                        sx: {
                          height: '35px',
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Amount</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter Amount"
                      disabled
                      value={orderinfo?.order_payment[0]?.amount || ''}
                      InputProps={{
                        sx: {
                          height: '35px',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : lastTerm === 'BG' ? (
            <>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Letter of credit details
              </Typography>
              <Box
                sx={{
                  marginTop: '14px',
                  width: '95%',
                  display: 'inline-flex',
                  alignItems: 'center',
                  border: '1px solid black', // Add the black border
                  borderRadius: '4px', // Border radius
                  padding: '4px', // Optional padding around the icon
                }}
              >
                <AttachFileIcon />
                <Typography
                  variant="body2"
                  component="a"
                  href={orderinfo?.order_payment[0]?.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'none', color: 'inherit' }} // Optional: to keep the default text styling
                >
                  {fileName || 'No attachment available'}
                </Typography>
              </Box>
            </>
          ) : lastTerm === 'PDC' ? (
            <>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                RTGS transfer details
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Upload scanned copy of Cheque.</Typography>
                    <Box
                      sx={{
                        width: '95%',
                        display: 'inline-flex',
                        alignItems: 'center',
                        border: '1px solid black',
                        borderRadius: '4px',
                        padding: '4px',
                      }}
                    >
                      <AttachFileIcon />
                      <Typography
                        variant="body2"
                        component="a"
                        href={orderinfo?.order_payment[0]?.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ textDecoration: 'none', color: 'inherit' }} // Optional: to keep the default text styling
                      >
                        {fileName || 'No attachment available'}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Name of Courier </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={orderinfo?.order_payment[0]?.courier_name || ''}
                      disabled
                      InputProps={{
                        sx: {
                          height: '35px',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Docket No</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={orderinfo?.order_payment[0]?.docket_no || ''}
                      disabled
                      InputProps={{
                        sx: {
                          height: '35px',
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">Courier sent on Date</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      value={orderinfo?.order_payment[0]?.courier_name || ''}
                      InputProps={{
                        sx: {
                          height: '35px',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : lastTerm === 'LC' ? (
            <>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Letter of credit details
              </Typography>
              <Box
                sx={{
                  marginTop: '14px',
                  width: '95%',
                  display: 'inline-flex',
                  alignItems: 'center',
                  border: '1px solid black', // Add the black border
                  borderRadius: '4px', // Border radius
                  padding: '4px', // Optional padding around the icon
                }}
              >
                <AttachFileIcon />
                <Typography
                  variant="body2"
                  component="a"
                  href={orderinfo?.order_payment[0]?.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'none', color: 'inherit' }} // Optional: to keep the default text styling
                >
                  {fileName || 'No attachment available'}
                </Typography>
              </Box>
            </>
          ) : null}
        </>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={NoPreview} alt="no Preview" style={{ height: '60px', width: '80px', marginTop: '20px' }} />
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            No Payment Details Added.
          </Typography>
        </Box>
      )}
      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteModalstyle}>
          <>
            <Heading16Reg text=" Are you sure you want to delete the payment details provided by customer," />
            <Heading16Reg text=" if deleted the customer will have to re-enter the details" />
            <Heading16Reg text=" this action can be performed one time only." />
            <Heading16Reg text="Do you want to Delete Users Permanantly?" />
            <Box sx={{ display: 'flex', gap: 2, mt: 1, justifyContent: 'center' }}>
              <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                Cancel
              </Button>
              <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={deletePaymentDetails}>
                Delete
              </Button>
            </Box>
          </>
        </Box>
      </Modal>
    </>
  );
};

PaymentDetails.propTypes = {
  orderinfo: PropTypes.object.isRequired,
  order_id: PropTypes.string,
  refresh: PropTypes.func,
};
