import { Box, Button, Grid, Paper, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Edit, Delete } from '../../../../theme/Images';
import Colors from '../../../../theme/colors/Color';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { postData, updateData } from '../../../../services/APIService';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { useDispatch } from 'react-redux';
import {
  Combobox,
  CustomDataGrid,
  FilledButton,
  Heading16Reg,
  Heading20Semi,
  Heading24Semi,
  InputTextField,
  OutlineButton,
} from '../../../../utils/component';

const limit = 10;
let totalPages = 1;

const CustomePaper = styled(Paper)(() => ({
  width: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2px',
  cursor: 'pointer',
}));

const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 4,
};

const DeleteModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 4,
};

const FieldBox = styled(Box)(() => ({
  height: '10vh',
  marginBottom: '2.5px',
}));

const selectList = [
  { value: 'Office', label: 'Office' },
  { value: 'Warehouse', label: 'Warehouse' },
];

const ContactDetails = () => {
  const firmData = JSON.parse(localStorage.getItem('firmData'));
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [open, setOpen] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [selectedRows, setselectedRows] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [showSubBtn, setShowSubBtn] = useState(true);
  const dispatch = useDispatch();
  let firm_id = firmData?.firmId;
  const initForm = {
    address_id: '',
    firm_id,
    mobile_number: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    postal_code: '',
    is_default: false,
    type: '',
  };
  const [formData, setFormData] = useState(initForm);
  useEffect(() => {
    setViewMode(firmData?.mode == 'view');
    if (firmData) {
      fetchEmpContacts();
      setFormData((prev) => ({ ...prev, firm_id: firmData.firmId }));
    }
  }, [page]);
  useEffect(() => {
    if (formData.type && formData.city) {
      setShowSubBtn(false);
    } else {
      setShowSubBtn(true);
    }
  }, [formData.type, formData.city]);

  const fetchEmpContacts = async () => {
    try {
      const res = await postData(`/address/all`, { page, limit, filters: {}, firm_id }, {}, userData.token);
      if (res && res.data && res.data.status) {
        totalPages = res.data.data.totalPages;
        setContactList(res.data.data.addressData);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };

  const handleTextChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleAddBtn = () => {
    setFormData(initForm);
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      delete formData.address_id;
      const res = await postData('/address/firm/add', formData, {}, userData.token);
      if (res && res.data && res.data.status) {
        fetchEmpContacts();
        setFormData(initForm);
        setOpen(false);
        setShowSubBtn(true);
        dispatch(showSnackbar({ message: 'Address Details added successfully', severity: 'success' }));
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const res = await updateData(`/address/${formData.address_id}`, formData, {}, userData.token);
      if (res && res.data && res.data.status) {
        fetchEmpContacts();
        setFormData(initForm);
        dispatch(showSnackbar({ message: 'Address Updated successfully', severity: 'success' }));
        setOpen(false);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };

  const handleDelete = async () => {
    try {
      const res = await postData('/address/bulkDelete', { adressIds: selectedRows }, {}, userData.token);
      if (res && res && res.data.status) {
        setDeleteWarning(false);
        fetchEmpContacts();
        dispatch(showSnackbar({ message: 'Employee deleted successfully', severity: 'success' }));
        setOpen(false);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
    setselectedRows([]);
  };

  const columnsData = [
    {
      field: 'user',
      headerName: 'Name of user',
      flex: 1.2,
      valueGetter: (params) => params?.name,
    },
    { field: 'type', headerName: 'Address Type', flex: 1.2 },
    { field: 'address_line_1', headerName: 'Address Line 1', flex: 1 },
    { field: 'address_line_2', headerName: 'Address Line 2', flex: 1 },
    { field: 'city', headerName: 'City', flex: 1 },
    { field: 'state', headerName: 'State', flex: 1 },
    { field: 'postal_code', headerName: 'Pin Code', flex: 0.9 },
    { field: 'mobile_number', headerName: 'Contact No.', flex: 1.2 },
    { field: 'is_default', headerName: 'Default Status', flex: 1.3, valueGetter: (params) => (params ? 'Default' : '') },
    {
      headerName: 'Edit',
      width: 70,
      renderCell: (params) => {
        const handleEdit = () => {
          if (viewMode) {
            dispatch(showSnackbar({ message: 'View Only', severity: 'warning' }));
          } else {
            setFormData(params.row);
            setOpen(true);
          }
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
    },
  ];

  return (
    <Box>
      <Box sx={{ display: 'flex', height: '50px', justifyContent: 'space-between', alignItems: 'center' }}>
        <Heading24Semi text={'Added Locations'} />
        {viewMode ? null : (
          <Box display={'flex'} width={'8vw'} justifyContent={'space-between'}>
            <CustomePaper elevation={3} onClick={handleAddBtn}>
              <AddRoundedIcon sx={{ height: '35px' }} />
              <Typography sx={{ fontSize: '10px', color: Colors.text_black, fontFamily: 'poppins-regular', marginTop: '-4px' }}>
                ADD
              </Typography>
            </CustomePaper>
            <CustomePaper elevation={3} onClick={() => setDeleteWarning(true)}>
              <img src={Delete} style={{ height: '18px', marginTop: '-4px' }} alt="delete" />
              <Typography sx={{ fontSize: '10px', color: Colors.text_black, fontFamily: 'poppins-regular', marginBottom: '-16px' }}>
                Delete
              </Typography>
            </CustomePaper>
          </Box>
        )}
      </Box>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false), setFormData(initForm);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          <CloseRoundedIcon sx={{ float: 'right' }} onClick={() => setOpen(false)} />
          <Box width={'100%'}>
            <Heading20Semi text={'Add Address'} />
            <Grid container marginTop={'35px'}>
              <Grid item xs={12} lgm={6} paddingRight={{ xs: 0, md: '15px' }}>
                <FieldBox>
                  <Combobox
                    label={'Address Type'}
                    optionsLabel={'label'}
                    options={selectList}
                    isDisable={false}
                    isRequire={false}
                    value={formData.type}
                    onChangeValue={(item) => setFormData((prev) => ({ ...prev, type: item?.label }))}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Address Line 1'}
                    name={'address_line_1'}
                    value={formData.address_line_1}
                    placeholder={'Enter address line'}
                    isRequire={false}
                    isDisable={false}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'City'}
                    name={'city'}
                    value={formData.city}
                    placeholder={'Enter City'}
                    isRequire={false}
                    isDisable={false}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Pin Code'}
                    name={'postal_code'}
                    value={formData.postal_code}
                    placeholder={''}
                    isRequire={false}
                    isDisable={false}
                    onChange={handleTextChange}
                  />
                </FieldBox>
              </Grid>
              <Grid item xs={12} lgm={6} paddingLeft={{ xs: 0, md: '15px' }}>
                <FieldBox>
                  <InputTextField
                    label={'Contact No.'}
                    name={'mobile_number'}
                    value={formData.mobile_number}
                    placeholder={'+9874563210'}
                    isRequire={false}
                    isDisable={false}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Address Line 2'}
                    name={'address_line_2'}
                    value={formData.address_line_2}
                    placeholder={'Enter address line'}
                    isRequire={false}
                    isDisable={false}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'State'}
                    name={'state'}
                    value={formData.state}
                    placeholder={'Enter State'}
                    isRequire={false}
                    isDisable={false}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                {/* <FieldBox>
                  <Heading16Reg text={'Default Status'} />
                  <Box
                    sx={{
                      color: formData.is_default ? Colors.placeholder : Colors.primary,
                      fontFamily: 'poppins-regular',
                      textDecoration: 'underline',
                      textDecorationColor: 'initial',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      formData.is_default
                        ? setFormData((prev) => ({ ...prev, is_default: false }))
                        : setFormData((prev) => ({ ...prev, is_default: true }));
                    }}
                  >
                    Make Default
                  </Box>
                </FieldBox> */}
              </Grid>
            </Grid>
            <Box
              width={'22.8vw'}
              sx={{
                margin: '0px auto',
                marginTop: '20px',
                marginBottom: '10px',
                display: 'flex',
                gap: '25px',
              }}
            >
              <OutlineButton
                text={'Cancel'}
                onClick={() => {
                  setOpen(false), setFormData(initForm);
                }}
              />
              {formData.address_id ? (
                <FilledButton text={'Update'} onClick={handleUpdate} />
              ) : (
                <FilledButton text={'Submit'} onClick={handleSubmit} isdisable={showSubBtn} />
              )}
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteModalstyle}>
          {selectedRows?.length ? (
            <>
              <Heading16Reg text="Do you want to Delete Users Permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDelete}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select User first." />
          )}
        </Box>
      </Modal>

      <CustomDataGrid
        rowIdKey={'address_id'}
        columnsData={columnsData}
        rowsData={contactList}
        onSelectCheckbox={(r) => setselectedRows(r)}
      />
      <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
    </Box>
  );
};

export default ContactDetails;
