import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FilledButton, OutlineButton } from '../../../utils/component';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Typography } from '@mui/material';

function createData(name, bosAdmin, salesAdmin, productAdmin, kam, invoice, employee) {
  return {
    name,
    bosAdmin,
    salesAdmin,
    productAdmin,
    kam,
    invoice,
    employee,
  };
}
const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 5,
};
function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [checkedStates, setCheckedStates] = React.useState({
    bosAdmin: row.bosAdmin,
    salesAdmin: row.salesAdmin,
    productAdmin: row.productAdmin,
    kam: row.kam,
    invoice: row.invoice,
    employee: row.employee,
  });

  const handleCheckboxChange = (key) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ width: '48px' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '200px', fontFamily: 'poppins-regular' }}>
          {row.name}
        </TableCell>
        {['bosAdmin', 'salesAdmin', 'productAdmin', 'kam', 'invoice', 'employee'].map((key) => (
          <TableCell key={key} align="center" style={{ width: '120px', padding: 0 }}>
            <Checkbox
              checked={checkedStates[key]}
              onChange={() => handleCheckboxChange(key)}
              sx={{
                '&.Mui-checked': {
                  color: '#63A47C',
                },
              }}
            />
          </TableCell>
        ))}
      </TableRow>

      <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }} colSpan={8}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box>
            {row.name === 'User management' && (
              <Table size="small" aria-label="user-management-details">
                <TableBody>
                  {['Add / Edit User', 'BOS Employee', 'Customer', 'Supplier', 'Vendor'].map((item) => (
                    <TableRow key={item} sx={{ padding: 0 }}>
                      {/* Empty cell to align with the expand/collapse icon from the main table */}
                      <TableCell style={{ width: '48px' }} />
                      <TableCell align="left" style={{ width: '200px', fontFamily: 'poppins-regular' }}>
                        {item}
                      </TableCell>
                      {['bosAdmin', 'salesAdmin', 'productAdmin', 'kam', 'invoice', 'employee'].map((key) => (
                        <TableCell key={key} align="center" style={{ width: '120px' }}>
                          <Checkbox
                            sx={{
                              '&.Mui-checked': {
                                color: '#63A47C',
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {row.name === 'Enquiry management' && (
              <Table size="small" aria-label="user-management-details">
                <TableBody>
                  {['Add / Edit Enquiry'].map((item) => (
                    <TableRow key={item} sx={{ padding: 0 }}>
                      <TableCell style={{ width: '48px' }} />
                      <TableCell align="left" style={{ width: '200px' }}>
                        {item}
                      </TableCell>
                      {['bosAdmin', 'salesAdmin', 'productAdmin', 'kam', 'invoice', 'employee'].map((key) => (
                        <TableCell key={key} align="center" style={{ width: '120px' }}>
                          <Checkbox
                            sx={{
                              '&.Mui-checked': {
                                color: '#63A47C',
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {row.name === 'Product management' && (
              <Table size="small" aria-label="user-management-details">
                <TableBody>
                  {['Add / Edit Product'].map((item) => (
                    <TableRow key={item} sx={{ padding: 0 }}>
                      <TableCell style={{ width: '48px' }} />
                      <TableCell align="left" style={{ width: '200px' }}>
                        {item}
                      </TableCell>
                      {['bosAdmin', 'salesAdmin', 'productAdmin', 'kam', 'invoice', 'employee'].map((key) => (
                        <TableCell key={key} align="center" style={{ width: '120px' }}>
                          <Checkbox
                            sx={{
                              '&.Mui-checked': {
                                color: '#63A47C',
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {row.name === 'Order management' && (
              <Table size="small" aria-label="user-management-details">
                <TableBody>
                  {['Add / Edit Order'].map((item) => (
                    <TableRow key={item} sx={{ padding: 0 }}>
                      {/* Empty cell to align with the expand/collapse icon from the main table */}
                      <TableCell style={{ width: '48px' }} />
                      <TableCell align="left" style={{ width: '200px' }}>
                        {item}
                      </TableCell>
                      {['bosAdmin', 'salesAdmin', 'productAdmin', 'kam', 'invoice', 'employee'].map((key) => (
                        <TableCell key={key} align="center" style={{ width: '120px' }}>
                          <Checkbox
                            sx={{
                              '&.Mui-checked': {
                                color: '#63A47C',
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {row.name === 'Community' && (
              <Table size="small" aria-label="user-management-details">
                <TableBody>
                  {['Add / Edit Community'].map((item) => (
                    <TableRow key={item} sx={{ padding: 0 }}>
                      {/* Empty cell to align with the expand/collapse icon from the main table */}
                      <TableCell style={{ width: '48px' }} />
                      <TableCell align="left" style={{ width: '200px' }}>
                        {item}
                      </TableCell>
                      {['bosAdmin', 'salesAdmin', 'productAdmin', 'kam', 'invoice', 'employee'].map((key) => (
                        <TableCell key={key} align="center" style={{ width: '120px' }}>
                          <Checkbox
                            sx={{
                              '&.Mui-checked': {
                                color: '#63A47C',
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    bosAdmin: PropTypes.bool.isRequired,
    salesAdmin: PropTypes.bool.isRequired,
    productAdmin: PropTypes.bool.isRequired,
    kam: PropTypes.bool.isRequired,
    invoice: PropTypes.bool.isRequired,
    employee: PropTypes.bool.isRequired,
  }).isRequired,
};

const rows = [
  createData('User management', true, true, false, true, false, true),
  createData('Enquiry management', true, false, true, false, true, true),
  createData('Product management', false, true, true, true, false, true),
  createData('Order management', true, true, true, false, false, true),
  createData('Community', false, true, false, true, true, false),
  createData('Permission management', true, true, true, true, true, true),
];

export default function MenuPermission() {
  const [open, setOpen] = useState(false);
  const handleModalOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#0093D3' }}>
              <TableCell style={{ width: '48px' }} />
              <TableCell style={{ width: '200px', color: 'white' }}>Permission</TableCell>
              <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                BOS admin
              </TableCell>
              <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                Sales admin
              </TableCell>
              <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                Product admin
              </TableCell>
              <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                KAM
              </TableCell>
              <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                Invoice
              </TableCell>
              <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                Employee
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        width={{ xs: '65vw', sm: '30vw' }}
        sx={{
          margin: '0px auto',
          marginTop: '30px',
          display: 'flex',
          gap: '25px',
        }}
      >
        <FilledButton text={'Save'} onClick={handleModalOpen} />
        <OutlineButton text={'Cancel'} />
      </Box>
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={Modalstyle}>
          <CloseRoundedIcon sx={{ float: 'right' }} onClick={() => setOpen(false)} />
          <Box width={'100%'}>
            <Typography mt={5} style={{ textAlign: 'center' }}>
              Are you sure you want to make changes to permissions.
            </Typography>
            <Box
              width={'18.8vw'}
              sx={{
                margin: 'auto',
                marginTop: '35px',
                marginBottom: '10px',
                display: 'flex',
                gap: '25px',
              }}
            >
              <FilledButton text={'Confirm'} />
              <OutlineButton text={'Cancel'} onClick={() => setOpen(false)} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
