import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import TallyInvetory from '../Inventory/TallyInventory';
import FinacialDetailsTally from '../FinacialDetails/FinacialDetailsTally';
import { Heading26Bold } from '../../../../common-components';
import FinacialDetails from '../FinacialDetails/FinacialDetails';
import InventoryHistory from '../Inventory/InventoryHistory';
import Inventory from '../Inventory/Inventory';
const TallyMaster = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ['Tally Finacial Details', 'Finacial Details', 'Tally Invetory Details', 'Inventory History', 'Inventory'];

  return (
    <>
      <Heading26Bold text={'Master Management'} />

      <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
        {tabs.map((tab, index) => (
          <Box
            key={index}
            onClick={() => setActiveTab(index)}
            sx={{
              width: '226px',
              height: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: '20px ',
              backgroundColor: activeTab === index ? '#70FACB4D' : 'transparent',
              transition: 'background-color 0.3s ease',
              border: activeTab === index ? '1px solid #70FACB4D' : '1px solid transparent',
              marginRight: index !== tabs.length - 1 ? '10px' : '0', // Add gap between tabs
            }}
          >
            <Typography variant="body1" sx={{ fontFamily: 'poppins-regular', opacity: activeTab === index ? 1 : 0.5, fontWeight: 'bold' }}>
              {tab}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ mt: 3 }}>
        {activeTab === 0 && <FinacialDetails />}
        {activeTab === 1 && <FinacialDetailsTally />}
        {activeTab === 2 && <TallyInvetory />}
        {activeTab === 3 && <InventoryHistory />}
        {activeTab === 4 && <Inventory />}
      </Box>
    </>
  );
};

export default TallyMaster;
