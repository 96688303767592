import { Box, Grid, styled } from '@mui/material';
import React from 'react';
// import FloatingLabelSelect from '../../../../common-components/select/FloatingLabelSelect';
// import FloatingLabelBox from '../../../../common-components/inputbox/FloatingLabelBox';
import PropTypes from 'prop-types';
import Combobox from '../../../../utils/component/combo-box/Combobox';

// const selectList = [
//   { value: 10, label: 'Option 1' },
//   { value: 20, label: 'Option 2' },
//   { value: 30, label: 'Option 3' },
// ];

const ManageItemBox = styled(Box)(() => ({
  height: '70px',
}));

const GeneralEnquiry = ({ enquiryData }) => {
  // const selectedvalue = (e) => {
  //   console.log(e);
  // };
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} lgm={6} paddingRight={{ xs: 0, md: '5px' }}>
          <ManageItemBox>
            <Combobox
              label={'Type Of Enquiry'}
              optionsLabel={'enquiry_type'}
              options={[]}
              isDisable={true}
              isRequire={false}
              value={enquiryData?.enquiry_type}
            />
          </ManageItemBox>
        </Grid>
        <Grid item xs={12} lgm={6} paddingLeft={{ xs: 0, md: '5px' }}>
          <ManageItemBox>
            <Combobox
              label={'KAM'}
              optionsLabel={'kam_name'}
              options={[]}
              isDisable={true}
              isRequire={false}
              value={enquiryData.kam?.name}
            />
          </ManageItemBox>
        </Grid>
      </Grid>
      <ManageItemBox>
        <Combobox
          label={'Enquiry Details'}
          optionsLabel={'details'}
          options={[]}
          isDisable={true}
          isRequire={false}
          value={enquiryData.enquiry_details}
        />
      </ManageItemBox>
    </Box>
  );
};
GeneralEnquiry.propTypes = {
  enquiryData: PropTypes.object,
};
export default GeneralEnquiry;
