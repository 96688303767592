import React, { useState } from 'react';
import { Box, IconButton, Popover, Typography, styled } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Logo, ProfileIcon } from '../../../theme/Images';
import { Bell } from '../../../theme/Images';
import Colors from '../../../theme/colors/Color';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios'
import { getData } from '../../../services/APIService';

const SXNavContainer = {
  background: Colors.primary,
  height: 60,
  width: { xs: `calc(100vw - 60px)`, md: `calc(100% - 60px)` },
  padding: '0px 30px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const CustomIconBtn = styled(IconButton)(() => ({
  background: Colors.white,
  padding: '4px',
  '&:hover': {
    backgroundColor: Colors.white,
  },
}));

const TopNav = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const base_url = process.env.REACT_APP_API_BASEURL
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const navigate = useNavigate();

  const handleClickProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const logout = async () => {
    if (userData) {
      try {
        const res = await getData(`/auth/logout/${userData.user.user_id}`, {}, userData.token);
        if (res.data.status) navigate('/login');

        localStorage.clear();
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <Box sx={SXNavContainer}>
      <img src={Logo} alt="logo" style={{ height: '55px', display: props.isSupplier ? 'block' : 'none' }} />
      {!props.isSupplier && (
        <Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => props.drawerToggle()}
            sx={{ display: { md: 'none' }, padding: '0px' }}
          >
            <MenuIcon style={{ color: Colors.white, height: '32px', width: '32px' }} />
          </IconButton>
        </Box>
      )}
      {!props.isSupplier && (
        <Box display={'flex'} gap={'30px'}>
          <Box>
            <CustomIconBtn onClick={handleClickProfile}>
              <img src={ProfileIcon} alt="profile" />
            </CustomIconBtn>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: { marginTop: 5 },
              }}
            >
              <Box sx={{ padding: '10px 15px' }}>
                <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '16px', cursor: 'pointer' }}>
                  {userData.user.name || userData.user.email}
                </Typography>
                <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '16px', cursor: 'pointer' }} onClick={logout}>
                  Log out
                </Typography>
              </Box>
            </Popover>
          </Box>
          <CustomIconBtn>
            <img src={Bell} alt="notification" />
          </CustomIconBtn>
        </Box>
      )}
    </Box>
  );
};

TopNav.propTypes = {
  drawerToggle: PropTypes.func,
  isSupplier: PropTypes.bool,
};

export default TopNav;
