import { Box, Link } from '@mui/material';
import React from 'react';
import { Heading26Bold } from '../../../../common-components';
import Colors from '../../../../theme/colors/Color';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// const navList = [
//   { name: 'General Details', path: 'general-details' },
//   { name: 'Log In Details', path: 'login-details' },
//   { name: 'Contact Details', path: 'contact-details' },
//   { name: 'Enquiry Details', path: 'enquiry-details' },
//   { name: 'Product Details', path: 'product-details' },
//   { name: 'Community Details', path: 'community-details' },
// ];
const AddSupplier = () => {
  const firmData = JSON.parse(localStorage.getItem('firmData'));
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  let navList;
  if (firmData?.firmId || state?.firmId) {
    navList = [
      { name: 'General Details', path: 'general-details' },
      { name: 'Log In Details', path: 'login-details' },
      { name: 'Contact Details', path: 'contact-details' },
      { name: 'Enquiry Details', path: 'enquiry-details' },
      { name: 'Product Details', path: 'product-details' },
    ];
  } else {
    navList = [
      { name: 'General Details', path: 'general-details' },
      { name: 'Log In Details' },
      { name: 'Contact Details' },
      { name: 'Enquiry Details' },
      { name: 'Product Details' },
    ];
  }

  return (
    <Box>
      <Heading26Bold text={'Add New Supplier'} />
      <Box
        sx={{
          marginTop: '30px',
          height: '50px',
          display: 'flex',
          gap: '10px',
          overflowX: 'auto',
        }}
      >
        {navList.map((item, i) => {
          let isActive = location.pathname === `/supplier/add/${item.path}` || (location.pathname === '/supplier/add' && i === 0);
          return (
            <Link
              key={i}
              onClick={() => {
                if (item.path) navigate(`/supplier/add/${item.path}`);
              }}
              sx={{
                textDecoration: 'none',
                borderRadius: '25px',
                color: isActive ? Colors.text_black : Colors.placeholder,
                background: isActive ? Colors.bg_strip : Colors.bg_grey,
                // fontSize: '16px',
                fontSize: '1rem',
                fontFamily: 'poppins-regular',
                padding: '12px 30px',
                cursor: 'pointer',
                '&:hover': {
                  background: isActive ? Colors.tersary : 'lightgray',
                },
              }}
            >
              {item.name}
            </Link>
          );
        })}
      </Box>
      <Box
        // border={'1px solid red'}
        sx={{
          height: { xl: '74vh', xs: '70vh' },
          padding: '5px',
          marginTop: '30px',
          overflow: 'auto',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default AddSupplier;
