const Logo = require('../assets/Images/boslogo.png');
const filterIcon = require('../assets/Images/filterIcon.png');
const exportIcon = require('../assets/Images/exportIcon.png');
const importIcon = require('../assets/Images/importicon.png');
const addIcon = require('../assets/Images/addIcon.png');
const deleteIcon = require('../assets/Images/deleteIcon.png');
const tableEdit = require('../assets/Images/edittable.png');
const usersIcon = require('../assets/Images/Users.png');
const enquiryManagement = require('../assets/Images/enquiryManagement.png');
const productManagement = require('../assets/Images/productManagement.png');
const userManagement = require('../assets/Images/userManagement.png');
const report = require('../assets/Images/reports.png');
const attachmentIcon = require('../assets/Images/attchment-icon.png');
const confirmationIcon = require('../assets/Images/confirmationIcon.png');
const successIcon = require('../assets/Images/successIcon.png');
const LoginPageImage = require('../assets/Images/loginpageimage.png');
const BosSmallLogo = require('../assets/Images/bossmalllogo.png');
const ProgressCircle = require('../assets/Images/progresscircle.png');
const FileImport = require('../assets/Images/icons/File-import.png');
const ProfileIcon = require('../assets/Images/icons/Profile.png');
const Bell = require('../assets/Images/icons/Bell.png');
const Edit = require('../assets/Images/icons/Edit.png');
const Filter = require('../assets/Images/icons/Filter.png');
const Add = require('../assets/Images/icons/Add.png');
const AddWhiteIcon = require('../assets/Images/icons/Add-white.png');
const Delete = require('../assets/Images/icons/Delete.png');
const RecordsFailed = require('../assets/Images/RecordsFailed.png');
const RecordsSuccess = require('../assets/Images/RecordsSuccess.png');
const OrderManagemeht = require('../assets/Images/OrderManagement.png');
const NoPreview = require('../assets/Images/NoPreview.png');
const RoundBars = require('../assets/Images/RoundBars.png');
const NotFound = require('../assets/Images/notfound 1.jpg');
const Magnifier = require('../assets/Images/Magnifier.png');
const fullLogo = require('../assets/Images/fullLogo.png');
const lockImg = require('../assets/Images/lock.png');
const communityIcon = require('../assets/Images/communityIcon.png');
const permissionIcon = require('../assets/Images/permissionicon.png');
const masterIcon = require('../assets/Images/masterIcon.png');
export {
  Logo,
  filterIcon,
  exportIcon,
  importIcon,
  addIcon,
  deleteIcon,
  tableEdit,
  usersIcon,
  enquiryManagement,
  productManagement,
  userManagement,
  communityIcon,
  permissionIcon,
  masterIcon,
  report,
  attachmentIcon,
  confirmationIcon,
  successIcon,
  LoginPageImage,
  BosSmallLogo,
  ProgressCircle,
  FileImport,
  ProfileIcon,
  Bell,
  Edit,
  Filter,
  Add,
  AddWhiteIcon,
  Delete,
  RecordsFailed,
  RecordsSuccess,
  OrderManagemeht,
  NoPreview,
  RoundBars,
  NotFound,
  Magnifier,
  fullLogo,
  lockImg,
};
