const ColorCode = {
  blue: '#0458AB',
  light_blue: '#0093D3',
  sky_blue: '#00C9D8',
  sky_blue30: '#b3eff3',
  cyan: '#d5fef0',
  cyan30: '#d4feef',
  light_grey: '#9C9A9A',
  bg_light_grey: '#DDDDDD',
  dark_green: '#63A47C',
  red: '#CB5656',
  dark_gold: '#A1673F',
  electric_blue: '#008ECC',
  dark_grey: '#78797A',
  light_black: '#413E3E',
  black: '#000000',
  grey_lte: '#D9D9D9',
  // ---
  light_cyan: '#0ABAB7',
  green: '#199348',
  yellowish: '#E8AB43',
  dark_yellowish: '#DF9149',
  light_yellow: '#F3F4C7',
  white: '#FFFFFF',
  transparent: '#FFFFFF00',
  gunmetal: '#29293D',
  scroll: '#06949E',
  sepia_brown: '#784B16',
  white_lotion: '#FDFDFDB2',
  highlight: '#F4C7C9',
  processing_dark: '#98140C',
};

export default ColorCode;
