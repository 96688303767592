import React, { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, StepConnector } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';

const defaultSteps = ['Vehicle Placed', 'Vehicle Loaded', 'Vehicle Invoiced', 'Shipped', 'Fulfilled'];

const getSteps = (sub_status) => {
  if (sub_status === 'Return Requested') {
    return [...defaultSteps, 'Return Requested'];
  }
  if (sub_status === 'Return Partial') {
    return [...defaultSteps, 'Return Requested', 'Return Partial'];
  }
  if (sub_status === 'Return Accepted') {
    return [...defaultSteps, 'Return Requested', 'Return Accepted'];
  }
  if (sub_status === 'Return Rejected') {
    return [...defaultSteps, 'Return Requested', 'Return Rejected'];
  }
  return defaultSteps;
};

const CustomStepper = styled(Stepper)({
  backgroundColor: '#63A47C',
  padding: '20px',
  borderRadius: '5px',
  marginBottom: '20px',
  width: '70%',
  margin: 'auto',
});

const CustomStepConnector = styled(StepConnector)(({ redBg }) => ({
  '& .MuiStepConnector-line': {
    borderColor: '#ccc',
    borderTopWidth: 2,
    marginTop: '6px',
  },
  '&.Mui-active .MuiStepConnector-line': {
    borderColor: redBg ? 'red' : 'blue',
  },
  '&.Mui-completed .MuiStepConnector-line': {
    borderColor: 'blue',
  },
}));

const CustomStepLabel = styled(StepLabel)(({ active, redBg }) => ({
  color: active ? '#fff' : '#ccc',
  '& .MuiStepIcon-root': {
    fontSize: '2rem',
    width: '40px',
    height: '40px',
    backgroundColor: '#fff',
    color: redBg ? 'red' : '',
    borderRadius: '50%',
  },
  '& .MuiStepIcon-text': {
    fontSize: '1rem',
  },
  '& .MuiStepLabel-label': {
    color: '#fff',
  },
  '& .Mui-disabled': {
    color: '#000',
  },
}));

const ColorlibStepIconRoot = styled('div')(({ isRed }) => ({
  backgroundColor: isRed ? 'red' : '#0458AB',
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
}));

function ColorlibStepIcon(props) {
  const { icon, isRed } = props;
  const icons = {
    1: <Check />,
    2: <Check />,
    3: <Check />,
    4: <Check />,
    5: <Check />,
    6: <ReplayIcon />,
    7: <Check />,
  };

  return <ColorlibStepIconRoot isRed={isRed}>{icons[String(icon)]}</ColorlibStepIconRoot>;
}

ColorlibStepIcon.propTypes = {
  icon: PropTypes.node,
  isRed: PropTypes.bool,
};

const getActiveStep = (steps, sub_status) => {
  if (!sub_status) return -1;
  const stepIndex = steps.indexOf(sub_status);
  return stepIndex !== -1 ? stepIndex : -1;
};

const VendorStep = ({ orderProducts }) => {
  const [activeStep, setActiveStep] = useState(-1);
  const [steps, setSteps] = useState(defaultSteps);

  useEffect(() => {
    if (orderProducts) {
      const currentStatus = orderProducts.sub_status;
      const updatedSteps = getSteps(currentStatus);
      setSteps(updatedSteps);
      const stepIndex = getActiveStep(updatedSteps, currentStatus);
      setActiveStep(stepIndex);
    } else {
      setSteps(defaultSteps);
      setActiveStep(-1);
    }
  }, [orderProducts]);

  return (
    <Box sx={{ width: '100%', mt: 5 }}>
      <CustomStepper
        activeStep={activeStep}
        alternativeLabel
        connector={
          <CustomStepConnector
            // redBg={steps.some((step) => ['Return Requested', 'Return Partial', 'Return Accepted', 'Return Rejected'].includes(step))}
            redBg={steps.some((step) => ['Return Requested', 'Return Partial', 'Return Accepted', 'Return Rejected'].includes(step))}
          />
        }
      >
        {steps.map((label, index) => (
          <Step key={label} completed={index < activeStep}>
            <CustomStepLabel
              active={index <= activeStep}
              redBg={['Return Requested', 'Return Partial', 'Return Accepted', 'Return Rejected'].includes(label)}
              StepIconComponent={
                orderProducts && label === orderProducts.sub_status
                  ? (props) => (
                      <ColorlibStepIcon
                        {...props}
                        isRed={['Return Requested', 'Return Partial', 'Return Accepted', 'Return Rejected'].includes(label)}
                      />
                    )
                  : null
              }
            >
              {label}
            </CustomStepLabel>
          </Step>
        ))}
      </CustomStepper>
    </Box>
  );
};

VendorStep.propTypes = {
  orderProducts: PropTypes.object,
};

export default VendorStep;
