import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../../theme/colors/Color';

const Heading16Bold = (props) => {
  return (
    <Typography
      fontSize={'16px'}
      fontFamily={'poppins-regular'}
      lineHeight={'24px'}
      color={props.isDisable ? Colors.placeholder : Colors.text_413e3e}
      fontWeight={'bold'}
    >
      {props.text}
    </Typography>
  );
};

Heading16Bold.propTypes = {
  text: PropTypes.string.isRequired,
  isDisable: PropTypes.bool,
};
export default Heading16Bold;
