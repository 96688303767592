import React, { useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Alert,
  Button,
  MenuItem,
  Menu,
} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { Heading26Bold } from '../../../../common-components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { RecordsFailed, RecordsSuccess } from '../../../../theme/Images';

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function NavTabs() {
  const [value, setValue] = useState(0);
  const [successfulUploads] = useState([
    {
      grade: 'As cast',
      size: 12345,
      millName: 'Hot rolled bars',
      warehouseLocation: 'Yes..........',
      dateTime: '30-06-2023 02:04:00',
    },
  ]);
  const [failedUploads] = useState([
    {
      grade: 'As cast',
      size: 12345,
      millName: 'Hot rolled bars',
      warehouseLocation: 'Yes..........',
      dateTime: '30-06-2023 02:04:00',
      reasons: ['improper data', 'add more details', 'improper data', 'duplicate record'],
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTable = (uploads) => (
    <TableContainer>
      <Table sx={{ minWidth: 650, marginTop: '30px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Sr. No.</TableCell>
            <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Record</TableCell>
            <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Date & Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uploads.map((upload, index) => (
            <TableRow key={index}>
              <TableCell sx={{ border: '1px solid black', width: '10%', fontWeight: 'bold' }}>{index + 1}.</TableCell>
              <TableCell sx={{ border: '1px solid black', width: '70%' }}>
                <Box>
                  <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>Grade:</span> {upload.grade}
                  </Typography>
                  <Typography variant="body3">
                    <span style={{ fontWeight: 'bold' }}>Size:</span> {upload.size}
                  </Typography>
                  <Typography variant="body2">
                    <span style={{ fontWeight: 'bold' }}>Mill Name:</span> {upload.millName}
                  </Typography>
                  <Typography variant="body4">
                    <span style={{ fontWeight: 'bold' }}>Warehouse location:</span> {upload.warehouseLocation}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ border: '1px solid black', width: '20%' }}>{upload.dateTime}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderTable1 = (uploads) => (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 650, marginTop: '30px' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Sr. No.</TableCell>
              <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Record</TableCell>
              <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Reason for failure</TableCell>
              <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Date & Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploads.map((upload, index) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid black', width: '10%', fontWeight: 'bold' }}>{index + 1}.</TableCell>
                <TableCell sx={{ border: '1px solid black', width: '55%' }}>
                  <Box>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>Grade:</span> {upload.grade}
                    </Typography>
                    <Typography variant="body3">
                      <span style={{ fontWeight: 'bold' }}>Size:</span> {upload.size}
                    </Typography>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 'bold' }}>Mill Name:</span> {upload.millName}
                    </Typography>
                    <Typography variant="body4">
                      <span style={{ fontWeight: 'bold' }}>Warehouse location:</span> {upload.warehouseLocation}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ border: '1px solid black', width: '20%' }}>
                  <ul>
                    {upload.reasons.map((reason, reasonIndex) => (
                      <li key={reasonIndex}>{reason}</li>
                    ))}
                  </ul>
                </TableCell>
                <TableCell sx={{ border: '1px solid black', width: '20%' }}>{upload.dateTime}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            backgroundColor: '#0458AB',
            color: 'white',
            borderRadius: '50px',
            '&:hover': {
              backgroundColor: '#041841',
            },
            display: 'flex',
            alignItems: 'right',
          }}
        >
          Download
          <ArrowDropDownIcon sx={{ marginLeft: '8px', fontSize: '30px' }} />
        </Button>
      </Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}>Option 1</MenuItem>
        <MenuItem onClick={handleClose}>Option 2</MenuItem>
        <MenuItem onClick={handleClose}>Option 3</MenuItem>
      </Menu>
    </Box>
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Alert
        sx={{ fontSize: '20px', fontWeight: 'bold' }}
        iconMapping={{
          success: <CheckCircleOutlineIcon fontSize="large" />,
        }}
      >
        Products Imported Successfully!
      </Alert>
      <br />
      <FormLabel>
        <Heading26Bold text={'Imported records'} />
      </FormLabel>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="nav tabs example"
        role="navigation"
        sx={{
          marginTop: '20px',
          borderBottom: 'none',
          '& .MuiTab-root': {
            backgroundColor: 'white',
            borderRadius: '20px',
            color: 'black',
            mx: 1,
            border: 'none',
            '&:focus': {
              outline: 'none',
            },
          },
          '& .Mui-selected': {
            backgroundColor: '#05205E',
            border: 'none',
            outline: 'none',
            color: 'white',
            '&:focus': {
              outline: 'none',
            },
          },
          '& .MuiTabs-indicator': {
            height: '0px',
            backgroundColor: 'transparent',
            color: 'white',
          },
        }}
      >
        <LinkTab label="Successful" href="" />
        <LinkTab label="Failed" href="" />
      </Tabs>
      {value === 0 &&
        (successfulUploads.length > 0 ? (
          renderTable(successfulUploads)
        ) : (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Box
              component="img"
              src={RecordsSuccess}
              alt="Image not Found"
              sx={{
                maxWidth: '100%',
                width: 'auto',
                height: 'auto',
              }}
            />
          </Box>
        ))}
      {value === 1 &&
        (failedUploads.length > 0 ? (
          renderTable1(failedUploads)
        ) : (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Box
              component="img"
              src={RecordsFailed}
              alt="Image not Found"
              sx={{
                maxWidth: '100%',
                width: 'auto',
                height: 'auto',
              }}
            />
          </Box>
        ))}
    </Box>
  );
}
