import React, { useState } from 'react';
import { BosSmallLogo, LoginPageImage } from '../../../theme/Images';
import { Box, Button, Grid, IconButton, InputAdornment, Link, TextField, Typography, styled } from '@mui/material';
import Colors from '../../../theme/colors/Color';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { postData } from '../../../services/APIService';

const CustomTextFeild = styled(TextField)(() => ({
  fontFamily: 'poppins-regular',
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      fontFamily: 'poppins-regular',
    },
    '& fieldset': {
      fontFamily: 'poppins-regular',
    },
    '&.Mui-focused fieldset': {
      fontFamily: 'poppins-regular',
    },
  },
  '& .MuiFormHelperText-root': {
    color: Colors.error,
    fontFamily: 'poppins-regular',
    marginLeft: '-0.5px',
  },
  '& input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 0px white  inset',
    transition: 'background-color 5000s ease-in-out 0s',
    fontFamily: 'poppins-regular',
  },
}));
const Signin = () => {
  const [isForgotPass, setIsForgotPass] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: '',
    password: '',
    type: 'EMPLOYEE',
  });

  const navigate = useNavigate();

  const onTextChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!isForgotPass) {
      try {
        const user = await postData('/auth/loginWeb', userDetails);

        if (user.data.status) {
          const data = JSON.stringify(user.data.data);
          localStorage.setItem('userDetails', data);
          setErrorMsg('');
          navigate('/dashboard');
        }
      } catch (error) {
        if (error?.response?.data.message) {
          setErrorMsg(error.response.data.message);
        } else setErrorMsg(error.message);
        console.error(error);
      }
    }
  };
  return (
    <Box width={'80%'} margin={'0px auto'} padding={'15px'}>
      <img src={BosSmallLogo} alt="Logo" style={{ height: '85px' }} />
      <Grid container sx={{ height: '80vh', border: '1px solid', borderColor: Colors.text_black, borderRadius: 5 }}>
        <Grid item md={7} sx={{ border: 'none' }} display={{ xs: 'none', md: 'block' }}>
          <img src={LoginPageImage} alt="Image" style={{ width: '100%', height: '100%' }} />
        </Grid>
        <Grid item xs={12} md={5} sx={{ padding: '40px 24px', textAlign: 'center' }}>
          <Typography sx={{ fontSize: '26px', fontFamily: 'poppins-regular' }}>{isForgotPass ? 'Forgot Password' : 'Sign In'}</Typography>
          <Typography sx={{ fontSize: '22px', fontFamily: 'poppins-regular' }}>
            Welcome to <span style={{ color: Colors.primary }}>Blue Ocean Steels</span>
          </Typography>
          {isForgotPass ? (
            <Box marginTop={'10vh'}>
              <Box height={'75px'}>
                <CustomTextFeild fullWidth id="standard-basic" label="Enter Password" variant="standard" />
              </Box>
              <Box height={'75px'}>
                <CustomTextFeild fullWidth id="standard-basic" label="Confirm Password" variant="standard" />
              </Box>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Typography sx={{ right: 0 }}>
                  <Link sx={{ cursor: 'pointer' }} onClick={() => setIsForgotPass(false)}>
                    Sign In
                  </Link>
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box marginTop={'10vh'}>
              <Box height={'75px'}>
                <CustomTextFeild
                  fullWidth
                  id="standard-basic"
                  label="Email Id"
                  variant="standard"
                  onChange={onTextChange}
                  name="email"
                  // helperText={errorMsg}
                  value={userDetails.email}
                  autoComplete="false"
                />
              </Box>
              <Box height={'75px'}>
                <CustomTextFeild
                  fullWidth
                  // id="standard-basic"
                  label="Password"
                  variant="standard"
                  type={showPassword ? 'text' : 'password'}
                  helperText={errorMsg}
                  name="password"
                  value={userDetails.password}
                  onChange={onTextChange}
                  autoComplete="false"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword((show) => !show)}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box display={'flex'} justifyContent={'flex-end'}>
                {/* <Typography sx={{ right: 0 }}>
                  <Link sx={{ cursor: 'pointer' }} onClick={() => setIsForgotPass(true)}>
                    Forgot password
                  </Link>
                </Typography> */}
              </Box>
            </Box>
          )}
          <Button
            variant="contained"
            sx={{
              background: Colors.primary,
              height: '40px',
              borderRadius: '22.5px',
              width: '25vw',
              fontSize: { xs: '1rem', lgm: '1.375rem' },
              marginTop: { xs: '15vh', lg: '10vh' },
              textTransform: 'none',
            }}
            onClick={submit}
            disabled={userDetails.email && userDetails.password ? false : true}
          >
            {isForgotPass ? 'Reset password' : 'Sign in'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signin;
