import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getData } from '../../../../services/APIService';
const headerCellStyle = {
  border: '1px solid #ddd',
  color: '#fff',
  backgroundColor: '#0093D3',
  fontFamily: 'poppins-regular',
};
const headerCellStyle2 = {
  border: '1px solid #ddd',
  fontFamily: 'poppins-regular',
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return `${formattedDate}, ${formattedTime}`;
};

const FinacialDetails = () => {
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [finacialList, setFinacialList] = useState([]);
  const fetchFinancials = async () => {
    await getData(`/tally/tally_financials`, {}, userData.token)
      .then((res) => {
        if (res.data.data) {
          setFinacialList(res.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchFinancials();
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, border: '1px solid #ddd' }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#0093D3' }}>
              <TableCell sx={headerCellStyle}>Customer code</TableCell>
              <TableCell sx={headerCellStyle}>Customer GST no.</TableCell>
              <TableCell sx={headerCellStyle}>Customer Name</TableCell>
              <TableCell sx={headerCellStyle}>Credit limit</TableCell>
              <TableCell sx={headerCellStyle}>Credit terms</TableCell>
              <TableCell sx={headerCellStyle}>Transaction date</TableCell>
              <TableCell sx={headerCellStyle}>Total amount deposit/credit on that date</TableCell>
              <TableCell sx={headerCellStyle}>Total sales on that date</TableCell>
              <TableCell sx={headerCellStyle}>Total amount returned/debit on that date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finacialList.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={headerCellStyle2} component="th" scope="row">
                  {row.customer_code}
                </TableCell>
                <TableCell sx={headerCellStyle2}>{row.cust_gstno}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.cust_name}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.credit_limit}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.credit_terms}</TableCell>
                <TableCell sx={headerCellStyle2}>{formatDate(row.transaction_date)}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.deposit}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.sales}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.total_returns}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FinacialDetails;
