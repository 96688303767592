import { Box, Button, Grid, Paper, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Edit, Delete } from '../../../../theme/Images';
import Colors from '../../../../theme/colors/Color';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch } from 'react-redux';
import { postData, updateData } from '../../../../services/APIService';
import {
  Combobox,
  CustomDataGrid,
  CustomRadioBtn,
  FilledButton,
  Heading16Reg,
  Heading20Semi,
  Heading24Semi,
  InputTextField,
  OutlineButton,
} from '../../../../utils/component';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
// import { useNavigate } from 'react-router-dom';
import { getStatusBgColor } from '../../../../utils/constants/statusColor';

const limit = 10;
let totalPages = 1;

const CustomePaper = styled(Paper)(() => ({
  width: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2px',
  cursor: 'pointer',
}));

const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65vw',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 40,
  p: 4,
};

const DeleteModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 40,
  p: 4,
};

const FieldBox = styled(Box)(() => ({
  // height: '11.5vh',
  height: '10vh',
  marginBottom: '2.5px',
  // border: '1px solid blue',
}));

const selectList = [
  { value: 'View Rights', label: 'View Rights' },
  { value: 'Transact Rights', label: 'Transact Rights' },
  { value: 'Administrative rights', label: 'Administrative rights' },
];

const LoginDetails = () => {
  const firmData = JSON.parse(localStorage.getItem('firmData'));
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [open, setOpen] = React.useState(false);
  const [supplierEmp, setSupplierEmp] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [showSubBtn, setShowSubBtn] = useState(true);
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const initForm = {
    firm_id: firmData?.firmId,
    gst_no: firmData?.gstNo,
    type: firmData?.type,
    user_id: '',
    name: '',
    email: '',
    password: '',
    role: '',
    mobile_number: '',
    status: 'Inactive',
    rights: 'View Rights',
  };
  const [formData, setFormData] = useState(initForm);

  useEffect(() => {
    setViewMode(firmData?.mode == 'view');
    if (firmData) {
      fetchSupplierEmp(firmData?.firmId);
      setFormData((prev) => ({ ...prev, firm_id: firmData?.firmId, gst_no: firmData.gstNo, type: firmData.type }));
    }
  }, [page]);
  useEffect(() => {
    if (formData.email && formData.password) {
      setShowSubBtn(false);
    }
  }, [formData]);

  const fetchSupplierEmp = async (id) => {
    try {
      const res = await postData('users/getAllByType', { page, limit, filters: {}, firm_id: id, type: firmData.type }, {}, userData.token);
      if (res && res.data && res.data.status) {
        totalPages = res.data.data.totalPages;
        setSupplierEmp(res.data.data.users);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };

  const selectedvalue = (e) => {
    setFormData((prev) => ({ ...prev, rights: e.value }));
  };
  const handleTextChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAddUser = () => {
    setFormData(initForm);
    setOpen(true);
  };

  const validateForm = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.name) return 'Supplier Name is required';
    if (!formData.email) return 'Email is required';
    if (!emailPattern.test(formData.email)) return 'Please enter valid Email';
    if (!formData.mobile_number) return 'Mobile Number is required';
    if (!/^\d{10}$/.test(formData.mobile_number)) return 'Mobile Number is invalid';
    if (!formData.password) return 'Password is required....!';
    return '';
  };

  const handleCancel = () => {
    setFormData(initForm);
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      dispatch(showSnackbar({ message: validationError, severity: 'error' }));
      return;
    }
    try {
      formData.firm_id = firmData?.firmId;
      const res = await postData('/auth/register/clientEmp', formData, {}, userData.token);
      if (res && res.data && res.data.status) {
        fetchSupplierEmp(firmData?.firmId);
        setFormData(initForm);
        setOpen(false);
        setShowSubBtn(true);
        dispatch(showSnackbar({ message: 'Employee Details added successfully', severity: 'success' }));
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      dispatch(showSnackbar({ message: validationError, severity: 'error' }));
      return;
    }
    try {
      const res = await updateData(`/users/edit/${formData.user_id}`, formData, {}, userData.token);
      if (res && res.data && res.data.status) {
        fetchSupplierEmp(firmData.firmId);
        setFormData(initForm);
        dispatch(showSnackbar({ message: 'Employee Updated successfully', severity: 'success' }));
        setOpen(false);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };

  const handleDeleteUsers = async () => {
    try {
      const res = await postData('/users/bulkDelete', { userIds: selectedUsers }, {}, userData.token);
      if (res && res && res.data.status) {
        setDeleteWarning(false);
        fetchSupplierEmp(firmData.firmId);
        dispatch(showSnackbar({ message: 'Employee deleted successfully', severity: 'success' }));
        setOpen(false);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
    setSelectedUsers([]);
  };

  const columnsData = [
    {
      field: 'name',
      headerName: 'Name of user',
      flex: 1.2,
    },
    { field: 'rights', headerName: 'Role', flex: 1.2 },
    {
      field: 'mobile_number',
      headerName: 'Mobile No.',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'password',
      headerName: 'Password',
      flex: 1,
      valueGetter: (params) => (params ? '********' : ' '),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.9,
      renderCell: (params) => {
        const status = params.value;
        return (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                background: getStatusBgColor(status),
                height: '25px',
                width: '80px',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: '1px 20px',
              }}
            >
              {status}
            </Box>
          </Box>
        );
      },
    },
    {
      // field: 'actions',
      headerName: 'Edit',
      width: 70,
      renderCell: (params) => {
        const handleEdit = () => {
          if (viewMode) {
            dispatch(showSnackbar({ message: 'View Only', severity: 'warning' }));
          } else {
            setFormData(params.row);
            setOpen(true);
          }
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
    },
  ];

  return (
    <Box>
      <Box sx={{ display: 'flex', height: '50px', justifyContent: 'space-between', alignItems: 'center' }}>
        <Heading24Semi text={'Add Users'} />
        {viewMode ? null : (
          <Box display={'flex'} width={'8vw'} justifyContent={'space-between'}>
            <CustomePaper elevation={3} onClick={handleAddUser}>
              <AddRoundedIcon sx={{ height: '35px' }} />
              <Typography sx={{ fontSize: '10px', color: Colors.text_black, fontFamily: 'poppins-regular', marginTop: '-4px' }}>
                ADD
              </Typography>
            </CustomePaper>
            <CustomePaper elevation={3} onClick={() => setDeleteWarning(true)}>
              <img src={Delete} style={{ height: '18px', marginTop: '-4px' }} alt="delete" />
              <Typography sx={{ fontSize: '10px', color: Colors.text_black, fontFamily: 'poppins-regular', marginBottom: '-16px' }}>
                Delete
              </Typography>
            </CustomePaper>
          </Box>
        )}
      </Box>

      <Modal open={open} onClose={handleCancel} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={Modalstyle}>
          <CloseRoundedIcon sx={{ float: 'right' }} onClick={() => setOpen(false)} />
          <Box width={'100%'}>
            <Heading20Semi text={'Add Users'} />
            <Grid container marginTop={'35px'}>
              <Grid item xs={12} lgm={6} paddingRight={{ xs: 0, md: '15px' }}>
                <FieldBox>
                  <InputTextField
                    label={'Supplier Name'}
                    name={'name'}
                    value={formData.name}
                    placeholder={'Enter Name'}
                    isRequire={false}
                    isDisable={false}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Email Address'}
                    name={'email'}
                    value={formData.email}
                    placeholder={'Enter Email'}
                    isRequire={false}
                    isDisable={false}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Mobile No.'}
                    name={'mobile_number'}
                    value={formData.mobile_number}
                    type={'number'}
                    placeholder={'+919874563210'}
                    isRequire={false}
                    isDisable={false}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <FormControl>
                    <FormLabel>
                      <Typography sx={{ fontSize: '16px', fontFamily: 'poppins-regular', color: Colors.text_413e3e, marginTop: '-5px' }}>
                        Status
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formData.status}
                      onChange={(e) => setFormData((prev) => ({ ...prev, status: e.target.value }))}
                    >
                      <FormControlLabel
                        value="Active"
                        control={<CustomRadioBtn />}
                        label="Active"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontFamily: 'poppins-regular',
                          },
                        }}
                      />
                      <FormControlLabel
                        value="Inactive"
                        control={<CustomRadioBtn />}
                        label="Inactive"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontFamily: 'poppins-regular',
                          },
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                </FieldBox>
              </Grid>
              <Grid item xs={12} lgm={6} paddingLeft={{ xs: 0, md: '15px' }}>
                <FieldBox>
                  <Combobox
                    label={'Rights (Role)'}
                    optionsLabel={'label'}
                    options={selectList}
                    // isDisable={true}
                    isRequire={false}
                    value={formData.rights}
                    onChangeValue={selectedvalue}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Password'}
                    name={'password'}
                    value={formData.password?.length > 15 ? '********' : formData.password}
                    type={'password'}
                    placeholder={'********'}
                    isRequire={false}
                    isDisable={false}
                    onChange={handleTextChange}
                  />
                </FieldBox>
              </Grid>
            </Grid>
            <Box
              width={'22.8vw'}
              sx={{
                margin: '0px auto',
                marginTop: '20px',
                marginBottom: '10px',
                display: 'flex',
                gap: '25px',
              }}
            >
              <OutlineButton text={'Cancel'} onClick={handleCancel} />
              {formData.user_id ? (
                <FilledButton text={'Update'} onClick={handleUpdate} />
              ) : (
                <FilledButton text={'Submit'} onClick={handleSubmit} isdisable={showSubBtn} />
              )}
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteModalstyle}>
          {selectedUsers?.length ? (
            <>
              <Heading16Reg text="Do you want to Delete Users Permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDeleteUsers}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select User first." />
          )}
        </Box>
      </Modal>

      <CustomDataGrid rowIdKey={'user_id'} columnsData={columnsData} rowsData={supplierEmp} onSelectCheckbox={(r) => setSelectedUsers(r)} />
      <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
    </Box>
  );
};

export default LoginDetails;
