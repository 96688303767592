import { TextField, styled } from '@mui/material';
import React from 'react';
import Colors from '../../theme/colors/Color';
import PropTypes from 'prop-types';

//add custom style
const CustomTextField = styled(TextField)(() => ({
  borderRadius: '5px',
  '& .MuiOutlinedInput-root': {
    height: '45px',
    fontFamily: 'poppins-regular',
    padding: '0px',
    '& .MuiOutlinedInput-input': {
      padding: '5px 14px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid',
      borderColor: Colors.text_413e3e,
    },
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'poppins-regular',
    top: '-6px',
    '&.Mui-focused': {
      color: Colors.text_413e3e,
    },
  },
  '& .MuiFormHelperText-root': {
    color: Colors.error,
    fontFamily: 'poppins-regular',
    marginLeft: '-0.1px',
    marginTop: '-2px',
  },
  '& input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 0px white  inset',
    transition: 'background-color 5000s ease-in-out 0s',
  },
  '& .MuiFormLabel-asterisk': {
    color: Colors.error, // Change asterisk color here
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
}));

const FloatingLabelBox = (props) => {
  let type;
  type = props.type;
  const handleChange = (e) => {
    if (props.onChange) props.onChange(e);
  };
  return (
    <CustomTextField
      label={props.label}
      fullWidth
      helperText={props.helperText}
      placeholder={props.placeholder}
      required={props.isRequire}
      type={type}
      name={props.name}
      value={props.value || ''}
      onChange={handleChange}
      disabled={props.isDisable}
      sx={{ background: props.bgColor ? props.bgColor : Colors.transparent }}
    />
  );
};

FloatingLabelBox.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  isDisable: PropTypes.bool,
  isRequire: PropTypes.bool,
  onChange: PropTypes.func,
  onIconClick: PropTypes.func,
  bgColor: PropTypes.string,
};

export default FloatingLabelBox;
