import { Box, Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Heading24Semi } from '../../../../common-components';
import { getStatusBgColor } from '../../../../utils/constants/statusColor';
import { CustomDataGrid, Heading16Reg } from '../../../../utils/component';
import { Delete, Edit } from '../../../../theme/Images';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { postData } from '../../../../services/APIService';
import { useNavigate } from 'react-router-dom';
import Colors from '../../../../theme/colors/Color';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
let totalPages = 1;

const DeleteModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 4,
};

const EnquiryDetails = () => {
  const [page, setCurrentPage] = useState(1);
  const [enquiryData, setEnquiryData] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const firmData = JSON.parse(localStorage.getItem('firmData'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let firm_id = firmData?.firmId;
  useEffect(() => {
    fetchEnquiries();
  }, [page]);

  const fetchEnquiries = async () => {
    try {
      const res = await postData(`/enquiry/getall`, { page, filters: { firm_id: [firm_id] } }, {}, userData.token);
      if (res && res.data && res.data.status) {
        totalPages = res.data.data.totalPages;
        setEnquiryData(res.data.data.enquiries);
      }
    } catch (error) {
      console.log('error fetchin enquiries', error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await postData('/enquiry/bulk/delete', { enquiryIds: selectedRows }, {}, userData.token);
      if (res && res && res.data.status) {
        setDeleteWarning(false);
        fetchEnquiries();
        dispatch(showSnackbar({ message: 'Enquiry deleted successfully', severity: 'success' }));
        // setModalOpen(false);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
    setSelectedRows([]);
  };

  const highlightCondition = (params) => {
    const dayPassed = new Date() - new Date(params.row.createdAt) > 24 * 60 * 60 * 1000;
    return dayPassed && params.row.status !== 'Close';
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const columnsData = [
    {
      field: 'enquiry_number',
      headerName: 'Enquiry no',
      flex: 0.5,
      renderCell: (params) => {
        const { enquiry_id } = params.row;
        const Code = params.value;
        const viewDetails = () => {
          navigate(`/enquiry/edit/${enquiry_id}`, { state: { eqType: params.row.enquiry_type, isCallFrom: 'view' } });
        };
        return (
          <Box
            sx={{
              color: Colors.primary,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={viewDetails}
          >
            {Code}
          </Box>
        );
      },
    },

    { field: 'createdAt', headerName: 'Date of entry', flex: 0.5, valueGetter: (p) => new Date(p).toLocaleDateString() },
    {
      field: 'enquiry_type',
      headerName: 'Type of enquiry',
      flex: 0.5,
    },
    { field: 'nature_of_enquiry', headerName: 'Nature of enquiry', flex: 0.5 },
    // {
    //   field: 'firm',
    //   headerName: 'KAM',
    //   flex: 0.5,
    //   valueGetter: () => 'kam user',
    // },
    {
      field: 'productCategory',
      headerName: 'Product category',
      flex: 0.5,
      valueGetter: (params) => params?.category_name,
    },
    {
      field: 'grade',
      headerName: 'Grade',
      flex: 0.5,
      valueGetter: (params) => params?.grade_name,
      renderCell: (params) => {
        return <Box sx={{ color: params.row.grade?.is_external ? Colors.error : 'inherit' }}>{params.value}</Box>;
      },
    },
    {
      field: 'size',
      headerName: 'Size',
      flex: 0.5,
      valueGetter: (params) => params?.size_name,
      renderCell: (params) => {
        return <Box sx={{ color: params.row.size?.is_external ? Colors.error : 'inherit' }}>{params.value}</Box>;
      },
    },
    {
      field: 'processRoute',
      headerName: 'Process route',
      flex: 0.5,
      valueGetter: (params) => params?.pr_name,
      renderCell: (params) => {
        return <Box sx={{ color: params.row.processRoute?.is_external ? Colors.error : 'inherit' }}>{params.value}</Box>;
      },
    },
    { field: 'quantity', headerName: 'Quantity(MT)', flex: 0.5 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => {
        const status = params.value;
        return (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                background: getStatusBgColor(status),
                height: '25px',
                width: '80px',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: '1px 20px',
              }}
            >
              {status}
            </Box>
          </Box>
        );
      },
    },
    {
      headerName: 'Edit',
      width: 70,
      renderCell: (params) => {
        const { enquiry_id } = params.row;
        const handleEdit = () => {
          navigate(`/enquiry/edit/${enquiry_id}`, { state: { eqType: params.row.enquiry_type, isCallFrom: 'customer' } });
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
      filterable: false,
    },
  ];
  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Heading24Semi text={'Enquiry Details'} />
        <img onClick={() => setDeleteWarning(true)} src={Delete} alt="delete" style={{ height: '25px', width: '25px' }} />
      </Box>
      <CustomDataGrid
        rowIdKey={'enquiry_id'}
        columnsData={columnsData}
        rowsData={enquiryData}
        onSelectCheckbox={(r) => setSelectedRows(r)}
        getHighlightCondition={highlightCondition}
      />
      <Pagination currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteModalstyle}>
          {selectedRows?.length ? (
            <>
              <Heading16Reg text="Do you want to Delete Users Permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDelete}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select Enquiry first." />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default EnquiryDetails;
