// This is data used to show in dropdowns list in forms
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData } from '../../../services/APIService';

export const fetchPM_Masters = createAsyncThunk('PM_Masters/fetchPM_Masters', async () => {
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [productCategoriesRes, gradesRes, shapesRes, sizesRes, heatTreatsRes, processRoutesRes, surfaceConditionsRes, millsRes] =
    await Promise.all([
      await getData('mstProductCategories/getAllmstProductCategoriesv1', {}, userData.token),
      await getData('/mstGrade/getAllmstGradesv1', {}, userData.token),
      await getData('/mstShape/getAllmstShapesv1', {}, userData.token),
      await getData('/mstSizes/getAllmstSizesv1', {}, userData.token),
      await getData('/mstHeatTreat/getAllmstHeatTreatv1', {}, userData.token),
      await getData('/mstProcessRoute/getAllmstProcessRoutev1', {}, userData.token),
      await getData('/mstSurfaceConditions/getAllmstSurfaceConditionsv1', {}, userData.token),
      await getData('/mstMills/getAllmstMillsv1', {}, userData.token),
    ]);
  return {
    productCategories: productCategoriesRes.data.data,
    grades: gradesRes.data.data,
    shapes: shapesRes.data.data,
    sizes: sizesRes.data.data,
    heatTreats: heatTreatsRes.data.data,
    processRoutes: processRoutesRes.data.data,
    surfaceConditions: surfaceConditionsRes.data.data,
    mills: millsRes.data.data,
  };
});

const initialState = {
  productCategories: [],
  grades: [],
  shapes: [],
  sizes: [],
  heatTreats: [],
  processRoutes: [],
  surfaceConditions: [],
  mills: [],
};

export const PM_MastersSlice = createSlice({
  name: 'PM_Masters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPM_Masters.fulfilled, (state, action) => {
      state.productCategories = action.payload.productCategories;
      state.grades = action.payload.grades;
      state.shapes = action.payload.shapes;
      state.sizes = action.payload.sizes;
      state.heatTreats = action.payload.heatTreats;
      state.processRoutes = action.payload.processRoutes;
      state.surfaceConditions = action.payload.surfaceConditions;
      state.mills = action.payload.mills;
    });
  },
});

export default PM_MastersSlice.reducer;
