import { Alert, Slide, Snackbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbar } from '../../../redux/features/snackbar/snackbarSlice';
// import Colors from '../../../theme/colors/Color';

const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
};

const CustomSnackbar = () => {
  const dispatch = useDispatch();
  const { open, message, severity } = useSelector((state) => state.snackbar);
  // const getColor = (status) => {
  //   switch (status) {
  //     case 'success':
  //       return Colors.success;
  //     case 'error':
  //       return Colors.error;
  //     case 'warning':
  //       return Colors.warning;
  //     default:
  //       return Colors.transparent;
  //   }
  // };
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={SlideTransition}
        sx={{ marginTop: '30px' }}
        onClose={() => dispatch(hideSnackbar())}
      >
        {/* <Alert severity={severity} sx={{ width: '100%', border: '1px solid', borderColor: getColor(severity) }}> */}
        <Alert severity={severity} variant="filled" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomSnackbar;
