import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getData } from '../../../../services/APIService';

const headerCellStyle = {
  border: '1px solid #ddd',
  color: '#fff',
  backgroundColor: '#0093D3',
  fontFamily: 'poppins-regular',
};
const headerCellStyle1 = {
  border: '1px solid #ddd',
  color: 'red',
  fontFamily: 'poppins-regular',
};
const headerCellStyle2 = {
  border: '1px solid #ddd',
  fontFamily: 'poppins-regular',
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return `${formattedDate}, ${formattedTime}`;
};

export default function FinacialDetailsTally() {
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [finacialTallyList, setTallyFinacialList] = useState([]);
  console.log('finacialTallyList', finacialTallyList);
  const fetchTallyFinancials = async () => {
    await getData(`/tally/financials`, {}, userData.token)
      .then((res) => {
        if (res.data.data) {
          setTallyFinacialList(res.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchTallyFinancials();
  }, []);
  return (
    <>
      {finacialTallyList?.map((cu) => (
        <TableContainer key={cu.customer_code} component={Paper} sx={{ mb: 2 }}>
          <Table sx={{ minWidth: 650, border: '1px solid #ddd' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={!cu.customer_id ? headerCellStyle1 : null} colSpan={4}>
                  Customer code : {cu.customer_code}
                </TableCell>
                <TableCell sx={headerCellStyle1} colSpan={8}></TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }}>
                <TableCell sx={headerCellStyle}>Date+time</TableCell>
                <TableCell sx={headerCellStyle}>Credit terms</TableCell>
                <TableCell sx={headerCellStyle}>Credit limit</TableCell>
                <TableCell sx={headerCellStyle}>Add-on</TableCell>
                <TableCell sx={headerCellStyle}>Balance credit limit</TableCell>
                <TableCell sx={headerCellStyle}>Deposit (receipt vchr)</TableCell>
                <TableCell sx={headerCellStyle}>Return (credit note sales vchr)</TableCell>
                <TableCell sx={headerCellStyle}>Available credit limit=Bal + deposit + return</TableCell>
                <TableCell sx={headerCellStyle}>Order no</TableCell>
                <TableCell sx={headerCellStyle}>Order Amount (grand total)</TableCell>
                <TableCell sx={headerCellStyle}>Payment Terms</TableCell>
                <TableCell sx={headerCellStyle}>Closing credit limit</TableCell>
                <TableCell sx={headerCellStyle}>Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cu.data?.map((row) => (
                <TableRow key={row.id} sx={headerCellStyle2}>
                  <TableCell sx={headerCellStyle2}>{formatDate(row.createdAt)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.credit_terms}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.credit_limit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.add_on_credit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.bal_credit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.deposit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.total_return}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.avl_credit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.order_no}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.order_amount}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.payment_terms}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.closing_credit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.remarks}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  );
}
