import { Box, Divider, FormControl, FormLabel } from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import {
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import Avatar from '@mui/material/Avatar';
import Colors from '../../../../theme/colors/Color';
import { Heading20Semi } from '../../../../common-components';

const CustomPaper = styled(Paper)(() => ({
  padding: '28px 9px',
}));

const ProductsComment = () => {
  const comments = [
    { id: 1, author: 'MH', text: 'Thanks A Lot For The Reply', timestamp: '14 Feb 2024 12:30 PM' },
    { id: 2, author: 'BOS', text: 'The Product Can Be Available By April', timestamp: '14 Feb 2024 12:30 PM' },
  ];

  const data = [
    { date: '01/05/2024 10:35 AM', action: 'Quantity Added', modifiedBy: '50 MT', totalQuantity: '50 MT' },
    { date: '05/05/2024 10:35 AM', action: 'Quantity Modified', modifiedBy: '- 6', totalQuantity: '44 MT' },
  ];
  return (
    <Box sx={{ marginTop: '30px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lgm={8}>
          <CustomPaper elevation={5} sx={{ borderRadius: '10px', height: { xs: '50vh', md: '55vh' } }}>
            <FormControl>
              <FormLabel>
                <Heading20Semi text={'Comments '} />
              </FormLabel>
            </FormControl>
            <Box height="auto" position="relative">
              <TextField
                rows={4}
                variant="outlined"
                placeholder="Add Comments"
                multiline
                sx={{
                  width: '100%',
                  maxHeight: '100%',
                  height: '140px',
                  overflowY: 'auto',
                  '& .MuiOutlinedInput-root': {
                    position: 'relative',
                    paddingBottom: '80px',
                    height: '100%',
                    alignItems: 'flex-start',
                    '&::-webkit-scrollbar': {
                      width: '12px',
                      cursor: 'pointer',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: Colors.secondary1,
                      borderRadius: '6px',
                      border: '3px solid transparent',
                      backgroundClip: 'padding-box',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: Colors.primary,
                    },
                  },
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'white',
                      }}
                    >
                      <Divider
                        sx={{
                          width: '96%',
                          borderBottom: '2px solid',
                          borderColor: Colors.primary,
                          mb: 0,
                        }}
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', px: 2 }}>
                        <IconButton component="span" sx={{ fontWeight: 'bold' }}>
                          <AttachFileIcon sx={{ fontWeight: 'bold', color: Colors.text_black }} />
                        </IconButton>
                        <IconButton sx={{ color: Colors.primary }}>
                          <SendIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ),
                }}
              />
            </Box>
            <Typography variant="caption" display="block" sx={{ marginBottom: 2, color: Colors.placeholder, fontSize: '13px' }}>
              Maximum attachment size for this enquiry 2.5 mb
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                maxHeight: '150px',
                '&::-webkit-scrollbar': {
                  width: '12px',
                  height: '11px',
                  cursor: 'pointer',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Colors.secondary1,
                  borderRadius: '6px',
                  border: '3px solid transparent',
                  backgroundClip: 'padding-box',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: Colors.primary,
                },
              }}
            >
              <List>
                {comments.map((comment) => (
                  <ListItem key={comment.id} alignItems="flex-start">
                    <Avatar sx={{ bgcolor: Colors.text_black, fontSize: '13px', fontWeight: 'bold' }}>
                      {comment.author.substring(0, 3)}
                    </Avatar>
                    <ListItemText
                      secondary={
                        <>
                          <Typography variant="caption" component="span" sx={{ marginLeft: 1 }}>
                            {comment.timestamp}
                          </Typography>
                        </>
                      }
                      primary={
                        <Typography variant="body2" component="span">
                          {comment.text}
                        </Typography>
                      }
                      sx={{ marginLeft: 2, fontSize: { md: '17px', lg: '17px', sm: '11px', xl: '22px' } }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </CustomPaper>
        </Grid>

        <Grid item xs={12} lgm={4}>
          <CustomPaper elevation={5} sx={{ borderRadius: '10px', height: { xs: '  50vh', md: '55vh' } }}>
            <FormControl>
              <FormLabel>
                <Heading20Semi text={'Quantity Change History'} />
              </FormLabel>
            </FormControl>
            <Divider sx={{ marginTop: '10px' }} />
            <TableContainer
              sx={{
                maxHeight: '100%',
                height: '96%',
                overflowY: 'auto',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                  width: '12px',
                  height: '12px',
                  cursor: 'pointer',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Colors.secondary1,
                  borderRadius: '6px',
                  border: '3px solid transparent',
                  backgroundClip: 'padding-box',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: Colors.primary,
                },
              }}
            >
              <Table>
                <TableHead>
                  <TableRow style={{ whiteSpace: 'nowrap' }}>
                    <TableCell>Date & Time</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Modified By</TableCell>
                    <TableCell>Total Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow key={index} style={{ whiteSpace: 'nowrap' }}>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.action}</TableCell>
                      <TableCell>{row.modifiedBy}</TableCell>
                      <TableCell>{row.totalQuantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductsComment;
