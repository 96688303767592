import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
// import FloatingLabelSelect from '../../../../common-components/select/FloatingLabelSelect'
import Colors from '../../../../theme/colors/Color';
// import SkyBlueSelect from '../../../../common-components/select/SkyBlueSelect'
// import SkyBlueInput from '../../../../common-components/inputbox/SkyBlueInput'
// import { NumList } from '../../../../utils/constants/dropdownList'
import FloatingLabelBox from '../../../../common-components/inputbox/FloatingLabelBox';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomRadio } from '../../../../common-components';
import Combobox from '../../../../utils/component/combo-box/Combobox';

// const selectList = [
//   { value: 10, label: 'Option 1' },
//   { value: 20, label: 'Option 2' },
//   { value: 30, label: 'Option 3' },
// ]

const ManageItemBox = styled(Box)(() => ({
  height: '70px',
}));

const SizeInputBox = {
  height: '30px',
  width: '100px',
  background: Colors.select_bg,
  border: '0.5px solid',
  borderColor: Colors.placeholder,
  marginTop: '4px',
};

const ProductEnquiry = ({ enquiryData }) => {
  return (
    <Box mb={'30px'}>
      <Grid container>
        <Grid item xs={12} lgm={6} paddingRight={{ xs: 0, md: '5px' }}>
          <ManageItemBox>
            <Combobox
              label={'Type Of Enquiry'}
              optionsLabel={'enquiry_type'}
              options={[]}
              isDisable={true}
              isRequire={false}
              value={enquiryData.enquiry_type}
            />
          </ManageItemBox>
          <ManageItemBox>
            <Combobox
              label={'Product Category'}
              optionsLabel={'pc_name'}
              options={[]}
              isDisable={true}
              isRequire={false}
              value={enquiryData.productCategory?.category_name}
            />
          </ManageItemBox>
        </Grid>
        <Grid item xs={12} lgm={6} paddingLeft={{ xs: 0, md: '5px' }}>
          <ManageItemBox>
            <Combobox label={'KAM'} isDisable={true} value={enquiryData.kam?.name} />
          </ManageItemBox>
          <ManageItemBox>
            <Combobox
              label={'Sub Product Category'}
              optionsLabel={'spc_name'}
              options={[]}
              isDisable={true}
              isRequire={false}
              value={enquiryData.subProductCategory?.sub_category_name}
            />
          </ManageItemBox>
        </Grid>
      </Grid>
      <Box sx={{ background: Colors.bg_form_section, marginTop: '10px', padding: '10px' }}>
        <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '20px', fontWeight: 600 }}>Product Details</Typography>
        <Grid container marginTop={'15px'}>
          <Grid item xs={12} lgm={6} paddingRight={{ xs: 0, md: '5px' }}>
            <ManageItemBox>
              <Combobox
                label={'Grade'}
                isDisable={true}
                value={enquiryData.grade?.is_external ? 'Other' : enquiryData.grade?.grade_name}
                bgColor={Colors.select_bg}
              />
            </ManageItemBox>
            <ManageItemBox>
              <Combobox
                label={'Size'}
                isDisable={true}
                value={enquiryData.size?.is_external ? 'Other' : enquiryData.size?.size_name}
                bgColor={Colors.select_bg}
              />
            </ManageItemBox>
            <ManageItemBox>
              <Combobox
                label={'Process Route'}
                isDisable={true}
                value={enquiryData.processRoute?.is_external ? 'Other' : enquiryData.processRoute?.pr_name}
                bgColor={Colors.select_bg}
              />
            </ManageItemBox>
            {/* <ManageItemBox>
              <SkyBlueSelect
                label={'Quantity'}
                placeholder={''}
                options={selectList}
                isDisable={false}
                isRequire={true}
                selected={selectedvalue}
              />
            </ManageItemBox> */}
            <ManageItemBox sx={{ display: 'flex', gap: '5px' }}>
              <Box width={'50%'}>
                {/* <SkyBlueSelect
                  label={'Quantity'}
                  placeholder={''}
                  options={[
                    { value: 10, label: 'Metric Ton' },
                    { value: 20, label: 'Number' },
                  ]}
                  isDisable={false}
                  isRequire={true}
                  // selected={}
                /> */}
                <FloatingLabelBox
                  label={'Quantity'}
                  name={'qty'}
                  value={enquiryData.number ? 'Number' : 'Metric Ton'}
                  isDisable={true}
                  bgColor={Colors.select_bg}
                />
              </Box>
              <Box width={'50%'}>
                {/* <SkyBlueSelect label={''} placeholder={''} options={NumList} isDisable={false} selected={selectedvalue} /> */}
                <FloatingLabelBox
                  label={''}
                  name={'value'}
                  value={enquiryData.number ? enquiryData.number : enquiryData.quantity}
                  isDisable={true}
                  bgColor={Colors.select_bg}
                />
              </Box>
            </ManageItemBox>
          </Grid>
          <Grid item xs={12} lgm={6} paddingLeft={{ xs: 0, md: '5px' }}>
            <ManageItemBox>
              <Combobox
                label={'Please Mention'}
                isDisable={true}
                value={enquiryData.grade?.is_external ? enquiryData.grade?.grade_name : ''}
                bgColor={Colors.select_bg}
              />
            </ManageItemBox>
            <ManageItemBox>
              <Combobox
                label={'Please Mention'}
                isDisable={true}
                value={enquiryData.size?.is_external ? enquiryData.size?.size_name : ''}
                bgColor={Colors.select_bg}
              />
            </ManageItemBox>
            <ManageItemBox>
              <Combobox
                label={'Please Mention'}
                isDisable={true}
                value={enquiryData.processRoute?.is_external ? enquiryData.processRoute?.pr_name : ''}
                bgColor={Colors.select_bg}
              />
            </ManageItemBox>
            {/* <ManageItemBox>
              <SkyBlueInput label={'Please Mention'} name={'qty'} value={''} helperText={''} placeholder={'Quantity'} isDisable={true} />
            </ManageItemBox> */}
          </Grid>
        </Grid>
        {enquiryData.number && (
          <Box>
            <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px', color: Colors.error }}>
              Converted value: {enquiryData.quantity} MT
            </Typography>
            <Accordion sx={{ background: Colors.bg_form_section, boxShadow: 'none', marginTop: '10px' }} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '14px', marginLeft: '-15px' }}>
                  For converting Numbers to metric tons please provide below details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ background: '#90d1e5', marginLeft: '-15px', padding: '12px' }}>
                  {/* <Typography>Select Shape</Typography> */}
                  <Grid container>
                    <Grid item xs={12} lgm={4}>
                      <FormControl>
                        <FormLabel>
                          <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '16px', color: Colors.text_black }}>
                            Select Shape
                          </Typography>
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          defaultValue={enquiryData.selected_shape_size.shape}
                          // onChange={handleShapeSelect}
                        >
                          <FormControlLabel
                            value="Round"
                            control={<CustomRadio />}
                            label="Round"
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontFamily: 'poppins-regular',
                              },
                            }}
                          />
                          <FormControlLabel
                            value="Square"
                            control={<CustomRadio />}
                            label="Square"
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontFamily: 'poppins-regular',
                              },
                            }}
                          />
                          <FormControlLabel
                            value="Rectangle"
                            control={<CustomRadio />}
                            label="Rectangle"
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontFamily: 'poppins-regular',
                              },
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lgm={4} display={'flex'} alignItems={'center'} gap={'20px'}>
                      {enquiryData.selected_shape_size.shape === 'Round' && (
                        <Box>
                          <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px' }}>Diameter (mm)</Typography>
                          <input style={SizeInputBox} value={enquiryData.selected_shape_size.diameter} disabled />
                        </Box>
                      )}
                      {enquiryData.selected_shape_size.shape === 'Square' && (
                        <Box>
                          <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px' }}>Side (mm)</Typography>
                          <input style={SizeInputBox} value={enquiryData.selected_shape_size.side} disabled />
                        </Box>
                      )}
                      {enquiryData.selected_shape_size.shape === 'Rectangle' && (
                        <>
                          <Box>
                            <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px' }}>Side 1 (mm)</Typography>
                            <input style={SizeInputBox} value={enquiryData.selected_shape_size.side1} disabled />
                          </Box>
                          <Box>
                            <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px' }}>Side 2 (mm)</Typography>
                            <input style={SizeInputBox} value={enquiryData.selected_shape_size.side2} disabled />
                          </Box>
                        </>
                      )}
                      <Box>
                        <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px' }}>Length (mm)</Typography>
                        <input style={SizeInputBox} value={enquiryData.selected_shape_size.length} disabled />
                      </Box>
                      {/* <Typography
                        sx={{
                          fontFamily: 'poppins-regular',
                          fontSize: '12px',
                          textDecoration: 'underline',
                          color: Colors.primary,
                          cursor: 'pointer',
                          marginLeft: '10px',
                          marginTop: '15px',
                        }}
                        onClick={calculate}
                      >
                        Calculate
                      </Typography> */}
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {/* <SkyBlueSelect
          label={'Delivery Location Of Product'}
          placeholder={''}
          options={selectList}
          isDisable={false}
          selected={selectedvalue}
        /> */}
        <Box mt={'20px'}>
          <FloatingLabelBox
            label={'Delivery Location Of Product'}
            name={'location'}
            value={`${enquiryData?.deliveryAddress?.address_line_1}, ${enquiryData?.deliveryAddress?.address_line_2}, ${enquiryData?.deliveryAddress?.city}, ${enquiryData?.deliveryAddress?.state}, ${enquiryData?.deliveryAddress?.postal_code}`}
            isDisable={true}
            bgColor={Colors.select_bg}
          />
        </Box>
      </Box>
    </Box>
  );
};

ProductEnquiry.propTypes = {
  enquiryData: PropTypes.object,
};
export default ProductEnquiry;
