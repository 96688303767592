import { Box, InputAdornment, styled, TextField, Typography } from '@mui/material';
import React from 'react';
import Colors from '../../theme/colors/Color';
import { Add, Delete, Filter, Magnifier } from '../../theme/Images';
import PropTypes from 'prop-types';

const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));

const CustomBtnBox = styled(Box)(() => ({
  height: '50px',
  width: '50px',
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  background: Colors.white,
  cursor: 'pointer',
}));

const PageHeader = (props) => {
  const filterClick = (e) => {
    props.filterClick(e);
  };
  const addClick = (e) => {
    props.addClick(e);
  };
  const deleteClick = (e) => {
    props.deleteClick(e);
  };

  const handleSearch = (e) => {
    props.handleSearch(e);
  };
  return (
    <Box
      sx={{
        marginTop: '20px',
        // height: '80px',
        height: '10.4vh',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: ' center',
        padding: '0px 30px',
        background: Colors.bg_strip,
      }}
    >
      <CustomSearch
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={Magnifier} alt="Search" style={{ width: '20px', height: '20px' }} />
            </InputAdornment>
          ),
        }}
        onChange={handleSearch}
      />
      <Box sx={{ display: 'flex', gap: '20px' }}>
        {props.showFilter && (
          <Box>
            <CustomBtnBox onClick={filterClick}>
              <img src={Filter} alt="filter" style={{ height: '20px', width: '25px' }} />
              <Typography sx={{ fontSize: '10px' }}>FILTER</Typography>
            </CustomBtnBox>
          </Box>
        )}
        {props.showAdd && (
          <CustomBtnBox onClick={addClick}>
            <img src={Add} alt="add" style={{ height: '22px', width: '22px' }} />
            <Typography sx={{ fontSize: '10px' }}>ADD</Typography>
          </CustomBtnBox>
        )}
        {props.showDeleter && (
          <CustomBtnBox onClick={deleteClick}>
            <img src={Delete} alt="delete" style={{ height: '21px', width: '20px' }} />
            <Typography sx={{ fontSize: '10px' }}>DELETE</Typography>
          </CustomBtnBox>
        )}
      </Box>
    </Box>
  );
};

PageHeader.propTypes = {
  showFilter: PropTypes.bool,
  showAdd: PropTypes.bool,
  showDeleter: PropTypes.bool,
  filterClick: PropTypes.func,
  addClick: PropTypes.func,
  deleteClick: PropTypes.func,
  handleSearch: PropTypes.func,
};

export default PageHeader;
