import React, { useState, useEffect } from 'react';
import { Box, Popover, TextField, Typography, styled, InputAdornment } from '@mui/material';
import Colors from '../../../theme/colors/Color';
import { Heading26Bold } from '../../../common-components';
import PropTypes from 'prop-types';
import { Add, Delete, Filter, Magnifier, Edit } from '../../../theme/Images';
import { useNavigate } from 'react-router-dom';
import { postData } from '../../../services/APIService';
// import TableHeader from '../../../utils/table headings/TableHeaders.json';
// import ListDataTable from '../../../common-components/table/ListDataTable';
import { CustomDataGrid } from '../../../utils/component';
import { getStatusBgColor } from '../../../utils/constants/statusColor';
import CommunityFilter from './filters/CommunityFilters';
import Pagination from '../../../utils/component/pagination/Pagination';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/features/snackbar/snackbarSlice';

const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));

const CustomBtnBox = styled(Box)(() => ({
  // border: '1px solid',
  height: '35px',
  width: '35px',
  borderRadius: '6px',
  padding: '8px 6px 5px',
  background: Colors.white,
  textAlign: 'center',
  cursor: 'pointer',
}));
const limit = 10;
let totalPages = 1;

const CommunityManagement = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  // const [filters, setFilters] = useState(initialFilters);

  const [allCommunity, setAllCommunity] = useState([]);
  console.log('all community', allCommunity);
  const navigate = useNavigate();

  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    fetchAllCommunity();
  }, [page, keyword]);
  const fetchAllCommunity = async () => {
    try {
      const res = await postData(`/community/getall`, { page, limit, keyword }, {}, userData.token);
      if (res && res.data && res.data.status) {
        totalPages = res.data.data.totalPages;
        setAllCommunity(res.data.data.communities);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      if (error?.response?.status === 404) setAllCommunity([]);
      console.log(error);
    }
  };
  const handleSearch = (e) => {
    const keyword = e.target.value.trim();
    setKeyword(keyword);
  };
  const open = Boolean(anchorEl);
  const columnsData = [
    {
      field: 'comNo',
      headerName: 'Community no',
      flex: 1,
    },
    { field: 'community_name', headerName: 'Name of community', flex: 1 },
    { field: 'createdAt', headerName: 'Date of creation', flex: 0.5, valueGetter: (p) => new Date(p).toLocaleDateString() },

    {
      field: 'realm',
      headerName: 'Realm',
      flex: 0.7,
    },
    {
      field: 'description',
      headerName: 'Description ',
      flex: 1,
    },
    {
      field: 'membersCount',
      headerName: 'No. of members',
      flex: 0.8,
    },
    {
      field: 'postsCount',
      headerName: 'No. of posts',
      flex: 0.7,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.8,
      renderCell: (params) => {
        const status = params.value;
        return (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                background: getStatusBgColor(status),
                height: '25px',
                width: '80px',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: '1px 20px',
              }}
            >
              {status}
            </Box>
          </Box>
        );
      },
    },
    {
      headerName: 'Edit',
      width: 70,
      renderCell: ({ id }) => {
        const handleEdit = () => {
          navigate(`/community/edit/${id}`, { state: { id, mode: 'edit' } });
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
    },
  ];
  return (
    <Box>
      <Heading26Bold text={'Community Management'} />
      <Box
        sx={{
          marginTop: '20px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch
          placeholder="Search Community"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Magnifier} alt="Search" style={{ width: '20px', height: '20px' }} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Box>
            <CustomBtnBox onClick={handleClickFilter}>
              <img src={Filter} alt="filter" style={{ height: '20px', width: '25px' }} />
              <Typography sx={{ fontSize: '10px' }}>Filter</Typography>
            </CustomBtnBox>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box>
                <CommunityFilter />
              </Box>
            </Popover>
          </Box>
          <CustomBtnBox onClick={() => navigate('/community/add')}>
            <img src={Add} alt="add" style={{ height: '22px', width: '22px' }} />
            <Typography sx={{ fontSize: '10px' }}>Add</Typography>
          </CustomBtnBox>
          <CustomBtnBox>
            <img src={Delete} alt="delete" style={{ height: '21px', width: '20px' }} />
            <Typography sx={{ fontSize: '10px' }}>Delete</Typography>
          </CustomBtnBox>
        </Box>
      </Box>
      <Box>
        {/* <ListDataTable list={orderListData} path={'/community/edit'} tableHead={TableHeader.Community_List} /> */}
        <CustomDataGrid rowIdKey={'community_id'} columnsData={columnsData} rowsData={allCommunity} />
        <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
      </Box>
    </Box>
  );
};
CommunityManagement.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
};
export default CommunityManagement;
