import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import RequireAuth from './components/Auth/RequireAuth';
import Adminlayout from './components/Layout/Adminlayout';
import Dashboard from './components/Dashboard/Dashboard';
import ProductManagement from './components/Screen/ProductManagement/ProductManagement';
import Reports from './components/Screen/Reports/Reports';
import AddProducts from './components/Screen/ProductManagement/AddProducts/AddProducts';
import ImportProducts from './components/Screen/ProductManagement/AddBulkProducts/ImportProducts';
import Result from './components/Screen/ProductManagement/BulkUploadResult/Result';
import { createTheme, ThemeProvider } from '@mui/material';
import AddUser from './components/Screen/UserManagement/BOS-employee/AddUser';
import ProductsRecord from './components/Screen/ProductManagement/Records/ProductsRecord';
import AddCustomer from './components/Screen/UserManagement/customer/AddCustomer';
import GeneralDetails from './components/Screen/UserManagement/customer/GeneralDetails';
import OrderDetails from './components/Screen/UserManagement/customer/OrderDetails';
import LoginDetails from './components/Screen/UserManagement/customer/LoginDetails';
import ContactDetails from './components/Screen/UserManagement/customer/ContactDetails';
import CustomerEnquiry from './components/Screen/UserManagement/customer/CustomerEnquiry';
import EditProducts from './components/Screen/ProductManagement/EditProducts/EditProducts';
import SGeneralDetails from './components/Screen/UserManagement/supplier/SGeneralDetails';
import AddSupplier from './components/Screen/UserManagement/supplier/AddSupplier';
import SProductDetails from './components/Screen/UserManagement/supplier/SProductDetails';
import SContactDetails from './components/Screen/UserManagement/supplier/SContactDetails';
import SLoginDetails from './components/Screen/UserManagement/supplier/SLoginDetails';
import SEnquiryDetails from './components/Screen/UserManagement/supplier/SEnquiryDetails';
import SCommunityDetails from './components/Screen/UserManagement/supplier/SCommunityDetails';
import Signin from './components/Auth/Login/Signin';
import OrderManagement from './components/Screen/OrderManagment/OrderManagement';
import AddOrders from './components/Screen/OrderManagment/AddProducts/AddOrders';
import EnquiryList from './components/Screen/EnquiryManagement/enquiry list/EnquiryList';
import AddEnquiryForm from './components/Screen/EnquiryManagement/add enquiry/AddEnquiryForm';
import EditEnquiryForm from './components/Screen/EnquiryManagement/edit enquiry/EditEnquiryForm';
import CommunityManagement from './components/Screen/CommunityManagement/CommunityManagement';
import AddCommunity from './components/Screen/CommunityManagement/AddCommunity/AddCommunity';
import EditCommunity from './components/Screen/CommunityManagement/AddCommunity/EditCommunity';
import OrderMgtDetails from './components/Screen/OrderManagment/OrderMgtDetails/OrderMgtDetails';
import EmployeeList from './components/Screen/UserManagement/BOS-employee/EmployeeList';
import CustomerList from './components/Screen/UserManagement/customer/List/CustomerList';
import PageNotFound from './components/Not Found/PageNotFound';
import UserConfirmation from './components/user confirmation/UserConfirmation';
import SupplierList from './components/Screen/UserManagement/supplier/List/SupplierList';
import { CustomSnackbar } from './utils/component';
import VendorList from './components/Screen/UserManagement/vendor/VendorList';
import AddVendor from './components/Screen/UserManagement/vendor/AddVendor';
import PermissionManagement from './components/Screen/Permission/PermissionManagement';
import MasterManagement from './components/Screen/Master/MasterManagement';
import TallyMaster from './components/Screen/Master/Tally/TallyMaster.jsx';
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lgm: 992,
      lg: 1200,
      xl: 1536,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CustomSnackbar />
      <Router>
        <Routes>
          <Route path="/login" element={<Signin />} />
          <Route path="/" element={<RequireAuth Component={Adminlayout} />}>
            <Route index element={<RequireAuth Component={Dashboard} />} />
            <Route path="/dashboard" element={<RequireAuth Component={Dashboard} />} />
            <Route path="/employee" element={<RequireAuth Component={EmployeeList} />} />
            <Route path="/employee/add" element={<RequireAuth Component={AddUser} />} />
            <Route path="/employee/edit/:id" element={<RequireAuth Component={AddUser} />} />
            <Route path="/customer" element={<RequireAuth Component={CustomerList} />} />
            <Route path="/customer/add" element={<RequireAuth Component={AddCustomer} />}>
              <Route index element={<RequireAuth Component={GeneralDetails} />} />
              <Route path="/customer/add/general-details" element={<RequireAuth Component={GeneralDetails} />} />
              <Route path="/customer/add/contact-details" element={<RequireAuth Component={ContactDetails} />} />
              <Route path="/customer/add/login-details" element={<RequireAuth Component={LoginDetails} />} />
              <Route path="/customer/add/enquiry-details" element={<RequireAuth Component={CustomerEnquiry} />} />
              <Route path="/customer/add/order-details" element={<RequireAuth Component={OrderDetails} />} />
            </Route>
            <Route path="/supplier" element={<RequireAuth Component={SupplierList} />} />
            <Route path="/supplier/add" element={<RequireAuth Component={AddSupplier} />}>
              <Route index element={<RequireAuth Component={SGeneralDetails} />} />
              <Route path="/supplier/add/general-details" element={<RequireAuth Component={SGeneralDetails} />} />
              <Route path="/supplier/add/contact-details" element={<RequireAuth Component={SContactDetails} />} />
              <Route path="/supplier/add/login-details" element={<RequireAuth Component={SLoginDetails} />} />
              <Route path="/supplier/add/enquiry-details" element={<RequireAuth Component={SEnquiryDetails} />} />
              <Route path="/supplier/add/product-details" element={<RequireAuth Component={SProductDetails} />} />
              <Route path="/supplier/add/community-details" element={<RequireAuth Component={SCommunityDetails} />} />
            </Route>

            <Route path="/vendor" element={<RequireAuth Component={VendorList} />} />
            <Route path="/vendor/add" element={<RequireAuth Component={AddVendor} />} />
            <Route path="/vendor/edit/:id" element={<RequireAuth Component={AddVendor} />} />
            <Route path="/enquiry" element={<RequireAuth Component={EnquiryList} />} />
            <Route path="/enquiry/add" element={<RequireAuth Component={AddEnquiryForm} />} />
            <Route path="/enquiry/edit/:id" element={<RequireAuth Component={EditEnquiryForm} />} />
            <Route path="/enquiry/view/:id" element={<RequireAuth Component={EditEnquiryForm} />} />
            <Route path="/order" element={<RequireAuth Component={OrderManagement} />} />
            <Route path="/order/add" element={<AddOrders />} />
            <Route path="/order/details" element={<OrderMgtDetails />} />
            <Route path="/community" element={<RequireAuth Component={CommunityManagement} />} />
            <Route path="/community/add" element={<RequireAuth Component={AddCommunity} />} />
            <Route path="/community/edit/:id" element={<RequireAuth Component={EditCommunity} />} />
            <Route path="/reports" element={<RequireAuth Component={Reports} />} />
            <Route path="/product" element={<RequireAuth Component={ProductManagement} />} />
            <Route path="/product/add" element={<RequireAuth Component={AddProducts} />} />
            <Route path="/import-products" element={<RequireAuth Component={ImportProducts} />} />
            <Route path="/products-record" element={<ProductsRecord />} />
            <Route path="/edit-products/:id" element={<EditProducts />} />
            <Route path="/permission" element={<PermissionManagement />} />
            <Route path="/master">
              <Route index path="/master/general" element={<MasterManagement />} />
              <Route path="/master/General2" element={<MasterManagement />} />
              <Route path="/master/tally" element={<TallyMaster />} />
            </Route>
          </Route>
          <Route path="/import-products/:id" element={<ImportProducts />} />
          <Route path="/import-result" element={<Result />} />
          <Route path="/user/confirmation/:id" element={<UserConfirmation />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
