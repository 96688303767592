import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './ProductManagement.css';
// import ListDataTable from '../../../common-components/table/ListDataTable';
// import tableHeaders from '../../../utils/table headings/TableHeaders.json';
import { Box, Popover, TextField, Typography, styled, Modal, RadioGroup, FormControlLabel, Button, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Colors from '../../../theme/colors/Color';
import { Add, Edit, Delete, Filter, Magnifier, importIcon, exportIcon } from '../../../theme/Images';
import ProductFilter from './filters/ProductFilter';
import { CustomRadio, Heading20Semi, Heading26Bold } from '../../../common-components';
import { CustomDataGrid, Heading16Reg } from '../../../utils/component';
import Pagination from '../../../utils/component/pagination/Pagination';
import { postData, postDataExport } from '../../../services/APIService';
import { showSnackbar } from '../../../redux/features/snackbar/snackbarSlice';
import { useDispatch } from 'react-redux';

const limit = 10;
let totalPages = 1;
const userData = JSON.parse(localStorage.getItem('userDetails'));

const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));

const CustomBtnBox = styled(Box)(() => ({
  height: '35px',
  width: '35px',
  borderRadius: '6px',
  padding: '8px 6px 5px',
  background: Colors.white,
  textAlign: 'center',
  cursor: 'pointer',
}));

const DeleteModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 4,
};

const initialFilters = {
  product_code: [],
  firm_id: [],
  status: [],
  location: [],
  show_in_catalog: [],
};
function debounce(func, delay) {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
}

const ProductManagement = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState('BOS');
  const navigate = useNavigate();

  const [page, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const [products, setProducts] = useState([]);
  const [selectedRows, setselectedRows] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [keyword, setKeyword] = useState('');
  const dispatch = useDispatch();
  const [isFilterActive, setIsFilterActive] = useState(false);
  useEffect(() => {
    fetchProducts();
  }, [page, filters, keyword]);

  const fetchProducts = async () => {
    try {
      const res = await postData(`/product/list`, { page, limit, filters, keyword }, {});
      if (res && res.data && res.data.status) {
        totalPages = res.data.data.totalPages;
        setProducts(res.data.data.products);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      if (error?.response?.status === 404) setProducts([]);
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await postData('/product/delete', { id: selectedRows }, {}, userData.token);
      if (res && res && res.data.status) {
        setDeleteWarning(false);
        fetchProducts();
        dispatch(showSnackbar({ message: 'Product deleted successfully', severity: 'success' }));
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
    setselectedRows([]);
  };

  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
    setIsFilterActive(true);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
    setIsFilterActive(false);
  };
  const handleClearFilter = () => {
    setFilters(initialFilters);
    fetchProducts();
  };
  const handleExport = async () => {
    if (selectedRows.length === 0) {
      dispatch(showSnackbar({ message: 'Please select products to export!', severity: 'warning' }));
      return; // Exit the function if no products are selected
    }

    try {
      const response = await postDataExport(
        '/product/exportList',
        { productIds: selectedRows },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`, // Template literal for cleaner string concatenation
          },
          responseType: 'blob',
        },
        userData.token
      );

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'products.xlsx';
      link.click();

      dispatch(showSnackbar({ message: 'Export successful', severity: 'success' }));
    } catch (error) {
      console.error('Error:', error);
      dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    }
  };

  const handleAddButtonClick = () => {
    setModalOpen(true);
  };

  const handleModalConfirm = () => {
    setModalOpen(false);
    navigate(`/product/add`, { state: { supplier: selectedSupplier } });
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const open = Boolean(anchorEl);

  const columnsData = [
    {
      field: 'product_code',
      headerName: 'Product Code',
      flex: 1.2,
      renderCell: (params) => {
        const { product_id } = params.row;
        const viewDetails = () => {
          navigate('/product/add', { state: { id: product_id, mode: 'view' } });
        };
        return (
          <Box
            sx={{
              color: Colors.primary,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={viewDetails}
          >
            {params.value}
          </Box>
        );
      },
    },
    { field: 'createdAt', headerName: 'Date of entry', flex: 0.7, valueGetter: (p) => new Date(p).toLocaleDateString() },
    {
      field: 'category',
      headerName: 'Product category',
      flex: 1,
      valueGetter: (params) => (params ? params.category_name : ''),
    },
    {
      field: 'subCategory',
      headerName: 'Product sub-category',
      flex: 1,
      valueGetter: (params) => (params ? params.sub_category_name : ''),
    },
    {
      field: 'firm',
      headerName: 'Supplier Name',
      flex: 1,
      valueGetter: (params) => {
        return params ? params.firm_name : '';
      },
    },
    {
      field: 'addressLocation',
      headerName: 'WareHouse Location',
      flex: 1.5,
      valueGetter: (params) => {
        if (params) return params.address_line_1 + ', ' + params.address_line_2 + ', ' + params.city + ', ' + params.state;
      },
    },
    {
      field: 'mill',
      headerName: 'Mill Name',
      flex: 0.7,
      valueGetter: (params) => (params ? params.mill_name : ''),
      renderCell: (params) => {
        return <Box sx={{ color: params.row.mill.is_external ? Colors.error : 'inherit' }}>{params.value}</Box>;
      },
    },
    { field: 'heat_number', headerName: 'Heat No.', flex: 0.5 },
    { field: 'quantity', headerName: 'Quantity', flex: 0.7 },
    {
      field: 'show_in_catalog',
      headerName: 'Show in catalog',
      flex: 0.8,
      renderCell: (params) => {
        if (params)
          return (
            <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  background: params.value ? Colors.success : Colors.error,
                  height: '25px',
                  width: '80px',
                  borderRadius: '15px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  padding: '1px 20px',
                }}
              >
                {params.value ? 'Yes' : 'No'}
              </Box>
            </Box>
          );
      },
    },
    {
      headerName: 'Edit',
      width: 70,
      renderCell: ({ id }) => {
        const handleEdit = () => {
          navigate('/product/add', { state: { id, mode: 'edit' } });
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
    },
  ];
  const handleSelectedFilters = (filter) => {
    // const cleanedFilters = Object.fromEntries(
    //   Object.entries(filter).filter(([key, value]) => {
    //     if (key) return Array.isArray(value) ? value.length > 0 : value;
    //   })
    // );
    setFilters(filter);
  };

  const debouncedSetKeyword = useRef(debounce((value) => setKeyword(value), 300)).current;

  const handleSearch = (e) => {
    const keyword = e.target.value.trim();
    debouncedSetKeyword(keyword);
  };
  const handleImport = () => {
    navigate(`/import-products`);
  };
  return (
    <>
      <Heading26Bold text={'Product Management'} />
      <Box
        sx={{
          marginTop: '20px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch
          placeholder="Search Products"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Magnifier} alt="Search" style={{ width: '20px', height: '20px' }} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <CustomBtnBox onClick={handleClickFilter}>
              <img src={Filter} alt="filter" style={{ height: '20px', width: '20px' }} />
              <Typography sx={{ fontSize: '11px', fontFamily: 'Poppins', color: '#000000' }}>Filter</Typography>
            </CustomBtnBox>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: { marginTop: 5 },
              }}
            >
              <Box>
                <ProductFilter selectedFilters={handleSelectedFilters} filterData={filters} />
                {isFilterActive && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                      position: 'relative',
                      bottom: 10,
                      right: 0,
                      width: '100%',
                      paddingRight: '30px',
                    }}
                  >
                    <Button variant="contained" size="small" onClick={handleClearFilter}>
                      Clear filter
                    </Button>
                  </Box>
                )}
              </Box>
            </Popover>
          </Box>
          <CustomBtnBox onClick={handleExport}>
            <img src={exportIcon} alt="add" style={{ height: '20px', width: '20px' }} />
            <Typography sx={{ fontSize: '11px', fontFamily: 'Poppins', color: '#000000', fontWeight: '400' }}>Export</Typography>
          </CustomBtnBox>
          <CustomBtnBox onClick={handleAddButtonClick}>
            <img src={Add} alt="add" style={{ height: '20px', width: '20px' }} />
            <Typography sx={{ fontSize: '11px', fontFamily: 'Poppins', color: '#000000', fontWeight: '400' }}>Add</Typography>
          </CustomBtnBox>
          <CustomBtnBox onClick={handleImport}>
            <img src={importIcon} alt="import" style={{ height: '20px', width: '20px' }} />
            <Typography sx={{ fontSize: '11px', fontFamily: 'Poppins', color: '#000000', fontWeight: '400' }}>Import</Typography>
          </CustomBtnBox>
          <CustomBtnBox onClick={() => setDeleteWarning(true)}>
            <img src={Delete} alt="delete" style={{ height: '20px', width: '20px' }} />
            <Typography sx={{ fontSize: '11px', fontFamily: 'Poppins', color: '#000000', fontWeight: '400' }}>Delete</Typography>
          </CustomBtnBox>
        </Box>
      </Box>

      <CustomDataGrid rowIdKey={'product_id'} columnsData={columnsData} rowsData={products} onSelectCheckbox={(r) => setselectedRows(r)} />
      <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 290,
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            p: 4,
            borderRadius: '20px',
            textAlign: 'center',
          }}
        >
          <Heading20Semi text={'Add New Product For'} />
          <RadioGroup
            aria-label="supplier"
            name="supplier"
            value={selectedSupplier}
            onChange={(e) => setSelectedSupplier(e.target.value)}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 2 }}
          >
            <FormControlLabel value="BOS" label="BOS" control={<CustomRadio />} labelPlacement="end" />
            <FormControlLabel value="Other supplier" control={<CustomRadio />} label="Other supplier" labelPlacement="end" />
          </RadioGroup>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleModalConfirm}
              sx={{ textTransform: 'none', borderRadius: '20px', bgcolor: Colors.primary, width: '130px' }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              onClick={handleModalClose}
              sx={{ textTransform: 'none', borderRadius: '20px', width: '130px', color: Colors.primary }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteModalstyle}>
          {selectedRows?.length ? (
            <>
              <Heading16Reg text="Do you want to Delete Products Permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDelete}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select Product first." />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ProductManagement;
