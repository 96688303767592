import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Colors from '../../theme/colors/Color';
import PropTypes from 'prop-types';

const FloatingLabelSelect = (props) => {
  const [selectedValue, setSelectedValue] = useState('');
  const showOtherOpn = ['Grade', 'Size', 'Process Route', 'Supply Condition'];

  // display dropdown item names
  const itemNames = ['label', 'grade_name', 'pr_name', 'category_name', 'sub_category_name', 'size_name', 'firm_name', 'name', 'city'];

  // menu item dispaly name
  const item = props.options[0];
  let displayItem;
  if (item) {
    displayItem = itemNames.filter((name) => Object.prototype.hasOwnProperty.call(item, name));
  }

  const handleSelect = (item) => {
    setSelectedValue(item[displayItem[0]]);
    props.selected(item);
  };

  return (
    <>
      <FormControl fullWidth required={props.isRequire}>
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            top: '-6px',
            fontFamily: 'poppins-regular',
            '&.MuiInputLabel-shrink': {
              top: '0px',
              left: '0px',
              color: Colors.text_413e3e,
              transform: 'translate(14px, -14px) scale(0.75)',
            },
            '& .MuiFormLabel-asterisk': {
              color: Colors.error,
            },
          }}
        >
          {props.label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          label={props.label}
          disabled={props.isDisable}
          IconComponent={ExpandMoreRoundedIcon}
          sx={{
            height: '45px',
            fontFamily: 'poppins-regular',
            background: props.bgColor ? props.bgColor : Colors.transparent,
            '& .MuiSelect-select': {
              paddingTop: '12px',
              paddingBottom: '12px',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.text_413e3e,
              border: '1px solid',
            },
            '&.MuiSelect-selectMenu': {
              color: 'red',
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiMenuItem-root': {
                  fontFamily: 'poppins-regular',
                },
              },
            },
          }}
        >
          {/* <MenuItem value={0} disabled>
            {props.placeholder}
          </MenuItem> */}
          {showOtherOpn.includes(props.label) && (
            <MenuItem value={'other'} onClick={() => handleSelect({ value: 'other', label: 'Other' })}>
              Other
            </MenuItem>
          )}
          {props.options.map((item, i) => (
            <MenuItem value={item[displayItem[0]]} key={i} onClick={() => handleSelect(item)}>
              {item[displayItem[0]]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

FloatingLabelSelect.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.func,
  isRequire: PropTypes.bool,
  isDisable: PropTypes.bool,
  bgColor: PropTypes.string,
};

export default FloatingLabelSelect;
