import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, styled } from '@mui/material';
import Colors from '../../../theme/colors/Color';
import PropTypes from 'prop-types';

const StyledDataGrid = styled(DataGrid)(() => ({
  border: 'none',
  minWidth: '800px',
  overflowX: 'auto',
  // Header styles
  '& .MuiDataGrid-columnHeaders': {
    // backgroundColor: 'red',
    // height: 'px',
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: Colors.secondary1,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '16px',
    fontFamily: 'poppins-semibold',
    color: Colors.white,
  },
  // Body row styles
  '& .MuiDataGrid-cell': {
    fontSize: '14px',
    fontFamily: 'poppins-regular',
    color: Colors.text_black,
    border: 'none',
    borderBottom: '1px solid',
    borderColor: Colors.secondary1,
  },
  '& .MuiDataGrid-row': {
    height: '30px',
  },
  '& .MuiDataGrid-root': {
    // border: 'none !important',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .highlighted': {
    backgroundColor: Colors.highlightRow, // Define your highlight color
  },
}));

const CustumDataGrid = (props) => {
  const { getHighlightCondition, rowsData, columnsData, rowIdKey, onSelectCheckbox } = props;

  const modifiedColumns = columnsData.map((column) => ({
    ...column,
    sortable: false, // Disable sorting
    disableColumnMenu: true, // Disable column menu
  }));

  const handleSelectionChange = (selectionRow) => {
    onSelectCheckbox(selectionRow);
  };

  const getRowClassName = (params) => {
    return getHighlightCondition && getHighlightCondition(params) ? 'highlighted' : '';
  };

  return (
    <Box
      sx={{
        height: '60vh',
        width: '100%',
        marginTop: '20px',
        overflow: 'auto',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <StyledDataGrid
        rows={rowsData}
        columns={modifiedColumns}
        disableRowSelectionOnClick
        hideFooter
        checkboxSelection
        getRowId={(row) => row[rowIdKey]}
        onRowSelectionModelChange={handleSelectionChange}
        getRowClassName={getRowClassName}
      />
    </Box>
  );
};

CustumDataGrid.propTypes = {
  rowIdKey: PropTypes.string.isRequired,
  rowsData: PropTypes.array.isRequired,
  columnsData: PropTypes.array.isRequired,
  onSelectCheckbox: PropTypes.func.isRequired,
  getHighlightCondition: PropTypes.func, // New prop for row highlighting condition
};

export default CustumDataGrid;
