import { Button, styled } from '@mui/material';
import React from 'react';
import Colors from '../../../theme/colors/Color';
import PropTypes from 'prop-types';

const CustomBtn = styled(Button)(() => ({
  color: Colors.white,
  background: Colors.primary,
  textTransform: 'none',
  padding: '0px 45px',
  height: '45px',
  borderRadius: '28px',
  fontSize: '18px',
  fontFamily: 'poppins-regular',
  lineHeight: '24px',
  '&:hover': {
    color: Colors.white,
    background: Colors.primary,
  },
  '&:disabled': {
    // color: Colors.gray,
    backgroundColor: Colors.placeholder,
    // borderStyle: 'solid',
    // borderWidth: '1px',
    // borderColor: Colors.rhino,
  },
}));

const FilledButton = (props) => {
  return (
    <CustomBtn onClick={props.onClick} disabled={props.isdisable}>
      {props.text}
    </CustomBtn>
  );
};

FilledButton.propTypes = {
  text: PropTypes.string.isRequired,
  isdisable: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FilledButton;
