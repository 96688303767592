import { Box, Link } from '@mui/material';
import React from 'react';
import { Heading26Bold } from '../../../../common-components';
import Colors from '../../../../theme/colors/Color';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const AddCustomer = () => {
  const firmData = JSON.parse(localStorage.getItem('firmData'));
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  let navList;
  if (firmData?.firmId || state?.firmId) {
    navList = [
      { name: 'General Details', path: 'general-details' },
      { name: 'Log In Details', path: 'login-details' },
      { name: 'Contact Details', path: 'contact-details' },
      { name: 'Enquiry Details', path: 'enquiry-details' },
      { name: 'Order Details', path: 'order-details' },
    ];
  } else {
    navList = [
      { name: 'General Details', path: 'general-details' },
      { name: 'Log In Details' },
      { name: 'Contact Details' },
      { name: 'Enquiry Details' },
      { name: 'Order Details' },
    ];
  }

  return (
    <Box>
      <Heading26Bold text={firmData?.firmId || state?.firmId ? 'Customer' : 'Add New Customer'} />
      <Box marginTop={'30px'} height={'50px'} display={'flex'} gap={'15px'}>
        {navList.map((item, i) => {
          let isActive = location.pathname === `/customer/add/${item.path}` || (location.pathname === '/customer/add' && i === 0);
          return (
            <Link
              key={i}
              onClick={() => {
                if (item.path) navigate(`/customer/add/${item.path}`);
              }}
              sx={{
                textDecoration: 'none',
                borderRadius: '25px',
                color: isActive ? Colors.text_black : Colors.placeholder,
                background: isActive ? Colors.bg_strip : Colors.bg_grey,
                // fontSize: '16px',
                fontSize: '1rem',
                fontFamily: 'poppins-regular',
                padding: '12px 30px',
                cursor: 'pointer',
                '&:hover': {
                  background: isActive ? Colors.tersary : 'lightgray',
                },
              }}
            >
              {item.name}
            </Link>
          );
        })}
      </Box>
      <Box
        // border={'1px solid red'}
        sx={{
          height: { xl: '74vh', xs: '70vh' },
          padding: '5px',
          marginTop: '30px',
          overflow: 'auto',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default AddCustomer;
