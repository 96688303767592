import { Button, styled } from '@mui/material';
import React from 'react';
import Colors from '../../theme/colors/Color';
import PropTypes from 'prop-types';

const CustomBtn = styled(Button)(() => ({
  color: Colors.text_black,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: Colors.primary,
  textTransform: 'none',
  padding: '0px 45px',
  height: '45px',
  borderRadius: '28px',
  fontSize: '18px',
  fontFamily: 'poppins-regular',
  lineHeight: '24px',
}));

const OutlineBtn = (props) => {
  return (
    <CustomBtn onClick={props.onClick} disabled={props.isdisable}>
      {props.text}
    </CustomBtn>
  );
};

OutlineBtn.propTypes = {
  text: PropTypes.string.isRequired,
  isdisable: PropTypes.bool,
  onClick: PropTypes.func,
};

export default OutlineBtn;
