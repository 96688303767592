import React, { useState } from 'react';
import './SideNavBar.css';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, List } from 'react-feather';
// import { Logo, productManagement, enquiryManagement, report, userManagement, OrderManagemeht } from '../../../theme/Images';
import {
  Logo,
  productManagement,
  enquiryManagement,
  userManagement,
  OrderManagemeht,
  communityIcon,
  permissionIcon,
  masterIcon,
} from '../../../theme/Images';

const SideNavbar = () => {
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [enquiryDropdownOpen, setEnquiryDropdownOpen] = useState(false);
  const [productDropdownOpen, setProductDropdownOpen] = useState(false);
  const [orderDropdownOpen, setOrderDropdownOpen] = useState(false);
  const [communityDropdownOpen, setCommunityDropdownOpen] = useState(false);
  // const [reportsDropdownOpen, setReportsDropdownOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(''); // State for active menu item
  const [sidebarOpen, setSidebarOpen] = useState(false); // State for sidebar visibility
  const [showItems, setShowItems] = useState(true); // State to manage visibility of items
  const [permissionDropdown, setPermissionDropdown] = useState(false);
  const [masterDropdown, setMasterDropdown] = useState(false);
  const toggleItems = () => {
    setShowItems(!showItems); // Toggle visibility of items
  };

  // const handleLinkClick = () => {
  //   setShowItems(false) // Hide items when link is clicked
  // }

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
    // Close other dropdowns
    setMasterDropdown(false);
    setEnquiryDropdownOpen(false);
    setProductDropdownOpen(false);
    // setReportsDropdownOpen(false);
    setOrderDropdownOpen(false);
    setCommunityDropdownOpen(false);
  };

  const toggleEnquiryDropdown = () => {
    setEnquiryDropdownOpen(!enquiryDropdownOpen);
    // Close other dropdowns
    setUserDropdownOpen(false);
    setProductDropdownOpen(false);
    setMasterDropdown(false);
    // setReportsDropdownOpen(false);
    setOrderDropdownOpen(false);
    setCommunityDropdownOpen(false);
  };

  const toggleProductDropdown = () => {
    setProductDropdownOpen(!productDropdownOpen);
    // Close other dropdowns
    setUserDropdownOpen(false);
    setEnquiryDropdownOpen(false);
    // setReportsDropdownOpen(false);
    setOrderDropdownOpen(false);
    setMasterDropdown(false);
    setCommunityDropdownOpen(false);
  };
  const toggleOrderDropdown = () => {
    setOrderDropdownOpen(!orderDropdownOpen);
    // Close other dropdowns
    setUserDropdownOpen(false);
    setEnquiryDropdownOpen(false);
    // setReportsDropdownOpen(false);
    setProductDropdownOpen(false);
    setMasterDropdown(false);
    setCommunityDropdownOpen(false);
  };

  /* const toggleReportsDropdown = () => {
    setReportsDropdownOpen(!reportsDropdownOpen);
    // Close other dropdowns
    setUserDropdownOpen(false);
    setEnquiryDropdownOpen(false);
    setProductDropdownOpen(false);
    setOrderDropdownOpen(false);
    setCommunityDropdownOpen(false);
  }; */
  const toggleCommunityDropdown = () => {
    setCommunityDropdownOpen(!communityDropdownOpen);
    // Close other dropdowns
    setUserDropdownOpen(false);
    setEnquiryDropdownOpen(false);
    setProductDropdownOpen(false);
    setOrderDropdownOpen(false);
    setMasterDropdown(false);

    // setReportsDropdownOpen(false);
  };
  const togglePermissionDropdown = () => {
    setPermissionDropdown(!permissionDropdown);
    // Close other dropdowns
    setCommunityDropdownOpen(false);
    setUserDropdownOpen(false);
    setEnquiryDropdownOpen(false);
    setProductDropdownOpen(false);
    setOrderDropdownOpen(false);
    setMasterDropdown(false);
    // setReportsDropdownOpen(false);
  };
  const toggleMasterDropdown = () => {
    setMasterDropdown(!masterDropdown);
    setPermissionDropdown(false);
    // Close other dropdowns
    setUserDropdownOpen(false);
    setEnquiryDropdownOpen(false);
    setProductDropdownOpen(false);
    setOrderDropdownOpen(false);
    setCommunityDropdownOpen(false);
    // setReportsDropdownOpen(false);
  };

  const setActiveMenu = (menu) => {
    setActiveMenuItem(menu);
  };

  return (
    <div className="sidenavbar-content">
      <div className="toggle-icon" onClick={toggleSidebar}>
        <List size={22} />
      </div>
      <Link to="/dashboard">
        <img src={Logo} alt="Logo Missing" className="company-logo" />
      </Link>
      <h2 className="title">Administration</h2>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li className="menu-item">
          <div className="menu-item-container" onClick={toggleUserDropdown}>
            {/* Using Link to navigate to the user management page */}
            <Link
              // to="/employee"
              className={`nav-link ${activeMenuItem === 'user' ? 'active' : ''}`}
              // onClick={() => setActiveMenu('user')}
              style={{ color: 'black' }}
            >
              <span>
                <img className="menu-item-icon" src={userManagement} style={{ marginBottom: '-6px' }} />
              </span>
              User Management
            </Link>
            <button className="dropdown-button">{userDropdownOpen ? <ChevronUp /> : <ChevronDown />}</button>
          </div>
        </li>
        {userDropdownOpen && (
          <ul className="submenu">
            {/* You can keep the dropdown options here */}
            <Link
              // to="/user-management"
              className={`nav-link ${['add-user', 'bos-employee', 'customer', 'supplier', 'vendor'].includes(activeMenuItem) ? 'active' : ''}`}
              onClick={() => {
                setActiveMenu('add-user');
                toggleItems(); // Toggle visibility of items when link is clicked
              }}
            >
              <li>Add/Edit User</li>
            </Link>
            {showItems && (
              <ul className="usersubmenu">
                <Link
                  to="/employee"
                  className={`nav-link-user ${activeMenuItem === 'bos-employee' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveMenu('bos-employee');
                  }}
                >
                  <li>BOS Employee</li>
                </Link>
                <Link
                  to="/customer"
                  className={`nav-link-user ${activeMenuItem === 'customer' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveMenu('customer');
                  }}
                >
                  <li>Customer</li>
                </Link>
                <Link
                  to="/supplier"
                  className={`nav-link-user ${activeMenuItem === 'supplier' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveMenu('supplier');
                  }}
                >
                  <li>Supplier</li>
                </Link>
                <Link
                  to="/vendor"
                  className={`nav-link-user ${activeMenuItem === 'vendor' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveMenu('vendor');
                  }}
                >
                  <li>Vendor</li>
                </Link>
              </ul>
            )}
            {/* <Link
              to="/user-management"
              className={`nav-link ${activeMenuItem === 'manage-user-login' ? 'active' : ''}`}
              onClick={() => {
                setActiveMenu('manage-user-login')
                handleLinkClick() // Collapse submenu items when link is clicked
              }}
            >
              <li>Manage User Login</li>
            </Link>
            <Link
              to="/user-management"
              className={`nav-link ${activeMenuItem === 'privilege-management' ? 'active' : ''}`}
              onClick={() => {
                setActiveMenu('privilege-management')
                handleLinkClick() // Collapse submenu items when link is clicked
              }}
            >
              <li>Privilege Management</li>
            </Link> */}
          </ul>
        )}
      </ul>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li className="menu-item">
          <div className="menu-item-container" onClick={toggleEnquiryDropdown}>
            {/* Using Link to navigate to the enquiry management page */}
            <Link
              to="/enquiry"
              className={`nav-link ${activeMenuItem === 'enquiry' ? 'active' : ''}`}
              onClick={() => setActiveMenu('enquiry')}
              style={{ color: 'black' }}
            >
              <span>
                <img className="menu-item-icon" src={enquiryManagement} style={{ marginBottom: '-7px' }} />
              </span>
              Enquiry Management
            </Link>
            <button className="dropdown-button">{enquiryDropdownOpen ? <ChevronUp /> : <ChevronDown />}</button>
          </div>
        </li>
        {enquiryDropdownOpen && (
          <ul className="submenu">
            <Link
              to="/enquiry"
              className={`nav-link ${activeMenuItem === 'add-enquiry' ? 'active' : ''}`}
              onClick={() => setActiveMenu('add-enquiry')}
            >
              <li>Add/Edit Enquery</li>
            </Link>
          </ul>
        )}
      </ul>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li className="menu-item">
          <div className="menu-item-container" onClick={toggleProductDropdown}>
            {/* Using Link to navigate to the product management page */}
            <Link
              to="/product"
              className={`nav-link ${activeMenuItem === 'product' ? 'active' : ''}`}
              onClick={() => setActiveMenu('product-management')}
              style={{ color: 'black' }}
            >
              <span>
                <img className="menu-item-icon" src={productManagement} style={{ marginBottom: '-8px' }} />
              </span>
              Product Management
            </Link>
            <button className="dropdown-button">{productDropdownOpen ? <ChevronUp /> : <ChevronDown />}</button>
          </div>
        </li>
        {productDropdownOpen && (
          <ul className="submenu">
            <Link
              to="/product "
              className={`nav-link ${activeMenuItem === 'add-product' ? 'active' : ''}`}
              onClick={() => setActiveMenu('add-product')}
            >
              <li>Add/Edit Product</li>
            </Link>
          </ul>
        )}
      </ul>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li className="menu-item">
          <div className="menu-item-container" onClick={toggleOrderDropdown}>
            {/* Using Link to navigate to the product management page */}
            <Link
              to="/order"
              className={`nav-link ${activeMenuItem === 'order' ? 'active' : ''}`}
              onClick={() => setActiveMenu('order')}
              style={{ color: 'black' }}
            >
              <span>
                <img className="menu-item-icon" src={OrderManagemeht} style={{ marginBottom: '-7px' }} />
              </span>
              Order Management
            </Link>
            <button className="dropdown-button">{orderDropdownOpen ? <ChevronUp /> : <ChevronDown />}</button>
          </div>
        </li>
        {orderDropdownOpen && (
          <ul className="submenu">
            <Link
              to="/order"
              className={`nav-link ${activeMenuItem === 'add-orders' ? 'active' : ''}`}
              onClick={() => setActiveMenu('add-orders')}
            >
              <li>Add/Edit Order</li>
            </Link>
          </ul>
        )}
      </ul>

      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li className="menu-item">
          <div className="menu-item-container" onClick={toggleCommunityDropdown}>
            {/* Using Link to navigate to the product management page */}
            <Link
              to="/community"
              className={`nav-link ${activeMenuItem === 'community' ? 'active' : ''}`}
              onClick={() => setActiveMenu('community')}
              style={{ color: 'black' }}
            >
              <span>
                <img className="menu-item-icon" src={communityIcon} style={{ marginBottom: '-7px' }} />
              </span>
              Community
            </Link>
            <button className="dropdown-button">{communityDropdownOpen ? <ChevronUp /> : <ChevronDown />}</button>
          </div>
        </li>
        {communityDropdownOpen && (
          <ul className="submenu">
            <Link
              to="/community "
              className={`nav-link ${activeMenuItem === 'community' ? 'active' : ''}`}
              onClick={() => setActiveMenu('community')}
            >
              <li>Add/Edit Enquiry</li>
            </Link>
          </ul>
        )}
      </ul>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li className="menu-item">
          <div className="menu-item-container" onClick={togglePermissionDropdown}>
            {/* Using Link to navigate to the product management page */}
            <Link
              to="/permission"
              className={`nav-link ${activeMenuItem === 'permission' ? 'active' : ''}`}
              onClick={() => setActiveMenu('permission')}
              style={{ color: 'black' }}
            >
              <span>
                <img className="menu-item-icon" src={permissionIcon} style={{ marginBottom: '-7px' }} />
              </span>
              Permission
            </Link>
            <button className="dropdown-button">{<ChevronDown />}</button>
            {/* <button className="dropdown-button">{communityDropdownOpen ? <ChevronUp /> : <ChevronDown />}</button> */}
          </div>
        </li>
        {/* {communityDropdownOpen && (
          <ul className="submenu">
            <Link
              to="/permission "
              className={`nav-link ${activeMenuItem === 'permission' ? 'active' : ''}`}
              onClick={() => setActiveMenu('permission')}
            >
              <li>Add/Edit permission</li>
            </Link>
          </ul>
        )} */}
      </ul>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li className="menu-item">
          <div className="menu-item-container" onClick={toggleMasterDropdown}>
            {/* Using Link to navigate to the product management page */}
            <Link
              // to="/allmaster"
              className={`nav-link ${activeMenuItem === 'allmaster' ? 'active' : ''}`}
              // onClick={() => setActiveMenu('allmaster')}
              style={{ color: 'black' }}
            >
              <span>
                <img className="menu-item-icon" src={masterIcon} style={{ marginBottom: '-7px' }} />
              </span>
              Master
            </Link>
            <button className="dropdown-button">{masterDropdown ? <ChevronUp /> : <ChevronDown />}</button>
          </div>
        </li>
        {masterDropdown && (
          <ul className="submenu">
            <Link
              to="/master/general"
              className={`nav-link ${activeMenuItem === 'generalMaster' ? 'active' : ''}`}
              onClick={() => setActiveMenu('generalMaster')}
            >
              <li>General</li>
            </Link>
            <Link
              to="/master/general2"
              className={`nav-link ${activeMenuItem === 'general2' ? 'active' : ''}`}
              onClick={() => setActiveMenu('general2')}
            >
              <li>General 2</li>
            </Link>
            <Link
              to="/master/tally"
              className={`nav-link ${activeMenuItem === 'tallymaster' ? 'active' : ''}`}
              onClick={() => setActiveMenu('tallymaster')}
            >
              <li>Tally</li>
            </Link>
          </ul>
        )}
      </ul>
      {/*   <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li className="menu-item">
          <div className="menu-item-container" onClick={toggleReportsDropdown}>
            <Link
              to="/reports"
              className={`nav-link ${activeMenuItem === 'reports' ? 'active' : ''}`}
              onClick={() => setActiveMenu('reports')}
              style={{ color: 'black' }}
            >
              <span>
                <img className="menu-item-icon" src={report} style={{ marginBottom: '-10px' }} />
              </span>
              Reports
            </Link>
            <button className="dropdown-button">{reportsDropdownOpen ? <ChevronUp /> : <ChevronDown />}</button>
          </div>
        </li>
        {reportsDropdownOpen && (
          <ul className="submenu">
            <li>Option 1</li>
            <li>Option 2</li>
            <li>Option 3</li>
          </ul>
        )}
      </ul> */}
    </div>
  );
};

export default SideNavbar;
