import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const Heading20Semi = (props) => {
  return (
    <Typography fontSize={'20px'} fontFamily={'poppins-semibold'} lineHeight={'24px'}>
      {props.text}
    </Typography>
  );
};

Heading20Semi.propTypes = {
  text: PropTypes.string.isRequired,
};
export default Heading20Semi;
