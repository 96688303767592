import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../../theme/colors/Color';

const Heading24Semi = (props) => {
  return (
    <Typography fontSize={'24px'} fontFamily={'poppins-semibold'} lineHeight={'24px'} color={Colors.heading}>
      {props.text}
    </Typography>
  );
};

Heading24Semi.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Heading24Semi;
