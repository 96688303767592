import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, { useState } from 'react';
import { Modal, Button, Box, Typography } from '@mui/material';
import { DateRange } from 'react-date-range';
import PropTypes from 'prop-types';

export default function DateRangePicker({ startDate, endDate, onDateRangeChange }) {
  const [state, setState] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    },
  ]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
    onDateRangeChange(ranges.selection);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Select Date
      </Button>

      <Modal open={open} onClose={handleClose} aria-labelledby="date-range-picker-title" aria-describedby="date-range-picker-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            maxWidth: '90%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'hidden',
          }}
        >
          <Typography id="date-range-picker-title" variant="h6" component="h2">
            Select Date Range
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            <DateRange
              ranges={state}
              onChange={handleSelect}
              // months={2} // Display two months side-by-side
              // direction="horizontal" // Ensure months are displayed side-by-side
              // style={{ width: '100%' }} // Ensure the picker takes full width of its container
            />
          </Box>
          <Box mt={2}>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <div>
        <p>Start Date: {state[0].startDate.toDateString()}</p>
        <p>End Date: {state[0].endDate.toDateString()}</p>
      </div>
    </div>
  );
}

DateRangePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onDateRangeChange: PropTypes.func,
};
