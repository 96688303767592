import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Typography,
  Grid,
  CardContent,
  Divider,
  StepConnector,
  Modal,
  Button,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { getData, putFileData } from '../../../../services/APIService';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import VendorStep from './VendorStep';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Colors from '../../../../theme/colors/Color';
const userData = JSON.parse(localStorage.getItem('userDetails'));

const stages = [
  'Pending For Payment Details',
  'Awaiting Order Acceptance',
  'Order Acceptance Generated',
  'Order Accepted',
  'Processing Order',
  'Order Completed',
];
const boxStyle = {
  backgroundColor: '#ACE9ED',
  padding: '15px',
  borderRadius: '8px',
  display: 'flex',
  width: '15%',
  cursor: 'pointer',
};
const CustomStepper = styled(Stepper)({
  backgroundColor: '#00C9D84D',
  padding: '20px',
  borderRadius: '5px',
  marginBottom: '20px',
});
const CustomStepConnector = styled(StepConnector)(() => ({
  '& .MuiStepConnector-line': {
    borderColor: '#ccc',
    borderTopWidth: 2,
    marginTop: '6px',
  },
  '&.Mui-active .MuiStepConnector-line': {
    borderColor: 'blue',
  },
  '&.Mui-completed .MuiStepConnector-line': {
    borderColor: 'blue',
  },
}));
const CustomStepLabel = styled(StepLabel)(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  // Customization for the StepIcon (circle)
  '& .MuiStepIcon-root': {
    fontSize: '2rem',
    width: '40px',
    height: '40px',
  },
  '& .MuiStepIcon-text': {
    fontSize: '1rem', // Adjust the text size inside the circle
  },
}));

const OrderDetails = styled(Paper)({
  padding: '10px',
  marginBottom: '10px',
  border: '3px solid #000',
});

const getActiveStep = (status) => {
  switch (status) {
    case 'Pending':
      return 0;
    case 'Awaiting':
      return 1;
    case 'Generated':
      return 2;
    case 'Accepted':
      return 3;
    case 'Processing':
      return 4;
    case 'Complete':
      return 5;
    default:
      return 0; // Default to the first step if no match
  }
};
const customStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#70FACB4D',
    width: '400px',
    '&:hover': {
      border: 'none',
    },
    height: '50px',
  }),

  menu: (provided) => ({
    ...provided,
    border: 'none',
  }),
  option: (provided) => ({
    ...provided,
  }),
};
// const modalStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   p: 4,
//   borderRadius: 2,
// };
const modalStyleInvices = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};
const modalStyleInvicesSubmit = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const modalStyleReturn = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

let productId = null;
let logisticsId = null;
let warehouseId = null;
let return_quantity = null;
const OrderStatus = ({ orderProducts, orderLocation, allStatus, refresh, formatDate }) => {
  console.log('orderProducts order stage', orderProducts);
  // const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [lorryFileName, setLorryFileName] = useState('');
  const lorryFileInputRef = useRef(null);
  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleIconClickLorry = () => {
    lorryFileInputRef.current.click();
  };
  const dispatch = useDispatch();
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [getVendors, setGetVendors] = useState([]);
  const [selectedLogistics, setSelectedLogistics] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [confirmData, setConfirmData] = useState(null);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openReturnModal, setOpenReturnModal] = useState(false);
  const [returnType, setReturnType] = useState('');
  const [returnData, setReturnData] = useState({});
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [lorryFile, setLorryFile] = useState(null);
  useEffect(() => {
    const step = getActiveStep(allStatus);
    setActiveStep(step);
  }, [allStatus]);

  // Handlers for the Invoice modal
  const handleOpenInvoiceModal = (id) => {
    productId = id;
    setOpenInvoiceModal(true);
  };
  const handleCloseInvoiceModal = () => {
    setOpenInvoiceModal(false);
  };

  // Handlers for the confirmation modal
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);
  const featchAllvendors = async () => {
    await getData('/users/vendors', {}, userData.token)
      .then((res) => {
        setGetVendors(res.data.data.users);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    featchAllvendors();
  }, []);
  const logisticsOptions = getVendors
    .filter((vendor) => vendor.sub_type === 'Logistics')
    .map((vendor) => ({ value: vendor.user_id, label: vendor.name }));

  const warehouseOptions = getVendors
    .filter((vendor) => vendor.sub_type === 'Warehouse')
    .map((vendor) => ({ value: vendor.user_id, label: vendor.name }));

  const handleVendorUpdate = async (id, data) => {
    try {
      const res = await putFileData(`/order/product/${productId}`, data, {}, userData.token);
      if (res.data.status) {
        refresh();
        dispatch(
          showSnackbar({
            message: 'Data Updated successfully',
            severity: 'success',
          })
        );
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error.message,
          severity: 'error',
        })
      );
    }
    productId = null;
    logisticsId = null;
    warehouseId = null;
  };
  const openConfirmationModal = (type) => {
    if (type === 'logistics' && !selectedLogistics) {
      dispatch(showSnackbar({ message: 'Please select a logistics option', severity: 'warning' }));
      return;
    }
    if (type === 'warehouse' && !selectedWarehouse) {
      dispatch(showSnackbar({ message: 'Please select a warehouse option', severity: 'warning' }));
      return;
    }
    setConfirmData(type);
    setOpenModal(true);
  };
  const handleConfirm = async () => {
    if (confirmData === 'logistics') {
      await handleVendorUpdate(productId, { logistics: logisticsId });
    } else if (confirmData === 'warehouse') {
      await handleVendorUpdate(productId, { warehouse: warehouseId });
    }
    setOpenModal(false);
  };

  const setData = (id, data) => {
    productId = id;
    logisticsId = data.logistics;
    warehouseId = data.warehouse;
  };
  const handleConfirmSubmit = async () => {
    const formData = new FormData();
    formData.append('invoice_bill_doc', invoiceFile);
    formData.append('lorry_receipt_doc', lorryFile);

    await handleVendorUpdate(id, formData);
    setOpenConfirmModal(false);
    setOpenInvoiceModal(false); // Close invoice modal after confirmation
  };
  const handleSubmit = () => {
    // Validate file selection
    if (!invoiceFile || !lorryFile) {
      dispatch(
        showSnackbar({
          message: 'Please select both files before submitting.',
          severity: 'warning',
        })
      );
      return;
    }
    handleOpenConfirmModal(); // Open the confirmation modal if both files are selected
  };
  const handleOpenInNewTab = (url) => {
    if (url && url !== 'null') {
      window.open(url, '_blank'); // Open the URL in a new tab
    } else {
      dispatch(
        showSnackbar({
          message: 'No document available to view.',
          severity: 'warning',
        })
      );
    }
  };
  const openReturnModel = (id, data, qty) => {
    productId = id;
    return_quantity = qty;
    setReturnType(data);
    setOpenReturnModal(true);
  };

  const handleReturnInputChange = (event) => {
    const { name, value } = event.target;
    setReturnData({
      ...returnData,
      [name]: value,
    });
  };
  const submitReturn = async (type) => {
    const data = { ...returnData };
    data.return_reject_by = userData.user?.name;
    if (type === 'accept' || type === 'confirm') data.is_return_accepted = true;
    else data.is_return_rejected = true;
    setOpenReturnModal(false);
    await handleVendorUpdate(null, data);
  };
  return (
    <>
      <Box sx={{ width: '100%', mt: 5 }}>
        <CustomStepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomStepConnector />} // Use the custom connector
        >
          {stages.map((label, index) => (
            <Step key={label} disabled={index > activeStep}>
              <CustomStepLabel active={activeStep === index}>{label}</CustomStepLabel>
            </Step>
          ))}
        </CustomStepper>

        <Box>
          <Grid container spacing={2}>
            {orderProducts && orderProducts.length > 0 ? (
              orderProducts.map((order, index) => (
                <Grid item xs={12} key={index}>
                  <OrderDetails elevation={3}>
                    <Grid container spacing={2}>
                      <Box mt={4} ml={3}>
                        <img
                          src={order.product.attachments.length > 0 ? order.product.attachments[0].url : 'default_image_url_here'}
                          alt="Product"
                          style={{ height: '100px', width: '130px' }}
                        />
                      </Box>
                      <Grid item xs={12} sm={10}>
                        <CardContent>
                          <Grid container spacing={7}>
                            <Grid item xs={4.5}>
                              <Typography variant="body1">
                                <strong>Product Code:</strong> {order.product.product_code}
                              </Typography>
                              <Typography variant="body1">
                                <strong>Product Category:</strong> {order.product.category.category_name}
                              </Typography>
                              <Typography variant="body1">
                                <strong>Mill Name:</strong> {order.product.addressLocation.address_line_1}
                              </Typography>
                              <Typography variant="body1">
                                <strong>Basic Price/Metric Ton:</strong> {order.product.basic_price} ₹
                              </Typography>
                            </Grid>

                            <Grid item xs={3.5}>
                              <Typography variant="body1">
                                <strong>Product Name:</strong> {order.product.product_name}
                              </Typography>
                              <Typography variant="body1">
                                <strong>Sub Category:</strong> {order.product.subCategory.sub_category_name}
                              </Typography>
                              <Typography variant="body1">
                                <strong>Heat No.:</strong> {order.product.heat_number}
                              </Typography>
                              <Typography variant="body1">
                                <strong>Price As Per Quantity:</strong> {order.total_price} ₹
                              </Typography>
                            </Grid>

                            <Grid item xs={4}>
                              <Typography variant="body1">
                                <strong>Quantity:</strong> {order.quantity}
                              </Typography>
                              <Typography variant="body1">
                                <strong>Shape:</strong> {order.product.shape.shape_name}
                              </Typography>
                              <Typography variant="body1">
                                <strong>Grade:</strong> {order.product.grade.grade_name}
                              </Typography>
                              <Typography variant="body1">
                                <strong>Size:</strong> {order.product.size.size_name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Grid>
                      <Grid item xs={12} mt={-3}>
                        <Divider sx={{ borderColor: 'black' }} />
                        <Typography variant="body1">
                          <strong>Warehouse Location:</strong> {order.product.addressLocation.address_line_1},{' '}
                          {order.product.addressLocation.city}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Delivery Location:</strong> {orderLocation || 'Not Available'}
                        </Typography>
                        <Divider sx={{ borderColor: 'black' }} />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item md={order.sub_status === 'Return Requested' ? 10 : 12}>
                        {(allStatus === 'Accepted' || allStatus === 'Processing' || allStatus === 'Complete') && (
                          <Grid container spacing={2}>
                            <Grid item xs={6} md={6} sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                              <Typography sx={{ marginRight: 1 }}>
                                <strong>Logistics: </strong>
                              </Typography>
                              <div>
                                {order.logistics_user ? (
                                  <Select
                                    value={{ label: order.logistics_user?.name, value: order.logistics_user?.id }}
                                    styles={customStyles}
                                    isDisabled={true}
                                  />
                                ) : (
                                  <Select
                                    options={logisticsOptions}
                                    styles={customStyles}
                                    onChange={(selectedOption) => {
                                      setSelectedLogistics(selectedOption);
                                    }}
                                  />
                                )}
                              </div>
                              {!order.logistics_user && (
                                <Typography
                                  sx={{ marginLeft: 3, color: '#0458AB', cursor: 'pointer' }}
                                  onClick={() => {
                                    openConfirmationModal('logistics');
                                    setData(order.id, { logistics: selectedLogistics?.value });
                                  }}
                                >
                                  Confirm
                                </Typography>
                              )}
                            </Grid>

                            <Grid item xs={6} md={6} sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                              <Typography sx={{ marginRight: 1 }}>
                                <strong>Warehouse: </strong>
                              </Typography>
                              <div>
                                {order.warehouse_user ? (
                                  <Select
                                    value={{ label: order.warehouse_user?.name, value: order.warehouse_user?.id }}
                                    styles={customStyles}
                                    isDisabled={true}
                                  />
                                ) : (
                                  <Select
                                    options={warehouseOptions}
                                    styles={customStyles}
                                    onChange={(selectedOption) => setSelectedWarehouse(selectedOption)}
                                  />
                                )}
                              </div>
                              {!order.warehouse_user && (
                                <Typography
                                  sx={{ marginLeft: 3, color: '#0458AB', cursor: 'pointer' }}
                                  onClick={() => {
                                    openConfirmationModal('warehouse');
                                    setData(order.id, { warehouse: selectedWarehouse?.value });
                                  }}
                                >
                                  Confirm
                                </Typography>
                              )}
                            </Grid>
                            {order.logistics_user && (
                              <>
                                <VendorStep orderProducts={order} />
                                {order.sub_status && (
                                  <Box sx={{ margin: 2, width: '100%' }}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                        <Typography>
                                          <strong>Truck Arrived Date and Time : </strong>
                                          {formatDate(order.truck_arrival_time)}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Typography>
                                          <strong>Truck empty photo : </strong>
                                          <a
                                            href={order.empty_truck_img}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: 'blue', textDecoration: 'underline' }}
                                          >
                                            View Image
                                          </a>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                        <Typography>
                                          <strong>Transporter Name :</strong> {order.logistics_user?.name}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Typography>
                                          <strong>Vehicle No. </strong>
                                          {order.vehicle_number}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Typography>
                                      <strong>Special instructions :</strong>
                                      {order.special_instructions}
                                    </Typography>
                                  </Box>
                                )}
                              </>
                            )}
                          </Grid>
                        )}
                      </Grid>
                      {order.sub_status === 'Return Requested' && (
                        <Grid md={2}>
                          <Button
                            variant="contained"
                            sx={{ width: 220, my: 3, py: 1, borderRadius: 10 }}
                            onClick={() => openReturnModel(order.id, 'reject')}
                          >
                            Reject return
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ width: 220, my: 3, py: 1, backgroundColor: 'red', borderRadius: 10 }}
                            onClick={() => openReturnModel(order.id, 'confirm', order.return_quantity)}
                          >
                            Confirm return
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ width: 220, my: 3, py: 1, borderRadius: 10 }}
                            onClick={() => openReturnModel(order.id, 'accept')}
                          >
                            Accept Partial return
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                    <Box>
                      {order.sub_status && !(order.sub_status === 'Vehicle Placed') && (
                        <>
                          <Divider sx={{ borderColor: 'black' }} />
                          <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                            <Grid item xs={4}>
                              <Typography>
                                <strong>Truck Loaded Date and Time : {formatDate(order.truck_loaded_time)} </strong>
                              </Typography>
                              <Typography>
                                <a
                                  href={order.weighment_slip_doc}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: 'blue', textDecoration: 'underline' }}
                                >
                                  Photo of weighment slip
                                </a>
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography>
                                <strong>Actual Weight : {order.actual_weight} </strong>
                              </Typography>
                              <Typography>
                                <a
                                  href={order.loaded_truck_img}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: 'blue', textDecoration: 'underline' }}
                                >
                                  Photo of weighment Truck
                                </a>
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                              {order.sub_status === 'Vehicle Loaded' && (
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: '#0458AB',
                                    borderRadius: '20px',
                                    '&:hover': {
                                      backgroundColor: '#034a91',
                                    },
                                    padding: '10px 20px',
                                  }}
                                  onClick={() => handleOpenInvoiceModal(order.id)}
                                >
                                  Upload Invoice Details
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Box>
                    <Box>
                      {order.sub_status && !(order.sub_status === 'Vehicle Placed' || order.sub_status === 'Vehicle Loaded') && (
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '15px',
                            marginTop: '20px',
                          }}
                        >
                          <Box sx={boxStyle} onClick={() => handleOpenInNewTab(order.invoice_bill_doc)}>
                            <Typography variant="body1" sx={{ marginLeft: '18px' }}>
                              Invoice & E-way bill
                            </Typography>
                            <FileDownloadIcon />
                          </Box>

                          <Box sx={boxStyle} onClick={() => handleOpenInNewTab(order.lorry_receipt_doc)}>
                            <Typography variant="body1" sx={{ marginLeft: '18px' }}>
                              Lorry Receipt
                            </Typography>
                            <FileDownloadIcon />
                          </Box>

                          <Box sx={boxStyle} onClick={() => handleOpenInNewTab(order.test_certificate_doc)}>
                            <Typography variant="body1" sx={{ marginLeft: '18px' }}>
                              Test certificate
                            </Typography>
                            <FileDownloadIcon />
                          </Box>
                        </Box>
                      )}
                      {order.sub_status &&
                        !(
                          order.sub_status === 'Vehicle Placed' ||
                          order.sub_status === 'Vehicle Loaded' ||
                          order.sub_status === 'Vehicle Invoiced'
                        ) && (
                          <Box sx={{ display: 'flex', gap: '20px', mt: 3 }}>
                            <Box sx={{ background: '#ACE9ED', padding: 3, borderRadius: 3 }}>
                              <Typography>
                                <strong>Truck Dispatched Date and Time : </strong>
                                {formatDate(order.truck_dispatch_time)}
                              </Typography>
                              <Typography>
                                <strong>GPS Tracking Link : </strong>
                                {order.gps_track_link}
                              </Typography>
                              <Typography>
                                <strong>Truck Driver Mobile No : </strong>
                                {order.transporter_mobile}
                              </Typography>
                              <Typography>
                                <strong>Expected date of Delivery : </strong>
                                {formatDate(order.expected_delivery_date)}
                              </Typography>
                            </Box>
                            {order.sub_status &&
                              !(
                                order.sub_status === 'Vehicle Placed' ||
                                order.sub_status === 'Vehicle Loaded' ||
                                order.sub_status === 'Vehicle Invoiced' ||
                                order.sub_status === 'Shipped'
                              ) && (
                                <Box sx={{ background: '#ACE9ED', padding: 3, borderRadius: 3 }}>
                                  <Typography>
                                    <strong>Material delivery Date and Time : </strong>
                                    {formatDate(order.material_delivery_time)}
                                  </Typography>
                                  <Typography>
                                    <a
                                      href={order.lr_consignee_doc}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: 'blue', textDecoration: 'underline' }}
                                    >
                                      LR consignee copy
                                    </a>
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        )}
                    </Box>
                    <Box>
                      {order.return_quantity && (
                        <Box sx={{ display: 'flex', gap: '20px', mt: 3 }}>
                          <Box sx={{ background: '#ACE9ED', padding: 3, borderRadius: 3 }}>
                            <Typography>
                              <strong>Quantity to be returned : </strong>
                              {order.return_quantity} MT
                            </Typography>
                            <Typography>
                              <strong>Reason for return : </strong>
                              {order.return_reason}
                            </Typography>
                            <Typography>
                              <strong>Details of return : </strong>
                              {order.return_details}
                            </Typography>
                            <Typography>
                              <a
                                href={order.return_product_img}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'blue', textDecoration: 'underline' }}
                              >
                                Document/ image for return
                              </a>
                            </Typography>
                          </Box>
                          {(order.is_return_accepted || order.is_return_rejected) && (
                            <Box sx={{ background: '#ACE9ED', padding: 3, borderRadius: 3 }}>
                              <Typography>
                                <strong>Return {order.is_return_accepted ? 'accepted' : 'rejected'} by BOS</strong>
                              </Typography>
                              <Typography>
                                <strong>Reason for {order.is_return_accepted ? 'accepting' : 'rejecting'} the return : </strong>
                                {order.return_reject_reason}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  </OrderDetails>
                </Grid>
              ))
            ) : (
              <Typography>No orders available</Typography>
            )}
          </Grid>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="confirmation-modal"
        aria-describedby="confirmation-modal-description"
      >
        <Box sx={modalStyleInvices}>
          <Typography id="confirmation-modal" variant="h6" component="h2">
            Confirm Selection
          </Typography>
          <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
            You have selected the name of {confirmData === 'logistics' ? 'Logistics' : 'Warehouse'} for the delivery of this product. The
            name cannot be modified after confirmation. Do you want to proceed with the selected name?
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '20px' }}>
            <Button variant="outlined" onClick={() => setOpenModal(false)} sx={{ borderRadius: '20px', padding: '5px 20px' }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#0458AB',
                borderRadius: '20px',
                padding: '5px 20px',
                '&:hover': {
                  backgroundColor: '#034a91',
                },
              }}
              onClick={handleConfirm}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openInvoiceModal} onClose={handleCloseInvoiceModal}>
        <Box sx={modalStyleInvices}>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '20px' }}>
            Upload invoice documents
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ color: 'blue' }}>
                Invoice & E-way bill
              </Typography>
              <Box
                sx={{
                  marginTop: '14px',
                  width: '95%',
                  display: 'inline-flex',
                  alignItems: 'center',
                  border: '1px solid black',
                  borderRadius: '4px',
                }}
                onClick={handleIconClick}
              >
                <AttachFileIcon />
                <p style={{ marginLeft: '8px' }}>{fileName || 'No file selected'}</p> {/* Display selected file name or default text */}
              </Box>
              <input
                type="file"
                ref={fileInputRef}
                accept=".pdf"
                style={{ display: 'none' }}
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file) {
                    setInvoiceFile(file);
                    setFileName(file.name);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" component="h2" sx={{ color: 'blue' }}>
                Lorry Receipt
              </Typography>
              <Box
                sx={{
                  marginTop: '14px',
                  width: '95%',
                  display: 'inline-flex',
                  alignItems: 'center',
                  border: '1px solid black',
                  borderRadius: '4px',
                }}
                onClick={handleIconClickLorry}
              >
                <AttachFileIcon />
                <p style={{ marginLeft: '8px' }}>{lorryFileName || 'No file selected'}</p>
              </Box>
              <input
                type="file"
                ref={lorryFileInputRef}
                accept=".pdf"
                style={{ display: 'none' }}
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file) {
                    setLorryFile(file);
                    setLorryFileName(file.name);
                  }
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '20px' }}>
            <Button variant="outlined" onClick={handleCloseInvoiceModal} sx={{ borderRadius: '20px', padding: '5px 20px' }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#0458AB',
                borderRadius: '20px',
                padding: '5px 20px',
                '&:hover': {
                  backgroundColor: '#034a91',
                },
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openConfirmModal} onClose={handleCloseConfirmModal}>
        <Box sx={modalStyleInvicesSubmit}>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '20px' }}>
            Are you sure you want to save the details?
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <Button variant="outlined" onClick={handleCloseConfirmModal} sx={{ borderRadius: '20px', padding: '5px 20px' }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#0458AB',
                borderRadius: '20px',
                padding: '5px 20px',
                '&:hover': {
                  backgroundColor: '#034a91',
                },
              }}
              onClick={() => {
                handleCloseConfirmModal();
                handleConfirmSubmit();
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openReturnModal} onClose={() => setOpenReturnModal(false)}>
        <Box sx={modalStyleReturn}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ marginBottom: '20px', textAlign: 'center', fontWeight: 'bold', width: 400, mx: 'auto' }}
          >
            Are you sure you want to Confirm the return the customer will be notified about the confirmation .
          </Typography>
          {returnType === 'accept' && (
            <>
              <Typography variant="h6" component="h2" sx={{ marginBottom: '5px' }}>
                Quantity accepted to be returned
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="return_accept_quantity"
                type="number"
                value={returnData.return_accept_quantity}
                onChange={handleReturnInputChange}
              />
            </>
          )}
          <Typography variant="h6" component="h2" sx={{ my: '5px' }}>
            Reason for {returnType === 'accept' ? 'accepting partial' : returnType === 'reject' ? 'rejecting ' : 'accepting'} return
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="return_reject_reason"
            value={returnData.return_reject_reason}
            onChange={handleReturnInputChange}
          />
          {returnType === 'confirm' && (
            <Typography variant="h6" component="h2" sx={{ marginBottom: '20px' }}>
              Quantity requested for return : {return_quantity} MT.
            </Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 5, mx: 10 }}>
            <Button
              variant="contained"
              // onClick={handleModalConfirm}
              sx={{ textTransform: 'none', borderRadius: '20px', bgcolor: Colors.primary, width: '130px' }}
              onClick={() => submitReturn(returnType)}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenReturnModal(false)}
              sx={{ textTransform: 'none', borderRadius: '20px', width: '130px', color: Colors.primary }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

OrderStatus.propTypes = {
  orderProducts: PropTypes.array,
  orderLocation: PropTypes.string,
  allStatus: PropTypes.string,
  refresh: PropTypes.func,
  formatDate: PropTypes.func,
};

export default OrderStatus;
