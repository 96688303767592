import React from 'react';
import './Result.css';

const Result = () => {
  return (
    <div className="result-container">
      <div>
        <span>Icon</span>
        <span>Products import Successfully !</span>
      </div>
      <p>Imported records</p>
      <div className="btn-container">
        <button>SUccessful</button>
        <button>Failed</button>
      </div>
    </div>
  );
};

export default Result;
