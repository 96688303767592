import { Box } from '@mui/material';
import React from 'react';
import { NotFound } from '../../theme/Images';

const PageNotFound = () => {
  return (
    <Box sx={{ width: '100%', height: '100vh' }}>
      <img style={{ width: '100%', height: '99.2vh' }} src={NotFound} alt="not founf" />
    </Box>
  );
};

export default PageNotFound;
