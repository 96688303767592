import { Box, Grid, styled } from '@mui/material';
import React from 'react';
// import FloatingLabelSelect from '../../../../common-components/select/FloatingLabelSelect';
import Combobox from '../../../../utils/component/combo-box/Combobox';
import PropTypes from 'prop-types';

const ManageItemBox = styled(Box)(() => ({
  height: '70px',
}));

const CallBackEnquiry = ({ enquiryData }) => {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} lgm={6} paddingRight={{ xs: 0, md: '5px' }}>
          <ManageItemBox>
            <Combobox
              label={'Type Of Enquiry'}
              optionsLabel={'enquiry_type'}
              options={[]}
              isDisable={true}
              isRequire={false}
              value={enquiryData.enquiry_type}
            />
          </ManageItemBox>
        </Grid>
        <Grid item xs={12} lgm={6} paddingLeft={{ xs: 0, md: '5px' }}>
          <ManageItemBox>
            <Combobox
              label={'KAM'}
              optionsLabel={'kam_name'}
              options={[]}
              isDisable={true}
              isRequire={false}
              value={enquiryData.firm?.kam?.name}
            />
          </ManageItemBox>
        </Grid>
      </Grid>
    </Box>
  );
};
CallBackEnquiry.propTypes = {
  enquiryData: PropTypes.object,
};
export default CallBackEnquiry;
