import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const RequireAuth = (props) => {
  const { Component } = props;
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  return <>{userData?.user.type === 'Employee' ? <Component /> : <Navigate to="/login" />}</>;
};

RequireAuth.propTypes = {
  Component: PropTypes.func,
};

export default RequireAuth;
