import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FilledButton, OutlineButton } from '../../../utils/component';
import { getData } from '../../../services/APIService';

function createData(name, viewAdmin, addAdmin, editAdmin, deleteAdmin, exportAdmin, importAdmin) {
  return {
    name,
    viewAdmin,
    addAdmin,
    editAdmin,
    deleteAdmin,
    exportAdmin,
    importAdmin,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [checkedStates, setCheckedStates] = React.useState({
    viewAdmin: row.viewAdmin,
    addAdmin: row.addAdmin,
    editAdmin: row.editAdmin,
    deleteAdmin: row.deleteAdmin,
    exportAdmin: row.exportAdmin,
    importAdmin: row.importAdmin,
  });

  const handleCheckboxChange = (key) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ width: '48px' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '200px' }}>
          {row.name}
        </TableCell>
        {['viewAdmin', 'addAdmin', 'editAdmin', 'deleteAdmin', 'exportAdmin', 'importAdmin'].map((key) => (
          <TableCell key={key} align="center" style={{ width: '120px', padding: 0 }}>
            <Checkbox
              checked={checkedStates[key]}
              onChange={() => handleCheckboxChange(key)}
              sx={{
                '&.Mui-checked': {
                  color: '#63A47C',
                },
              }}
            />
          </TableCell>
        ))}
      </TableRow>

      <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }} colSpan={8}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box>
            {row.name === 'User management' && (
              <Table size="small" aria-label="user-management-details">
                <TableBody>
                  {['Add / Edit User', 'BOS Employee', 'Customer', 'Supplier', 'Vendor'].map((item) => (
                    <TableRow key={item} sx={{ padding: 0 }}>
                      {/* Empty cell to align with the expand/collapse icon from the main table */}
                      <TableCell style={{ width: '48px' }} />
                      <TableCell align="left" style={{ width: '200px' }}>
                        {item}
                      </TableCell>
                      {['viewAdmin', 'addAdmin', 'editAdmin', 'deleteAdmin', 'exportAdmin', 'importAdmin'].map((key) => (
                        <TableCell key={key} align="center" style={{ width: '120px' }}>
                          <Checkbox
                            sx={{
                              '&.Mui-checked': {
                                color: '#63A47C',
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {row.name === 'Enquiry management' && (
              <Table size="small" aria-label="user-management-details">
                <TableBody>
                  {['Add / Edit Enquiry'].map((item) => (
                    <TableRow key={item} sx={{ padding: 0 }}>
                      <TableCell style={{ width: '48px' }} />
                      <TableCell align="left" style={{ width: '200px' }}>
                        {item}
                      </TableCell>
                      {['viewAdmin', 'addAdmin', 'editAdmin', 'deleteAdmin', 'exportAdmin', 'importAdmin'].map((key) => (
                        <TableCell key={key} align="center" style={{ width: '120px' }}>
                          <Checkbox
                            sx={{
                              '&.Mui-checked': {
                                color: '#63A47C',
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {row.name === 'Product management' && (
              <Table size="small" aria-label="user-management-details">
                <TableBody>
                  {['Add / Edit Product'].map((item) => (
                    <TableRow key={item} sx={{ padding: 0 }}>
                      <TableCell style={{ width: '48px' }} />
                      <TableCell align="left" style={{ width: '200px' }}>
                        {item}
                      </TableCell>
                      {['viewAdmin', 'addAdmin', 'editAdmin', 'deleteAdmin', 'exportAdmin', 'importAdmin'].map((key) => (
                        <TableCell key={key} align="center" style={{ width: '120px' }}>
                          <Checkbox
                            sx={{
                              '&.Mui-checked': {
                                color: '#63A47C',
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {row.name === 'Order management' && (
              <Table size="small" aria-label="user-management-details">
                <TableBody>
                  {['Add / Edit Order'].map((item) => (
                    <TableRow key={item} sx={{ padding: 0 }}>
                      {/* Empty cell to align with the expand/collapse icon from the main table */}
                      <TableCell style={{ width: '48px' }} />
                      <TableCell align="left" style={{ width: '200px' }}>
                        {item}
                      </TableCell>
                      {['viewAdmin', 'addAdmin', 'editAdmin', 'deleteAdmin', 'exportAdmin', 'importAdmin'].map((key) => (
                        <TableCell key={key} align="center" style={{ width: '120px' }}>
                          <Checkbox
                            sx={{
                              '&.Mui-checked': {
                                color: '#63A47C',
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {row.name === 'Community' && (
              <Table size="small" aria-label="user-management-details">
                <TableBody>
                  {['Add / Edit Community'].map((item) => (
                    <TableRow key={item} sx={{ padding: 0 }}>
                      {/* Empty cell to align with the expand/collapse icon from the main table */}
                      <TableCell style={{ width: '48px' }} />
                      <TableCell align="left" style={{ width: '200px' }}>
                        {item}
                      </TableCell>
                      {['viewAdmin', 'addAdmin', 'editAdmin', 'deleteAdmin', 'exportAdmin', 'importAdmin'].map((key) => (
                        <TableCell key={key} align="center" style={{ width: '120px' }}>
                          <Checkbox
                            sx={{
                              '&.Mui-checked': {
                                color: '#63A47C',
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    viewAdmin: PropTypes.bool.isRequired,
    addAdmin: PropTypes.bool.isRequired,
    editAdmin: PropTypes.bool.isRequired,
    deleteAdmin: PropTypes.bool.isRequired,
    exportAdmin: PropTypes.bool.isRequired,
    importAdmin: PropTypes.bool.isRequired,
  }).isRequired,
};

const rows = [
  createData('User management', true, true, false, true, false, true),
  createData('Enquiry management', true, false, true, false, true, true),
  createData('Product management', false, true, true, true, false, true),
  createData('Order management', true, true, true, false, false, true),
  createData('Community', false, true, false, true, true, false),
  createData('Permission management', true, true, true, true, true, true),
];
const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 5,
};
const UserLevelPermission = () => {
  const [open, setOpen] = useState(false);
  const [allUserList, setAllUserList] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  const fetchUserList = async () => {
    await getData('/users/getAllRoleUsers', {}, userData.token)
      .then((res) => {
        setAllUserList(res.data.data.users);
      })
      .catch((error) => {
        console.error('user roll list', error);
      });
  };
  useEffect(() => {
    const options = allUserList.map((user) => ({
      value: user.user_id,
      label: user.name,
      role: user.role,
    }));
    setUserOptions(options);
  }, [allUserList]);

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleModalOpen = () => {
    setOpen(true);
  };
  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);

    if (selectedOption.role) {
      setSelectedRole({
        value: selectedOption.role.role_id,
        label: selectedOption.role.role_name,
      });
    } else {
      setSelectedRole({
        value: null,
        label: 'Role not assigned',
      });
    }
  };
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body1">User Name:</Typography>
          <Select
            options={userOptions}
            onChange={handleUserChange}
            placeholder="Select a user"
            styles={{ container: (base) => ({ ...base, minWidth: 400 }) }}
          />
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body1">Role:</Typography>
          <Select
            value={selectedRole}
            isDisabled={!selectedUser} // Disable if no user selected
            placeholder="Select a role"
            styles={{ container: (base) => ({ ...base, minWidth: 400 }) }}
            isClearable
            isSearchable={false}
          />
        </Box>
      </Box>
      <Box mt={5}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }}>
                <TableCell style={{ width: '48px' }} />
                <TableCell style={{ width: '200px', color: 'white' }}>Permission</TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                  View
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                  Add
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                  Edit
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                  Delete
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                  Export
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px' }}>
                  Import
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        width={{ xs: '65vw', sm: '30vw' }}
        sx={{
          margin: '0px auto',
          marginTop: '30px',
          display: 'flex',
          gap: '25px',
        }}
      >
        <FilledButton text={'Save'} onClick={handleModalOpen} />
        <OutlineButton text={'Cancel'} />
      </Box>
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={Modalstyle}>
          <CloseRoundedIcon sx={{ float: 'right' }} onClick={() => setOpen(false)} />
          <Box width={'100%'}>
            <Typography mt={5} style={{ textAlign: 'center' }}>
              Are you sure you want to make changes to permissions.
            </Typography>
            <Box
              width={'18.8vw'}
              sx={{
                margin: 'auto',
                marginTop: '35px',
                marginBottom: '10px',
                display: 'flex',
                gap: '25px',
              }}
            >
              <FilledButton text={'Confirm'} />
              <OutlineButton text={'Cancel'} onClick={() => setOpen(false)} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserLevelPermission;
