import { Box, Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Heading24Semi } from '../../../../common-components';
import { Edit, Delete } from '../../../../theme/Images';
import { CustomDataGrid, Heading16Reg } from '../../../../utils/component';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postData } from '../../../../services/APIService';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import Colors from '../../../../theme/colors/Color';
import { getorderStatusBgColor } from '../../../../utils/constants/orderStatusColor';

const DeleteModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 4,
};

const limit = 10;
let totalPages = 1;

const OrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allOrders, setAllOrders] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [deleteWarning, setDeleteWarning] = useState(false);
  const [page, setCurrentPage] = useState(1);

  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const firmData = JSON.parse(localStorage.getItem('firmData'));
  let firm_id = firmData?.firmId;

  useEffect(() => {
    fetchOrders();
  }, [page]);
  const fetchOrders = async () => {
    try {
      const res = await postData(`/order/list`, { page, limit, filters: { firm_id: [firm_id] } }, {}, userData.token);
      if (res && res.data && res.data.status) {
        totalPages = res.data.data.totalPages;
        setAllOrders(res.data.data.orders);
      } else {
        totalPages = 1;
        setAllOrders([]);
        dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
      }
    } catch (error) {
      totalPages = 1;
      setAllOrders([]);
      console.log(error);
    }
  };

  const columnsData = [
    {
      field: 'order_number',
      headerName: 'Order No',
      flex: 1.3,
      renderCell: (params) => {
        const { order_id } = params.row;
        const viewDetails = () => {
          navigate('/order/details', { state: { id: order_id, mode: 'view' } });
        };
        return (
          <Box
            sx={{
              color: Colors.primary,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={viewDetails}
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: 'order_user',
      headerName: 'Customer name',
      flex: 0.7,
      valueGetter: (params) => (params ? params.name : ''),
    },
    { field: 'createdAt', headerName: 'Date of Order', flex: 0.7, valueGetter: (p) => new Date(p).toLocaleDateString() },
    {
      field: 'order_location',
      headerName: 'Delivery location',
      flex: 1,
      valueGetter: (params) => (params ? params.city : ''),
    },
    {
      field: 'payment_terms',
      headerName: 'Payment terms',
      flex: 0.71,
      valueGetter: (params) => (params ? params.payment_terms : ''),
      renderCell: (params) => {
        const { credit_term, payment_option } = params.row;
        return credit_term.credit_term == 0
          ? payment_option.payment_option
          : `${credit_term.credit_term} days ${payment_option.payment_option}`;
      },
    },
    {
      field: 'fulfilment_type',
      headerName: 'Fulfilment type',
      flex: 1.49,
      // valueGetter: (params) => {
      //   if (params) return params.address_line_1 + ', ' + params.address_line_2 + ', ' + params.city + ', ' + params.state;
      // },
    },
    {
      field: 'grant_total',
      headerName: 'Grand total',
      flex: 0.7,
      // valueGetter: (params) => (params ? params.mill_name : ''),
      // renderCell: (params) => {
      //   return <Box sx={{ color: params.row.mill.is_external ? Colors.error : 'inherit' }}>{params.value}</Box>;
      // },
    },
    {
      field: 'payment_entry_date',
      headerName: 'Payment details entry',
      flex: 0.7,
      valueGetter: (p) => p && new Date(p).toLocaleDateString(),
    },
    {
      field: 'order_acceptance_date',
      headerName: 'Order acceptance',
      flex: 0.7,
      valueGetter: (p) => p && new Date(p).toLocaleDateString(),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.1,
      renderCell: (params) => {
        const status = params.value;
        return (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                background: getorderStatusBgColor(status),
                height: '25px',
                width: '80px',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: '1px 20px',
              }}
            >
              {status}
            </Box>
          </Box>
        );
      },
    },
    {
      headerName: 'Edit',
      width: 51,
      renderCell: ({ id }) => {
        const handleEdit = () => {
          navigate('/order/details', { state: { id, mode: 'edit' } });
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
    },
  ];

  const handleDelete = async () => {
    try {
      const res = await postData('/order/delete', { id: selectedRows }, {}, userData.token);
      if (res && res && res.data.status) {
        setDeleteWarning(false);
        fetchOrders();
        dispatch(showSnackbar({ message: 'Order deleted successfully', severity: 'success' }));
        // setModalOpen(false);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
    setSelectedRows([]);
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Heading24Semi text={'Order Details'} />
        <img onClick={() => setDeleteWarning(true)} src={Delete} alt="filter" style={{ height: '25px', width: '25px' }} />
      </Box>
      <CustomDataGrid rowIdKey={'order_id'} columnsData={columnsData} rowsData={allOrders} onSelectCheckbox={(r) => setSelectedRows(r)} />
      <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteModalstyle}>
          {selectedRows?.length ? (
            <>
              <Heading16Reg text="Do you want to Delete Order Permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDelete}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select Order first." />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default OrderDetails;
