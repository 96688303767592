import {
  Divider,
  FormControl,
  FormLabel,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Heading20Semi } from '../../../../utils/component';
import Colors from '../../../../theme/colors/Color';
import PropTypes from 'prop-types';
import { getData } from '../../../../services/APIService';

const CustomPaper = styled(Paper)(() => ({
  padding: '28px 9px',
}));

const QuantityChangeHistory = ({ product_id }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getData(`product/quantity_change_history/${product_id}`);
        if (response.data.status) {
          setHistory(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [product_id]);

  return (
    <CustomPaper elevation={5} sx={{ borderRadius: '10px', height: { xs: '50vh', md: '530px' } }}>
      <FormControl>
        <FormLabel>
          <Heading20Semi text={'Quantity Change History'} />
        </FormLabel>
      </FormControl>
      <Divider sx={{ marginTop: '10px' }} />
      <TableContainer
        sx={{
          maxHeight: '100%',
          height: '96%',
          overflowY: 'auto',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            width: '12px',
            height: '12px',
            cursor: 'pointer',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: Colors.secondary1,
            borderRadius: '6px',
            border: '3px solid transparent',
            backgroundClip: 'padding-box',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: Colors.primary,
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow style={{ whiteSpace: 'nowrap' }}>
              <TableCell>Date & Time</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Modified Quantity</TableCell>
              <TableCell>Total Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((row) => (
              <TableRow key={row.id} style={{ whiteSpace: 'nowrap', margin: -10 }}>
                <TableCell>
                  {new Date(row.createdAt).toLocaleString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </TableCell>
                <TableCell>{row.action}</TableCell>
                <TableCell>{row.modified_qty}</TableCell>
                <TableCell>{row.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomPaper>
  );
};

QuantityChangeHistory.propTypes = {
  product_id: PropTypes.string,
};

export default QuantityChangeHistory;
