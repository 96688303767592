import React, { useEffect, useState } from 'react';
import {
  Grid,
  styled,
  Box,
  Typography,
  Divider,
  FormLabel,
  Paper,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Modal,
} from '@mui/material';
import { Heading20Semi, Heading26Bold } from '../../../../common-components';
import Heading16Bold from '../../../../common-components/headings/Heading16Bold';
import Colors from '../../../../theme/colors/Color';
import OrderStages from './OrderStages';
import { getData, updateData } from '../../../../services/APIService';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import PropTypes from 'prop-types';
import Comment from '../../../../utils/component/comment/Comment';
import { PaymentDetails } from './PaymentDetails';

const userData = JSON.parse(localStorage.getItem('userDetails'));
const CustomPaper = styled(Paper)(() => ({
  padding: '28px 9px',
}));
const InfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(1.5),
  },
}));
const Container = styled(Box)(({ theme }) => ({
  backgroundColor: '#00C9D84D',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginTop: theme.spacing(2),
}));
const Container2 = styled(Box)(({ theme }) => ({
  backgroundColor: ' #0458AB',
  color: 'white',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginTop: theme.spacing(2),
}));

const DetailsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0px 4px 4px 0px #00000040',
  border: '0.5px solid #000',
  marginTop: theme.spacing(2),
  width: '100%',
}));

const PriceDetailsRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(0.5),
}));

const DetailsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
const getModalStyle = (size) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: size === 'xl' ? '80%' : size === 'lg' ? '60%' : size === 'md' ? '40%' : '30%', // Sizes for different options
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
});
const OrderMgtDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const order_id = state?.id;
  const [orderinfo, setOrderInfo] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [generateOA, setGenerateOA] = useState();
  const orderLocation = orderinfo?.order_location?.city;
  const allStatus = orderinfo?.status;
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [size, setSize] = useState('md');

  const handleOpen = (modalSize) => {
    setSize(modalSize);
    setOpen(true);
    getGenerateOA();
  };
  const getGenerateOA = async () => {
    try {
      const res = await getData(`/order/generateOA/${state.id}`, {}, userData.token);
      if (res && res.data && res.data.status) {
        setGenerateOA(res.data.data);
      } else dispatch(showSnackbar({ message: '"Something went wrong"', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };
  const handleClose = () => setOpen(false);

  const handleConfirmOpen = () => {
    setOpen(false);
    setConfirmOpen(true);
  };
  const handleConfirmClose = () => setConfirmOpen(false);

  const handleConfirmSubmit = async () => {
    await changeOrderStatus();
    fetchOrderInfo();
    setConfirmOpen(false);
  };
  useEffect(() => {
    fetchOrderInfo();
    fetchOrderHistory();
  }, []);
  const fetchOrderInfo = async () => {
    try {
      const res = await getData(`/order/details/${state.id}`, {}, userData.token);
      if (res && res.data && res.data.status) {
        setOrderInfo(res.data.data);
      } else dispatch(showSnackbar({ message: '"Something went wrong"', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };
  const fetchOrderHistory = async () => {
    try {
      const res = await getData(`/order/status_history/${state.id}`, {}, userData.token);
      if (res && res.data && res.data.status) {
        setOrderHistory(res.data.data);
      } else dispatch(showSnackbar({ message: '"Something went wrong"', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };
  const changeOrderStatus = async () => {
    try {
      const res = await updateData(
        `/payment/${state.id}`, // Use the order_id from state
        { is_oa_generated: true }, // Pass the payload
        {},
        userData.token // Include the user's token
      );

      if (res && res.data && res.data.status) {
        dispatch(showSnackbar({ message: 'Order acceptance generated successfully', severity: 'success' }));
      } else {
        dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      } else {
        dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
      }
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format date to '14 Feb 2024'
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    // Format time to '12:30 PM'
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return `${formattedDate}, ${formattedTime}`;
  };
  return (
    <>
      <Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="h5" component="h1" sx={{ flexGrow: 1, typography: { xs: 'h6', sm: 'h5' } }}>
            <Heading26Bold text={'Order Details'} />
          </Typography>
          {allStatus === 'Awaiting' && (
            <Button
              variant="outlined"
              onClick={() => handleOpen('lg')}
              sx={{
                backgroundColor: 'white',
                borderRadius: '50px',
                border: '1px solid black',
                color: 'black',
                padding: '8px 20px',
                textTransform: 'none',
              }}
            >
              Generate OA
            </Button>
          )}
          {(allStatus === 'Generated' || allStatus === 'Processing' || allStatus === 'Accepted' || allStatus === 'Complete') && (
            <Typography
              variant="body2"
              sx={{
                typography: { xs: 'body2', sm: 'body2' },
                color: 'blue',
                cursor: 'pointer',
              }}
              onClick={() => handleOpen('lg')}
            >
              View Order Acceptance
            </Typography>
          )}
        </Box>
        <Container>
          <InfoBox>
            <Typography variant="body2" sx={{ typography: { xs: 'body2', sm: 'body2' } }}>
              <strong>Order No.:</strong> {orderinfo?.order_number}
            </Typography>
            <Typography variant="body2" sx={{ typography: { xs: 'body2', sm: 'body2' } }}>
              <strong>Customer Name:</strong> {orderinfo?.order_user?.name}
            </Typography>
          </InfoBox>
          <InfoBox>
            <Typography variant="body2" sx={{ typography: { xs: 'body2', sm: 'body2' } }}>
              <strong>Date Of Order:</strong> {new Date(orderinfo?.createdAt).toLocaleDateString()}
            </Typography>
            <Typography variant="body2" sx={{ typography: { xs: 'body2', sm: 'body2' } }}>
              <strong>Available Credit Limit: </strong>
              {orderinfo?.order_firm?.credit_limit || 0}
            </Typography>
          </InfoBox>
          <InfoBox>
            <Typography variant="body2" sx={{ typography: { xs: 'body2', sm: 'body2' } }}>
              <strong>No.Of Product:</strong> {orderinfo?.product_quantity}
            </Typography>
            <Typography variant="body2" sx={{ typography: { xs: 'body2', sm: 'body2' } }}>
              <strong>Payment Terms: </strong>
              {orderinfo?.credit_term?.credit_term == 0
                ? orderinfo?.payment_option?.payment_option
                : `${orderinfo?.credit_term?.credit_term} days ${orderinfo?.payment_option?.payment_option}`}
            </Typography>
          </InfoBox>
          <InfoBox>
            <Typography variant="body2" sx={{ typography: { xs: 'body2', sm: 'body2' } }}>
              <strong style={{ fontSize: '14px' }}>Grand Total {orderinfo?.grant_total} ₹</strong>
            </Typography>
            <Typography variant="body2" sx={{ typography: { xs: 'body2', sm: 'body2' } }}>
              <strong>Fulfilment Type:</strong> {orderinfo?.fulfilment_type}
            </Typography>
          </InfoBox>
        </Container>
        <DetailsWrapper>
          <DetailsContainer>
            <Typography variant="subtitle1" gutterBottom>
              <Heading16Bold text={'Price Details:'} />
            </Typography>
            <PriceDetailsRow>
              <Typography variant="body2">Total price</Typography>
              <Typography variant="body2">{orderinfo?.total_price} ₹</Typography>
            </PriceDetailsRow>
            {orderinfo.order_products &&
              orderinfo.order_products.map((product, index) => (
                <PriceDetailsRow key={index}>
                  <Typography variant="body2">Freight from {product.product.addressLocation.city}</Typography>
                  <Typography variant="body2"> {product.freight_rate || '-'} ₹</Typography>
                </PriceDetailsRow>
              ))}
            <PriceDetailsRow>
              <Typography variant="body2">GST 18% (Price + Freight)</Typography>
              <Typography variant="body2" color="green">
                {orderinfo?.gst} ₹
              </Typography>
            </PriceDetailsRow>
            <Divider sx={{ margin: '10px 0', borderColor: Colors.text_black }} />
            <PriceDetailsRow>
              <Typography variant="subtitle1" gutterBottom>
                <Heading20Semi text={'Grand Total:'} />
              </Typography>
              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                {orderinfo?.grant_total} ₹
              </Typography>
            </PriceDetailsRow>
            <Divider sx={{ margin: '5px 0', borderColor: Colors.text_black }} />
          </DetailsContainer>
          <DetailsContainer>
            <PaymentDetails orderinfo={orderinfo} order_id={order_id} refresh={fetchOrderInfo} />
          </DetailsContainer>
        </DetailsWrapper>
        <OrderStages
          formatDate={formatDate}
          orderProducts={orderinfo?.order_products}
          orderLocation={orderLocation}
          allStatus={allStatus}
          refresh={fetchOrderInfo}
        />
      </Grid>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Comment module_id={order_id} comment_type={'order'} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomPaper elevation={5} sx={{ borderRadius: '10px', height: { xs: '  50vh', md: '55vh' } }}>
              <FormControl>
                <FormLabel>
                  <Heading20Semi text={'Order Status History'} />
                </FormLabel>
              </FormControl>
              <Divider sx={{ marginTop: '10px' }} />
              <TableContainer
                sx={{
                  maxHeight: '100%',
                  height: '96%',
                  overflowY: 'auto',
                  overflowX: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '12px',
                    height: '12px',
                    cursor: 'pointer',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: Colors.secondary1,
                    borderRadius: '6px',
                    border: '3px solid transparent',
                    backgroundClip: 'padding-box',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: Colors.primary,
                  },
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow style={{ whiteSpace: 'nowrap' }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Perticuler</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Date & Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderHistory.map((row, index) => (
                      <TableRow key={index} style={{ whiteSpace: 'nowrap' }}>
                        <TableCell>{row.particulars}</TableCell>
                        <TableCell>{formatDate(row.createdAt)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomPaper>
          </Grid>
        </Grid>
      </Box>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={getModalStyle(size)}>
          <Container2>
            {generateOA ? (
              <iframe src={generateOA} width="100%" height="600px" style={{ border: 'none' }} title="Order Acceptance PDF"></iframe>
            ) : (
              <p>Loading PDF...</p>
            )}
          </Container2>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button onClick={handleClose} sx={{ mr: 2 }} variant="outlined">
              Cancel
            </Button>
            {!(allStatus === 'Generated' || allStatus === 'Processing' || allStatus === 'Accepted' || allStatus === 'Complete') && (
              <Button onClick={handleConfirmOpen} variant="contained" color="primary">
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      <Modal
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="confirm-modal-title" variant="h6" component="h2">
            Confirm
          </Typography>
          <Typography id="confirm-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to generate order acceptance?
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button onClick={handleConfirmClose} sx={{ mr: 2 }} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirmSubmit} variant="contained" color="primary">
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
OrderMgtDetails.propTypes = {
  orderProducts: PropTypes.array,
  orderLocation: PropTypes.string,
  allStatus: PropTypes.string,
  order_id: PropTypes.string,
  orderinfo: PropTypes.array,
  formatDate: PropTypes.func,
};
export default OrderMgtDetails;
