import { Box, styled } from '@mui/material';
import React, { memo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Colors from '../../../theme/colors/Color';
import PropTypes from 'prop-types';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const StyledAutocomplete = styled(Autocomplete, { shouldForwardProp: (prop) => prop !== 'isExternal' })(({ isExternal = false }) => ({
  fontFamily: 'poppins-regular',
  '& .MuiOutlinedInput-root': {
    fontFamily: 'poppins-regular',
    height: '45px',
    color: isExternal ? 'red' : 'inherit',
  },
  '& .MuiInputLabel-root': {
    color: isExternal ? 'red' : 'inherit',
  },
}));

const StyledTextField = styled(TextField, { shouldForwardProp: (prop) => prop !== 'isExternal' && prop !== 'isDisable' })(
  ({ isExternal, isDisable }) => ({
    '& .MuiInputBase-root': {
      fontFamily: 'poppins-regular',
      '& .MuiOutlinedInput-input': {
        padding: '2px 10px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: isExternal && isDisable ? 'red' : 'inherit',
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: isExternal ? 'red' : 'rgba(0, 0, 0, 0.38)',
      },
    },
    '& .MuiInputLabel-root': {
      top: '-5px',
      fontFamily: 'poppins-regular',
      '&.Mui-focused': {
        color: Colors.text_413e3e,
      },
    },
    '& .MuiFormLabel-asterisk': {
      color: Colors.error,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  })
);

const Combobox = ({ isMultiSelect = false, disableOption, optionsLabel, ...props }) => {
  const handleSelect = (event, newValue) => {
    props.onChangeValue(newValue);
  };

  return (
    <Box>
      <StyledAutocomplete
        multiple={isMultiSelect}
        disablePortal
        fullWidth
        sx={{ background: props.bgColor || Colors.transparent }}
        id="combo-box-demo"
        getOptionLabel={(option) => option[optionsLabel] || ''}
        options={props.options || []}
        disabled={props.isDisable}
        value={props.isMultiSelect ? props.value : { [optionsLabel]: props.value }}
        onChange={handleSelect}
        renderInput={(params) => (
          <StyledTextField
            required={props.isRequire}
            {...params}
            label={props.label}
            variant="outlined"
            autoComplete="off"
            isExternal={props.isExternal}
            isDisable={props.isDisable}
          />
        )}
        popupIcon={<ExpandMoreRoundedIcon />}
        isOptionEqualToValue={(option, value) => option[optionsLabel] === value[optionsLabel]}
        renderOption={(listItemProps, option) => {
          const isDisabled = disableOption && disableOption(option);
          return (
            <li
              {...listItemProps}
              style={{
                pointerEvents: isDisabled ? 'none' : 'auto',
                opacity: isDisabled ? 0.5 : 1,
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
              aria-disabled={isDisabled ? 'true' : undefined}
            >
              {option[optionsLabel]}
            </li>
          );
        }}
      />
    </Box>
  );
};

Combobox.propTypes = {
  label: PropTypes.string,
  optionsLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object)]),
  options: PropTypes.array,
  onChangeValue: PropTypes.func,
  isRequire: PropTypes.bool,
  isDisable: PropTypes.bool,
  bgColor: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  isExternal: PropTypes.bool,
  disableOption: PropTypes.func,
};

export default memo(Combobox);
