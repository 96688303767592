import { Autocomplete, Box, styled, TextField } from '@mui/material';
import React from 'react';
import Colors from '../../../theme/colors/Color';
import PropTypes from 'prop-types';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

/* const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
]; */

const StyledAutocomplete = styled(Autocomplete)(() => ({
  fontFamily: 'poppins-regular',
  '& .MuiOutlinedInput-root': {
    fontFamily: 'poppins-regular',
    borderRadius: '15px',
    background: Colors.bg_filterItem,
  },
}));

const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    minHeight: '45px',
    fontFamily: 'poppins-regular',
    '& .MuiOutlinedInput-input': {
      padding: '2px 10px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid',
      borderColor: Colors.text_413e3e,
    },
  },
  '& .MuiInputLabel-root': {
    top: '-5px',
    fontFamily: 'poppins-regular',
    '&.Mui-focused': {
      color: Colors.text_413e3e,
    },
  },
  '& .MuiFormLabel-asterisk': {
    color: Colors.error,
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
}));

const MultiValCombobox = (props) => {
  return (
    <Box>
      <StyledAutocomplete
        multiple
        fullWidth
        id="tags-outlined"
        options={props.options}
        disableCloseOnSelect
        getOptionLabel={(option) => option[props.optionsLabel] || ''}
        filterSelectedOptions
        value={props.value}
        onChange={(e, value) => props.onChangeValue(value)}
        popupIcon={<ExpandMoreRoundedIcon />}
        renderInput={(params) => <StyledTextField {...params} placeholder="Type/Select Name Of Customer" />}
      />
    </Box>
  );
};

MultiValCombobox.propTypes = {
  label: PropTypes.string,
  optionsLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object)]),
  options: PropTypes.array,
  onChangeValue: PropTypes.func,
  isRequire: PropTypes.bool,
  isDisable: PropTypes.bool,
  bgColor: PropTypes.string,
  isMultiSelect: PropTypes.bool,
};

export default MultiValCombobox;
