import { configureStore } from '@reduxjs/toolkit';
import PM_MastersReducer from '../features/masters/PM_MastersSlice';
import firmReducer from '../features/user management/firmSlice';
import snackbarReducer from '../features/snackbar/snackbarSlice';
import supplierListReducer from '../features/user management/supplierListSlice';
import kamListReducer from '../features/user management/kamListSlice';
import enquiryReasonsReducer from '../features/masters/enquiryMasterSlice';

export const store = configureStore({
  reducer: {
    PM_Masters: PM_MastersReducer,
    firm: firmReducer,
    snackbar: snackbarReducer,
    suppliers: supplierListReducer,
    kam: kamListReducer,
    enquiryReasons: enquiryReasonsReducer,
  },
});
