import { Box, Divider, FormControl, FormLabel, InputAdornment, Switch, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Paper, IconButton, TextField, List, ListItem, ListItemText, ListItemSecondaryAction, Button } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import Colors from '../../../../theme/colors/Color';
import { Heading20Semi, Heading26Bold } from '../../../../common-components';
import 'react-quill/dist/quill.snow.css'; // import styles for react-quill
import ReactQuill from 'react-quill';
import { Delete, Search } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { getData } from '../../../../services/APIService';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';

const CustomPaper = styled(Paper)(() => ({
  padding: '28px 9px',
}));

const members = ['Rohan Deshpande', 'Rupa Deshpande', 'Rucha Doshi', 'Sanjay Doshi', 'Paresh Mane', 'Rupa Deshpande', 'Rohan Deshpande'];
const toolbarOptions = [
  [{ font: [] }],
  [{ header: [1, 2, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ['link', 'image'],
  ['clean'],
  ['undo', 'redo'],
];

const EditCommunity = () => {
  const [value, setValue] = useState('');
  const [communityDetails, setCommunityDetails] = useState([]);
  console.log('communityDetails', communityDetails);
  const location = useLocation();
  const { state } = location;

  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  const fetchDetailsCommunity = async () => {
    try {
      const res = await getData(`/community/communitydetails/${state.id}`, {}, userData.token);
      if (res && res.data && res.data.status) {
        console.log('res.data', res.data);
        setCommunityDetails(res.data.data);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      if (error?.response?.status === 404);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDetailsCommunity();
  }, []);
  // const createPost = async () => {
  //   try {
  //     const res = await postData(`/community/addCommunityPost`, { page, limit, filters, keyword }, {}, userData.token);
  //     if (res && res.data && res.data.status) {
  //     } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
  //   } catch (error) {
  //     if (error?.response?.status === 404) setAllOrders([]);
  //     console.log(error);
  //   }
  // };
  return (
    <>
      <Heading26Bold text={'Create Community'} />
      <Box
        style={{
          backgroundColor: '#b3faff',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '20px',
          paddingRight: '10px',
          alignItems: 'center',
        }}
        sx={{
          height: {
            lg: '50px', // large screens
            xl: '6vh', // extra-large screens
          },
          width: {
            lg: '97%', // large screens
            xl: '98%', // extra-large screens
          },
        }}
      >
        <Typography
          sx={{
            fontSize: {
              lg: '14px',
              xl: '16px',
            },
          }}
        >
          <strong>Community Number:</strong> {communityDetails.community_no}
        </Typography>
        <Typography
          sx={{
            fontSize: {
              lg: '14px',
              xl: '16px',
            },
          }}
        >
          <strong>Date Of Creation:</strong>
          {new Date(communityDetails.createdAt).toLocaleString()}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'white',
            width: '40%',
            height: '32px',

            padding: {
              xs: '120px',
              lg: '5px',
              xl: '5px',
            },
          }}
        >
          <Typography
            style={{ fontWeight: 'bold' }}
            sx={{
              fontSize: {
                lg: '14px',
                xl: '16px',
              },
            }}
          >
            <span>
              <span style={{ color: '#9C9A9A' }}>Realm</span>
              <span style={{ color: 'red' }}> * </span>
            </span>
          </Typography>
          <select
            style={{
              minWidth: '200px',
              padding: '8px',
              fontSize: '14px',
              borderRadius: '4px',
              border: 'none',
            }}
            value={communityDetails.realm}
            disabled
          >
            <option value="" disabled>
              Select Realm
            </option>
            <option value="segment">Segment</option>
            <option value="category">Category</option>
          </select>
          <Typography
            style={{ margin: '4px', fontWeight: 'bold' }}
            sx={{
              fontSize: {
                lg: '14px',
                xl: '16px',
              },
            }}
          >
            <span>
              <span style={{ color: '#9C9A9A' }}>Select</span>
              <span style={{ color: 'red' }}> * </span>
              <span style={{ color: '#9C9A9A' }}> : </span>
            </span>
          </Typography>
          <select
            style={{
              minWidth: '200px',
              padding: '8px',
              fontSize: '14px',
              borderRadius: '4px',
              border: 'none',
            }}
            value={communityDetails.realm_select}
            disabled
          >
            <option value="" disabled>
              Select Realm
            </option>
            <option value="segment">Segment</option>
            <option value="category">Category</option>
          </select>
        </Box>
      </Box>
      <Box sx={{ marginTop: '30px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <CustomPaper elevation={5} sx={{ borderRadius: '10px', height: { md: '43vh', xs: '40vh' } }}>
              <FormLabel>
                <Heading20Semi text={'Post Messages '} />
              </FormLabel>
              <TextField variant="standard" placeholder="Add A Subject" size="small" fullWidth sx={{ marginTop: '10px' }} />
              <Box mt={1}>
                <Box
                  sx={{
                    height: {
                      xs: '120px', // small screens
                      lg: '128px', // large screens
                      xl: '24vh', // extra-large screens
                    },
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px',
                    overflow: 'hidden', // Ensure the content fits within the box
                  }}
                >
                  <ReactQuill
                    fullWidth
                    value={value}
                    onChange={setValue}
                    placeholder="Type A Message"
                    backgroundColor="red"
                    modules={{ toolbar: toolbarOptions }}
                    style={{ height: '100%' }}
                  />
                </Box>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    top: '10px',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Divider
                    sx={{
                      width: '100%',
                      borderBottom: '2px solid',
                      borderColor: Colors.primary,
                      height: {
                        xl: '5vh',
                      },
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <IconButton component="span" sx={{ fontWeight: 'bold' }}>
                      <AttachFileIcon sx={{ fontWeight: 'bold', color: Colors.text_black }} />
                    </IconButton>
                    <IconButton sx={{ color: Colors.primary }}>
                      <SendIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </CustomPaper>
          </Grid>

          <Grid item xs={12} lgm={4}>
            <CustomPaper elevation={5} sx={{ borderRadius: '10px', height: { md: '43vh' } }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <FormLabel>
                  <Heading20Semi text={'Community Details '} />
                </FormLabel>
                <Switch size="medium" />
              </Box>
              <Box sx={{ marginTop: 1 }}>
                <label>Community Name</label>
                <TextField
                  sx={{
                    height: { md: '5vh' },
                    '& .MuiInputBase-root': {
                      height: { xl: '10vh' },
                      alignItems: 'flex-start',
                    },
                  }}
                  placeholder="Name of Community"
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  value={communityDetails.community_name}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                />
              </Box>

              <Box sx={{ marginTop: 1 }}>
                <label>User Type</label>
                <TextField
                  sx={{
                    height: { md: '5vh' },
                    '& .MuiInputBase-root': {
                      height: { xl: '10vh' },
                      alignItems: 'flex-start',
                    },
                  }}
                  placeholder="Name of Community"
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  value={communityDetails.user_type} // Set the value to community_name
                  InputProps={{
                    readOnly: true, // Make the field read-only to prevent editing
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                />
              </Box>
              <Box sx={{ marginTop: 1 }}>
                <label>Community Description</label>
                <TextField
                  sx={{
                    height: { md: '5vh' },
                    '& .MuiInputBase-root': {
                      height: { lg: '17vh', xl: '20vh' },
                      alignItems: 'flex-start',
                    },
                  }}
                  placeholder="Name of Community"
                  variant="outlined"
                  fullWidth
                  size="small"
                  margin="dense"
                  value={communityDetails.description} // Set the value to community_name
                  InputProps={{
                    readOnly: true, // Make the field read-only to prevent editing
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  multiline
                  rows={5}
                />
              </Box>
            </CustomPaper>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} lg={8}>
            <CustomPaper
              elevation={5}
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                maxHeight: '54vh',
                '&::-webkit-scrollbar': {
                  width: '12px',
                  height: '11px',
                  cursor: 'pointer',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Colors.secondary1,
                  borderRadius: '6px',
                  border: '3px solid transparent',
                  backgroundClip: 'padding-box',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: Colors.primary,
                },
              }}
            >
              <FormControl>
                <FormLabel>
                  <Heading20Semi text="Previous Messages" />
                </FormLabel>
              </FormControl>
              {communityDetails.posts.map((post) => (
                <Paper
                  key={post.post_id} // Use post_id as a unique key
                  elevation={3}
                  style={{
                    margin: '10px 0',
                    padding: '10px',
                    borderBottom: '1px solid #ccc',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4>Subject : {post.subject}</h4> {/* Display post subject */}
                    <p>
                      <strong>Posted On:</strong> {new Date(post.createdAt).toLocaleString()}
                    </p>
                  </div>
                  <Divider
                    sx={{
                      width: '100%',
                      borderBottom: '2px solid #ccc',
                      borderColor: Colors.primary,
                    }}
                  />
                  <p dangerouslySetInnerHTML={{ __html: post.message }} />
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button variant="text" style={{ color: Colors.primary }}>
                      {post.published ? 'Unpublish' : 'Publish'}
                    </Button>
                    <IconButton>
                      <Delete />
                    </IconButton>
                  </div>
                </Paper>
              ))}
            </CustomPaper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomPaper
              elevation={5}
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                maxHeight: '54vh',
                '&::-webkit-scrollbar': {
                  width: '12px',
                  height: '11px',
                  cursor: 'pointer',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Colors.secondary1,
                  borderRadius: '6px',
                  border: '3px solid transparent',
                  backgroundClip: 'padding-box',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: Colors.primary,
                },
              }}
            >
              <FormControl>
                <FormLabel>
                  <Heading20Semi text="Add/Remove Members" />
                </FormLabel>
              </FormControl>
              <Box sx={{ marginTop: 2 }}>
                <label>Add new member</label>
                <TextField placeholder="Combo" variant="outlined" size="small" fullWidth style={{ marginTop: '10px' }} />
              </Box>
              <Box sx={{ marginTop: 2 }}>
                <label>Add new member</label>
                <TextField
                  placeholder="Search Members"
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{ marginTop: '10px' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <List>
                {members.map((member, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={member} />
                    <ListItemSecondaryAction>
                      <Button variant="contained" color="error">
                        Remove
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CustomPaper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditCommunity;
