import ColorCode from './ColorCode';

const Colors = {
  primary: ColorCode.blue,
  secondary1: ColorCode.light_blue,
  secondary2: ColorCode.sky_blue,
  tersary: ColorCode.cyan,
  form_lines: ColorCode.light_grey,
  success: ColorCode.dark_green,
  error: ColorCode.red,
  warning: ColorCode.dark_gold,
  inprogress: ColorCode.yellowish,
  awaiting: ColorCode.dark_yellowish,
  information: ColorCode.electric_blue,
  placeholder: ColorCode.light_grey,
  bottom_bar_icon: ColorCode.dark_grey,
  bg_strip: ColorCode.cyan30,
  bg_grey: ColorCode.bg_light_grey,
  secondary_text: ColorCode.dark_grey,
  left_pannel: ColorCode.cyan,
  text_413e3e: ColorCode.light_black,
  text_black: ColorCode.black,
  white: ColorCode.white,
  transparent: ColorCode.transparent,
  heading: ColorCode.gunmetal,
  bg_filterItem: ColorCode.grey_lte,
  scrollbar: ColorCode.scroll,
  status_gnrtd: ColorCode.sepia_brown,
  select_bg: ColorCode.white_lotion,
  bg_form_section: ColorCode.sky_blue30,
  highlightRow: ColorCode.highlight,
  proceesingDark: ColorCode.processing_dark,
};

export default Colors;
